const getDefaultState = () => ({
  isLoading: true,
  zones: [],
  distances: [],
  userData: null,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async getZones({ commit }) {
    try {
      commit('SET_IS_LOADING', true);
      const res = await this.$axios.$get('/api/training-zones');
      commit('SET_ZONES', res.data);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async getDistances({ commit }) {
    try {
      const res = await this.$axios.$get('/api/training-details/distances');
      commit('SET_DISTANCES', res.data);
    } catch {}
  },
  async getUserData({ commit }) {
    try {
      const res = await this.$axios.$get('/api/training-details');
      commit('SET_USER_DATA', res.data);
    } catch {}
  },
  async updateUserData({ commit }, payload) {
    try {
      commit('SET_IS_LOADING', true);
      const res = await this.$axios.$put('/api/training-details', payload);
      commit('SET_USER_DATA', res.data);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_DISTANCES(state, data) {
    state.distances = data;
  },
  SET_USER_DATA(state, data) {
    state.userData = data;
  },
  SET_ZONES(state, data) {
    state.zones = data;
  },
};
