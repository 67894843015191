import { format } from 'date-fns';
import { uniqID } from '@/utils/hash';
import { getCookie } from '@/utils/cookieStorage';
import { UserSubscriptionStatus } from '~/data/types';
import { DateFormat } from '~/helpers/date';

export const state = () => ({
  isLoadingSettings: false,
  settings: null, // UserSettings type
});

const MUTATION_TYPES = {
  SET_LOADING_SETTINGS: 'SET_LOADING_SETTINGS',
  SET_USER_SETTINGS: 'SET_USER_SETTINGS',
  SET_HAS_CONFIRMED_EMAIL: 'SET_HAS_CONFIRMED_EMAIL',
};

export const mutations = {
  [MUTATION_TYPES.SET_LOADING_SETTINGS](state, value) {
    state.isLoadingSettings = value;
  },
  [MUTATION_TYPES.SET_USER_SETTINGS](state, payload) {
    // Transform to expected UserSettings type
    state.settings = {
      isAutoShareGraphicsWithGroupsOn:
        payload?.data?.allow_auto_share_training_graphics || false,
      isCommentNotificationOn:
        payload?.data?.is_comment_notification_on || false,
      isFiverNotificationOn: payload?.data?.is_fiver_notification_on || false,
      public: payload?.data?.public || false,
      isIncompleteWorkoutNotificationOn:
        payload?.data?.is_uncompleted_reminder_on || false,
      isScheduledWorkoutNotificationOn:
        payload?.data?.is_scheduled_reminder_on || false,
    };
  },
};

export const actions = {
  async login(_, payload) {
    let deviceToken = getCookie('device_token');

    if (!deviceToken) {
      deviceToken = uniqID(navigator.userAgent);
      document.cookie = `device_token=${deviceToken}`;
    }

    const defaultPayload = {
      device_token: deviceToken,
      platform: 'web',
    };

    try {
      const response = await this.$axios.post('/api/users/login', { ...payload, ...defaultPayload });
      return response;
    } catch (e) {
      this.$logger.error('user/login', e.response.data.message);
    }
  },
  async resetPasswordRequest(_, payload) {
    try {
      const response = await this.$axios.$post('/api/users/reset-password', payload);
      return response;
    } catch (e) {
      this.$logger.error('user/resetPasswordRequest', e.response.data.message);
    }
  },
  async setNewPasswordRequest(_, payload) {
    try {
      const response = await this.$axios.$post('/api/users/set-password', payload, { processErrors: false });
      return response;
    } catch (e) {
      this.$logger.error('user/setNewPasswordRequest', e.response.data.message);
    }
  },
  async register(_, payload) {
    const apiURL = '/api/users/register';
    try {
      const deviceToken = uniqID(navigator.userAgent);
      document.cookie = `device_token=${deviceToken}`;

      const defaultPayload = {
        device_token: deviceToken,
        platform: 'web',
      };
      return await this.$axios.$post(apiURL, { ...payload, ...defaultPayload });
    } catch (e) {
      this.$logger.error('user/register', e.response.data.message);
      throw new Error(e);
    }
  },
  async subscribe(_, payload) {
    const apiURL = '/api/users/subscribe';
    try {
      const { token, ...rest } = payload;
      return await this.$axios.$post(apiURL, rest, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      this.$logger?.error('user/subscribe', e?.response?.data?.message);
    }
  },
  async updateUser(_, data) {
    try {
      const response = await this.$axios.put('/api/users/me', data);
      // commit(MUTATION_TYPES.SET_DATA, response);
      return response.data;
    } catch (e) {
      return e.response;
    }
  },
  async deleteUser(_) {
    try {
      const response = await this.$axios.delete('/api/users/me');
      return response.data;
    } catch (e) {
      return e.response;
    }
  },
  async changeSettings({ commit }, { data, endpoint }) {
    try {
      const response = await this.$axios.put(`/api/${endpoint}`, data);
      commit(MUTATION_TYPES.SET_USER_SETTINGS, response.data);
      return response.data;
    } catch (error) {
      throw new Error(error);
    }
  },
  async getUserSettings({ commit }) {
    try {
      commit(MUTATION_TYPES.SET_LOADING_SETTINGS, true);
      const response = await this.$axios.$get('/api/users/my-settings');
      commit(MUTATION_TYPES.SET_USER_SETTINGS, response);
      return response.data;
    } catch (e) {
      return e.response;
    } finally {
      commit(MUTATION_TYPES.SET_LOADING_SETTINGS, false);
    }
  },
  async confirmEmail(_, token) {
    return await this.$axios.post(`/api/users/confirm-email/${token}`);
  },
  async resendConfirmationEmail() {
    try {
      const response = await this.$axios.$post('/api/users/confirm-email-resend', {
        hideToastr: true,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },
  async upgrade(_, data) {
    try {
      const response = await this.$axios.put('/api/user-subscription/upgrade', data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
  async cancelDowngrade({ commit }) {
    try {
      const response = await this.$axios.post('/api/user-subscription/cancel-scheduled');
      commit(MUTATION_TYPES.SET_USER_SETTINGS, response.data);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const getters = {
  hasConfirmedEmail: (_, __, rootState) => {
    return rootState?.auth?.user?.data?.is_email_confirmed;
  },
  isUnsubscribed: (_, __, rootState) => {
    const user = rootState.auth.user?.data;

    if (user) {
      return (user.subscription?.status !== UserSubscriptionStatus.Trialing && user.subscription?.status !== UserSubscriptionStatus.Active && user.subscription?.status !== UserSubscriptionStatus.PastDue);
    }

    return false;
  },
  userTimeZone(_, __, rootState) {
    return rootState.auth.user?.data?.timezone ?? 'America/New_York';
  },
  userCurrentDate() {
    // TODO: Check bug now replaced moment with date-fns...
    // to prevent safari bugs with dates YYYY-MM-DD[T]HH:mm:ss format is needed to parse new date
    // const dateString = formatInTimeZone(new Date(), getters.userTimeZone, "yyyy-MM-dd'T'HH:mm:ss");
    const dateString = format(new Date(), DateFormat.DateTimeLocal);
    return new Date(dateString);
  },
};
