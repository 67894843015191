import { useStore } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';
import { State } from '~/data/types/store';

export const useNotifications = () => {
  const store = useStore<State>();

  const isLoading = computed(() => store.state.notifications.isListLoading);
  const notifications = computed(() => store.state.notifications.list);
  const unreadNotificationCount = computed(() => store.state.notifications.unreadNotificationsNumber);
  const latestNotifications = computed(() => store.state.notifications.dropdownList || []);
  const pagination = computed(() => store.state.notifications.pagination);

  // TODO: Last UNREAD notifications
  const getLatestNotifications = async() => {
    await store.dispatch('notifications/GET_NOTIFICATION_DROPDOWN_LIST', {
      params: {
        'per-page': 3,
      },
    });
  };

  const getNotifications = async(page: number, perPage: number = 20, params: { [key: string]: any } = {}) => {
    await store.dispatch('notifications/GET_LIST', {
      params: {
        ...params,
        'per-page': perPage,
        page,
      },
    });
  };

  const markAsRead = async(id: number) => {
    await putMarkAsRead(id, true);
  };

  const markAllAsRead = async() => {
    await store.dispatch('notifications/MARK_ALL_AS_READ');
  };

  const markAsUnread = async(id: number) => {
    await putMarkAsRead(id, false);
  };

  const putMarkAsRead = async(id: number, read: boolean) => {
    await store.dispatch('notifications/MARK_AS_READ', {
      id,
      markAsRead: read,
      unreadCountType: read ? 'decrease' : 'increase',
      endpoint: read ? 'mark-as-read' : 'mark-as-unread',
    });
  };

  const deleteWorkout = (id: number) => {
    store.commit('notifications/DELETE_TRAINING_PLAN_ITEM', id);
  };

  return {
    deleteWorkout,
    getLatestNotifications,
    getNotifications,
    isLoading,
    latestNotifications,
    markAllAsRead,
    markAsRead,
    markAsUnread,
    notifications,
    pagination,
    unreadNotificationCount,
  };
};
