import { EventBus, Key } from '~/helpers/eventBus';

const requestWithRetry = async(requestClosure, attempts = 3, hideToastr = false) => {
  for (let i = 1; i <= attempts; i++) {
    try {
      const response = await requestClosure({
        hideToastr: i !== attempts || hideToastr,
      });
      return response;
    } catch (error) {
      const code = parseInt(error.response && error.response.status, 10);
      if (code === 422) {
        if (!hideToastr) {
          EventBus.$emit(Key.ShowErrorToast, error.response?.data?.message);
        }
        throw new Error(error);
      }
      if (i >= attempts) {
        throw new Error(error);
      }
    }
  }
};

export { requestWithRetry };
