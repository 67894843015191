const getDefaultState = () => ({
  isLoading: true,
  data: null,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async DELETE_IMPORTED_ACTIVITY({ commit }, id) {
    commit('SET_IS_LOADING', true);
    try {
      await this.$axios.$delete(`/api/imported-activities/${id}`);
      commit('SET_DATA', null);
      commit('SET_IS_LOADING', false);
    } catch (e) {
      commit('SET_IS_LOADING', false);
      return Promise.reject(e);
    }
  },
  async GET_IMPORTED_ACTIVITY({ commit }, id) {
    commit('SET_IS_LOADING', true);
    try {
      const response = await this.$axios.$get(`/api/imported-activities/${id}`);
      commit('SET_DATA', response.data);
      commit('SET_IS_LOADING', false);
    } catch (e) {
      commit('SET_IS_LOADING', false);
      return Promise.reject(e);
    }
  },
  async UPDATE_IMPORTED_ACTIVITY({ commit }, { id, data }) {
    try {
      const response = await this.$axios.put(`/api/imported-activities/${id}`, data);
      commit('SET_DATA', response.data.data);
      return response.data.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
};
