const getDefaultState = () => ({
  isLoading: true,
  data: null,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async getGuidelines({ commit }) {
    try {
      commit('SET_IS_LOADING', true);
      const res = await this.$axios.$get('/api/guidelines/racing');
      commit('SET_DATA', res.data);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
};
