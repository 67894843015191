function hash(s) {
  let h;
  for (let i = 0; i < s.length; i++) {
    h = 0xDEADBEEF;
    h = Math.imul(h ^ s.charCodeAt(i), 2654435761);
  }
  return (h ^ h >>> 16) >>> 0;
}

export const uniqID = (str) => {
  return `_${Math.random().toString(36).substr(2, 9)}_${hash(str)}`;
};
