import { useContext } from '@nuxtjs/composition-api';
import { addDays } from 'date-fns';
import { COOKIE_EXPIRY_DAYS } from '~/constants';

export const useOnSuccess = () => {
  const { $auth } = useContext();

  const onSuccess = async(result: any, shouldRememberMe: boolean) => {
    $auth.setUser(result.data); // User is stored as { data: User, message: string, statusCode: number, success: bool }
    const token = result.headers['x-token'];
    await $auth.setUserToken(token);
    const bearerToken = $auth.$storage.getCookie('_token.local');
    const expires = shouldRememberMe ? COOKIE_EXPIRY_DAYS : undefined;

    if (shouldRememberMe) {
      const expiryDate = addDays(new Date(), COOKIE_EXPIRY_DAYS);
      // @ts-ignore type doesn't include expires
      $auth.$storage.setCookie('_token_expiration.local', expiryDate.getTime(), { expires });
      // @ts-ignore type doesn't include expires
      $auth.$storage.setCookie('_token.local', bearerToken, { expires });
      // @ts-ignore type doesn't include expires
      $auth.$storage.setCookie('_token_remember.local', true, { expires });
    } else {
      $auth.$storage.setCookie('_token.local', bearerToken);
      $auth.$storage.removeCookie('_token_expiration.local');
      $auth.$storage.removeCookie('_token_remember.local');
    }

    localStorage.removeItem('registration');
  };

  return {
    onSuccess,
  };
};
