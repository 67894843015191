import { WeekDay } from '~/data/types/workout-settings';

export enum WorkoutStatus {
  Completed = 'completed',
  CompletedMissed = 'completed-missed',
  CompletedPartially = 'completed-partially',
  Future = 'future',
  Missed = 'missed',
  Today = 'today',
  Unavailable = 'unavailable',
  Imported = 'imported',
};

// Currently using common store, so need to use keys from api
export type TrainingZone = {
  functional_threshold_power_max: number | null,
  functional_threshold_power_min: number | null,
  heart_rate_high: number,
  heart_rate_low: number,
  id: string,
  pace_max: string | null,
  pace_min: string | null,
  rpe_max: number,
  rpe_min: number,
};

export type UserZone = {
  ftp_test: number | null,
  max_heart_rate: number | null,
  max_heart_rate_default: number,
  recent_workout_event_id: number | null,
  recent_workout_event_time: string | null,
  recent_workout_event_title: string | null,
  rest_heart_rate: number | null,
  rest_heart_rate_default: number,
};

export type RunningEvent = {
  id: number,
  title: string,
};

export enum TrainingPlanType {
  Baseline = 1,
  Offseason = 2,
  Event = 3,
  Onramp = 4,
};

export type WorkoutStatNumber = {
  raw: number | null,
  units: string,
  value: number | null,
};

export type WorkoutStatsTime = {
  raw: number | null,
  units: string[] | null,
  value: string | null,
};

export type WorkoutStats = {
  distance?: WorkoutStatNumber | null,
  duration_moving?: WorkoutStatsTime | null,
  duration_total?: WorkoutStatsTime | null,
  hr_avg?: WorkoutStatNumber | null,
  hr_max?: WorkoutStatNumber | null,
  pace_avg?: WorkoutStatsTime | null,
  pace_max?: WorkoutStatsTime | null,
  power_avg?: WorkoutStatNumber | null,
  power_max?: WorkoutStatNumber | null,
  power_normalized?: WorkoutStatNumber | null,
  speed_avg?: WorkoutStatNumber | null,
  speed_max?: WorkoutStatNumber | null,
};

export type ImportedActivity = {
  distance: number | null,
  duration: number | null,
  id: number,
  is_processing_stats: boolean,
  name: string,
  started_at: string,
  stats: WorkoutStats,
  type: string | null,
};

export type TrainingPlanItem = {
  areDetailsAvailable: boolean,
  completed_date: string | null,
  completion_status_automatic: number | null,
  completion_status_manual: number | null,
  description: string | null,
  event_title: string | null,
  has_suggested_calories: boolean,
  id: number,
  imported_activity: ImportedActivity | null,
  isCompleted: boolean,
  isMissed: boolean,
  nutrition_details: string | null,
  planned_date: string, // "2022-07-18"
  related_post_id: number | null,
  stats: WorkoutStats,
  workout: {
    categoryKey: string,
    categoryTitle: string,
    categoryTitleShort: string,
    distance_max: number | null,
    distance_min: number | null,
    duration_max: number | null,
    duration_min: number | null,
    plan_event_key: string | null,
    plan_event_title: string | null,
    plan_phase_key: string | null,
    plan_phase_title: string | null,
    title: string | null,
  },
};

export type TrainingPlanFocusEvent = {
  id: number,
  name: string | null,
  event: string,
  eventTitle: string,
  eventDate: Date,
  eventTaperStartDate: Date | null,
  eventTaperEndDate: Date | null,
  eventRecoveryStartDate: Date | null,
  eventRecoveryEndDate: Date | null,
};

export type TrainingPlanFocus = {
  calendarDays: number,
  description: string,
  endDate: Date,
  events: TrainingPlanFocusEvent[],
  isCurrent: boolean,
  planType: TrainingPlanType,
  startDate: Date,
  title: string,
};

export type TrainingPlanPreview = {
  distance_max: number | null,
  distance_min: number | null,
  duration_max: number | null,
  duration_min: number | null,
  event_title: string | null,
  plan_phase_key: string,
  plan_type: number,
  plan_week: number,
  workout_category_key: string,
  workout_category_title_short: string,
  workout_category_title: string,
  workout_event_key: string | null,
  workout_event_title: string | null,
};

export enum CalendarType {
  Month = 'month',
  Week = 'week',
};

export enum CalendarDayStatus {
  Future = 'future',
  Past = 'past',
  Today = 'today',
  Unavailable = 'unavailable',
};

export type CalendarActivity = {
  details: string[] | null,
  id: number | null,
  title: string,
  type: string | null,
  workoutStatus: WorkoutStatus | null,
};

export type CalendarDay = {
  activities: CalendarActivity[],
  date: Date,
  dateKey: string,
  dateLabel: string,
  status: CalendarDayStatus | null,
};

export type CalendarWeek = {
  isCurrent: boolean,
  isUnavailable: boolean,
  days: CalendarDay[],
};

export type CalendarFocus = {
  focus: TrainingPlanFocus,
  weeks: CalendarWeek[],
};

export type KeywordItem = {
  description: string,
  embed_code: string | null,
  id: number,
  key: string,
  platform_media_id: number | null,
  platform: null,
  title: string,
};

export enum EstimatedResultType {
  Competitive = 'competitive',
  Finish = 'finish',
  FinishQuestionable = 'finish_questionable',
  FinishStrong = 'finish_strong',
};

export type EstimatedResult = {
  id: number,
  plan_type: number,
  result_key: EstimatedResultType,
  result_text: string,
  show_warning: boolean,
  workout_event_id: number,
  workout_hours_per_week: number,
};

export type PlanSport = {
  athlete_title: string,
  id: number,
  key: string,
  title: string,
};

export type DraggableWeekUpdate = {
  category: string,
  day: WeekDay,
};

export type DraggableWeekMove = {
  category: string,
  from: WeekDay,
  to: WeekDay,
};
