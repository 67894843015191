const getDefaultState = () => ({
  categories: [],
  currentEvent: null,
  currentPlanType: null,
  isLoadingCategories: true,
  isLoadingWeeklySettings: true,
  isLoadingWorkoutSettings: true,
  offseasonMonth: null,
  weeklyPreferences: null,
  weeklyPreferencesError: null,
  weeklySettings: null,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_CATEGORIES({ commit }, { planType, workoutEventId, sportId }) {
    commit('SET_IS_LOADING_CATEGORIES', true);

    try {
      const response = await this.$axios.$get('/api/workout-categories', {
        params: {
          plan_type: planType,
          workout_event_id: workoutEventId, // required for event plan type only
          sport_id: sportId,
        },
      });
      commit('SET_CATEGORIES', response.data);
    } catch {
      // Do nothing
    }

    commit('SET_IS_LOADING_CATEGORIES', false);
  },
  async GET_WEEKLY_SETTINGS({ commit }, { planType, userEventId }) {
    commit('SET_IS_LOADING_WEEKLY_SETTINGS', true);

    try {
      const response = await this.$axios.$get('/api/workout-weekly-preferences', {
        params: {
          plan_type: planType,
          user_event_id: userEventId,
        },
      });
      commit('SET_WEEKLY_SETTINGS', response.data);
      commit('SET_WEEKLY_PREFERENCES', response.data.preferences);
    } catch {
      // Do nothing
    }

    commit('SET_IS_LOADING_WEEKLY_SETTINGS', false);
  },
  async GET_WORKOUT_SETTINGS({ commit }) {
    commit('SET_IS_LOADING_WORKOUT_SETTINGS', true);

    try {
      const response = await this.$axios.$get('/api/workout-preferences');
      commit('SET_OFFSEASON_MONTH', response.data.offseason_month);
      commit('SET_CURRENT_EVENT', response.data.current_plan_event);
      commit('SET_CURRENT_PLAN_TYPE', response.data.current_plan_type);
    } catch {
      // Do nothing
    }

    commit('SET_IS_LOADING_WORKOUT_SETTINGS', false);
  },
  async PUT_WORKOUT_SETTINGS({ commit }, offseasonMonth) {
    commit('SET_IS_LOADING_WORKOUT_SETTINGS', true);

    try {
      const response = await this.$axios.$put('/api/workout-preferences', {
        offseason_month: offseasonMonth,
      });
      commit('SET_OFFSEASON_MONTH', response.data.offseason_month);
    } catch (e) {
      commit('SET_IS_LOADING_WORKOUT_SETTINGS', false);
      return Promise.reject(e);
    }

    commit('SET_IS_LOADING_WORKOUT_SETTINGS', false);
  },
  async PUT_WORKOUT_WEEKLY_PREFERENCES({ commit }, data) {
    commit('SET_IS_LOADING_WEEKLY_SETTINGS', true);

    try {
      const response = await this.$axios.$put('/api/workout-weekly-preferences', data);
      commit('SET_WEEKLY_SETTINGS', response.data);
      commit('SET_WEEKLY_PREFERENCES', response.data.preferences);
    } catch (e) {
      commit('SET_IS_LOADING_WEEKLY_SETTINGS', false);
      return Promise.reject(e);
    }

    commit('SET_IS_LOADING_WEEKLY_SETTINGS', false);
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_CATEGORIES(state, data) {
    state.categories = data;
  },
  SET_CURRENT_EVENT(state, value) {
    state.currentEvent = value;
  },
  SET_CURRENT_PLAN_TYPE(state, value) {
    state.currentPlanType = value;
  },
  SET_IS_LOADING_CATEGORIES(state, value) {
    state.isLoadingCategories = value;
  },
  SET_IS_LOADING_WEEKLY_SETTINGS(state, value) {
    state.isLoadingWeeklySettings = value;
  },
  SET_IS_LOADING_WORKOUT_SETTINGS(state, value) {
    state.isLoadingWorkoutSettings = value;
  },
  SET_OFFSEASON_MONTH(state, value) {
    state.offseasonMonth = value;
  },
  SET_WEEKLY_SETTINGS(state, data) {
    state.weeklySettings = data;
  },
  SET_WEEKLY_PREFERENCES(state, data) {
    state.weeklyPreferences = data;
  },
  SET_WEEKLY_PREFERENCES_ERROR(state, error) {
    state.weeklyPreferencesError = error;
  },
};
