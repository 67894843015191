export const state = () => ({
  list: [],
});

export const actions = {
  async GET_BANNERS({ commit }, key) {
    try {
      const { data } = await this.$axios.$get(`/api/banners?filter[key]=${key || ''}`);
      await commit('SET_BANNERS_LIST', { data, key });
    } catch (e) {}
  },
  async DISMISS_BANNER({ commit }, id) {
    try {
      await commit('DISMISS_BANNER', id);
      await this.$axios.$put(`/api/banners/dismiss/${id}`);
    } catch (e) {}
  },
};

export const mutations = {
  SET_BANNERS_LIST(state, { data, key }) {
    if (key) {
      state.list = [...state.list, ...data];
    } else {
      const bannersWithKeys = state.list.filter(banner => !!banner.banner_key);
      state.list = [...bannersWithKeys, ...data];
    }
  },
  DISMISS_BANNER(state, id) {
    state.list = state.list.filter(item => item.id !== id);
  },
};
