export enum BannerType {
  AppGuideConnections = 'guide_connections',
  AppGuideFeed = 'guide_feed',
  AppGuideTrainingPlan = 'guide_training_plan',
  AppGuideTrainingRaces = 'guide_training_races',
  AppGuideTrainingSchedule = 'guide_training_schedule',
  AppGuideTrainingZones = 'guide_training_zones',
};

export type Banner = {
  id: number,
  image: string | null,
  title: string | null,
  text: string | null,
  button_text: string | null,
  button_url: string | null,
  banner_key: string | null,
  dismiss_text: string | null,
  is_premium: boolean, // computed property
};
