import Vue from 'vue';
import { NotificationType } from '~/data/types/notifications';
import storeInit from '~/helpers/store';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/notifications',
  unreadNotificationsNumber: 0,
  dropdownList: [],
  deletedIds: [],
});

const splitDataForDropdownList = (commit, data) => {
  commit('SET_DROPDOWN_LIST', data.data);
  commit('SET_UNREAD_NOTIFICATIONS_NUMBER', data.unread_notifications_number);
};

export const actions = {
  ...commonStore.actions,
  GET_NOTIFICATIONS_LIST_FOR_DROPDOWN: commonStore.actions.GET_LIST,
  async GET_NOTIFICATION_DROPDOWN_LIST({ dispatch }, action) {
    await dispatch('GET_NOTIFICATIONS_LIST_FOR_DROPDOWN', {
      ...action,
      dataFormatterFunction: splitDataForDropdownList,
    });
  },
  async MARK_AS_READ({ commit }, action) {
    await this.$axios.$put(`/api/notifications/${action.endpoint}/${action.id}`);
    commit('UPDATE_NOTIFICATION', { id: action.id, markAsRead: action.markAsRead });
    commit('UPDATE_UNREAD_COUNT', { type: action.unreadCountType });
  },
  async MARK_ALL_AS_READ({ commit }) {
    await this.$axios.$put('/api/notifications/mark-all-as-read');
    commit('MARK_ALL_READ');
  },
  UPDATE_RACE_COMPLETED_NOTIFICATION({ commit }, data) {
    commit('UPDATE_RACE_COMPLETED_NOTIFICATION', data);
  },
};

const setRelationshipForStateKey = (stateKey, state, data) => {
  const itemIndex = state[stateKey].findIndex(item => item?.user?.id === data.user_id && item?.type === NotificationType.Follower);
  if (itemIndex >= 0) {
    Vue.set(state[stateKey], itemIndex, {
      ...state[stateKey][itemIndex],
      user: {
        ...state[stateKey][itemIndex].user,
        own_followee: data,
      },
    });
  }
};

const removeRelationshipForStateKey = (stateKey, state, userId) => {
  const obj = state[stateKey].find(item => item?.user?.id === userId && item?.type === NotificationType.Follower);
  if (obj) {
    obj.user.own_followee = null;
  }
};

export const mutations = {
  ...commonStore.mutations,
  DELETE_TRAINING_PLAN_ITEM(state, id) {
    const notifications = state.list.filter(item => item.training_plan?.id === id);
    const deletedIds = state.deletedIds;

    if (notifications.length) {
      notifications.forEach(notification => deletedIds.push(notification.id));
    }

    state.deletedIds = deletedIds;
  },
  SET_LIST(state, data) {
    state.list = data.filter(item => !state.deletedIds.includes(item.id));
  },
  SET_RELATIONSHIP(state, { data }) {
    setRelationshipForStateKey('list', state, data);
    setRelationshipForStateKey('dropdownList', state, data);
  },
  REMOVE_RELATIONSHIP(state, action) {
    removeRelationshipForStateKey('list', state, action.userId);
    removeRelationshipForStateKey('dropdownList', state, action.userId);
  },
  SET_UNREAD_NOTIFICATIONS_NUMBER(state, data) {
    state.unreadNotificationsNumber = data;
  },
  SET_DROPDOWN_LIST(state, data) {
    state.dropdownList = data.filter(item => !state.deletedIds.includes(item.id));
  },
  UPDATE_NOTIFICATION(state, data) {
    const listItemIndex = state.list.findIndex(item => item.id === data.id);
    const isExistsInDropdownIndex = state.dropdownList.findIndex(item => item.id === data.id);
    // updated data for both lists
    let updatedData = { ...state.list[listItemIndex], is_read: data.markAsRead };

    if (isExistsInDropdownIndex !== -1) {
      if (!state.list?.length) {
        // updated dropdownList data for case: updates notification form Notiication's dropdown
        updatedData = { ...state.dropdownList[isExistsInDropdownIndex], is_read: data.markAsRead };
      }
      Vue.set(state.dropdownList, isExistsInDropdownIndex, updatedData);
    }
    Vue.set(state.list, listItemIndex, updatedData);
  },
  SET_WORKOUT_NOTIFICATION_COMPLETE(state, notificationId) {
    const listItemIndex = state.list.findIndex(item => item.id === notificationId);
    const isExistsInDropdownIndex = state.dropdownList.findIndex(item => item.id === notificationId);
    // updated data for both lists
    let updatedDataTrainingPlanObject = { ...state.list[listItemIndex]?.training_plan, isCompleted: true };
    let updatedData = { ...state.list[listItemIndex], training_plan: updatedDataTrainingPlanObject };

    if (isExistsInDropdownIndex !== -1) {
      if (!state.list?.length) {
        // updated dropdownList data for case: user invokes WorkoutsModal from Notiication's dropdown(by click on 'View'
        // button) and he has not been yet on Notifications page
        updatedDataTrainingPlanObject = { ...state.dropdownList[isExistsInDropdownIndex]?.training_plan, isCompleted: true };
        updatedData = { ...state.dropdownList[isExistsInDropdownIndex], training_plan: updatedDataTrainingPlanObject };
      }
      Vue.set(state.dropdownList, isExistsInDropdownIndex, updatedData);
    }
    Vue.set(state.list, listItemIndex, updatedData);
  },
  UPDATE_RACE_COMPLETED_NOTIFICATION(state, { notificationId, shareableMomentId, shareableMomentUrl }) {
    const listItemIndex = state.list.findIndex(item => item.id === notificationId);
    if (listItemIndex === -1) {
      return;
    }

    const params = {
      shareable_moment_id: shareableMomentId,
      shareable_moment_type: 'race_completed',
      shareable_moment_url: shareableMomentUrl,
    };

    const updatedData = { ...state.list[listItemIndex], params };

    Vue.set(state.list, listItemIndex, updatedData);

    const dropdownListIndex = state.dropdownList.findIndex(item => item.id === notificationId);
    if (dropdownListIndex !== -1) {
      Vue.set(state.dropdownList, dropdownListIndex, updatedData);
    }
  },
  MARK_ALL_READ(state) {
    state.list = state.list.map(item => ({
      ...item,
      is_read: true,
    }));
    state.dropdownList = state.dropdownList.map(item => ({
      ...item,
      is_read: true,
    }));
    state.unreadNotificationsNumber = 0;
  },
  UPDATE_UNREAD_COUNT(state, data) {
    if (data.type === 'increase') {
      state.unreadNotificationsNumber = state.unreadNotificationsNumber + 1;
    } else {
      state.unreadNotificationsNumber = state.unreadNotificationsNumber - 1;
    }
  },
};
