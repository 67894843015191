import Vue from 'vue';
import findIndex from 'lodash/findIndex';
import { requestWithRetry } from '../utils/api';
import {
  getCache,
  setCache,
  getAllCacheKeys,
  cachePrefix,
} from '../utils/cacheUtils';
import { ITEMS_PER_PAGE, POST_FILTER } from '~/constants';

import storeInit from '~/helpers/store';

const commonStore = storeInit();
const cacheFirstPagePrefix = 'postsFirstPage.';

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/posts',
  isListLoading: true,
  data: {},
  draft: {},
  hasNewData: {},
  currentListForCommunityGroupId: null,
});

const draftPost = {
  id: 0,
  text: '',
  origin_text: '',
  html_formatted_text: '',
  gif_url: null,
  published_at: '',
  origin_id: null,
  author: null,
  images: [],
  ownReaction: null,
  reactionsCount: 0,
  commentsCount: 0,
  latestComments: [],
  hasMoreComments: false,
  trainingPlan: null,
  community_group_id: null,
};

const makeDraftPostFromData = (rootState, data) => ({
  ...draftPost,
  ...data,
  html_formatted_text: data.text,
  origin_text: data.text,
  author: rootState?.auth?.user?.data ?? {},
});

const normalizePostData = (data = [], commit) => {
  data.forEach((post) => {
    if (post.latestComments.length) {
      let reversedList = post.latestComments.slice().reverse();
      let showLoadMoreButton = false;

      if (reversedList.length >= 3) {
        reversedList = reversedList.slice(1);
        showLoadMoreButton = true;
      }

      commit(
        'comments/SET_COMMENTS',
        {
          items: reversedList,
          showLoadMoreButton,
        },
        { root: true },
      );

      post.latestComments.forEach((comment) => {
        if (comment.latestReplies.length) {
          let reversedRepliesList = comment.latestReplies.slice().reverse();
          let showLoadMoreButton = false;

          if (reversedRepliesList.length >= 3) {
            reversedRepliesList = reversedRepliesList.slice(1);
            showLoadMoreButton = true;
          }

          commit(
            'comments/SET_REPLIES',
            {
              items: reversedRepliesList,
              showLoadMoreButton,
            },
            { root: true },
          );
        }
      });
    }
  });
};

const shouldUseCacheByType = (page, type, id) => {
  return type === POST_FILTER.GROUP && id && page === 1;
};

export const actions = {
  ...commonStore.actions,
  async GET_LIST(
    { commit, state, rootState },
    { params, type = '', id = '', force = false },
  ) {
    commit('SET_HAS_NEW_DATA_MARK', {
      type,
      id,
      value: false,
    });
    const perPage
      = (params && params['per-page'])
      || state.pagination.per_page
      || ITEMS_PER_PAGE;
    const paginationQuery = {
      page: (params && params.page) || 1,
      'per-page': perPage,
    };

    const queryParams = {
      ...state.queryParams,
      ...paginationQuery,
      ...params,
    };

    // Only cache if we are querying the first page of the group posts
    const shouldUseCache = shouldUseCacheByType(queryParams.page, type, id);
    const cacheKey = `${cacheFirstPagePrefix}${type}.${id}`;

    const cachedData = getCache(cacheKey);
    if (shouldUseCache && cachedData && !force) {
      commit('SET_LIST', cachedData);
      commit(
        'SET_CURRENT_LIST_COMMUNITY_GROUP_ID',
        type === POST_FILTER.GROUP && id ? id : null,
      );
    } else {
      commit('SET_LIST_LOADING', true);
    }

    await this.$axios
      .get(state.requestUrl, { params: queryParams })
      .then((res) => {
        const { data } = res.data;
        const { meta } = res.data;
        const { links } = res.data;

        // normalize post comments data
        normalizePostData(data, commit);

        if (!shouldUseCache || !cachedData || force) {
          commit('SET_LIST', data);
          commit(
            'SET_CURRENT_LIST_COMMUNITY_GROUP_ID',
            type === POST_FILTER.GROUP && id ? id : null,
          );
        }

        if (shouldUseCache) {
          setCache(cacheKey, data);

          const currentUserId = rootState?.auth?.user?.data.id;
          commit('SET_HAS_NEW_DATA_MARK', {
            type,
            id,
            value:
              data
                // should ignore posts that were created, deleted by the current user.
                // When switching from other screens, there may be a delay when creating or deleting a post,
                // because we use asynchronous requests for visual speed
                .filter(i => i?.author?.id !== currentUserId)
                .map(i => i.id)
                .join(',')
              !== state.list
                // We also limit the number of records to be checked,
                // because when a new record is created by the current user,
                // the list will shift
                .slice(0, perPage)
                .filter(i => i?.author?.id !== currentUserId)
                .map(i => i.id)
                .join(','),
          });
        }

        commit('UPDATE_PAGINATION', {
          current_page: meta.current_page,
          per_page: meta.per_page,
          last_page: meta.last_page,
          total: meta.total,
          next_page: links.next,
        });
      })
      .finally(() => {
        commit('SET_LIST_LOADING', false);
      });
  },

  async GET_ITEM({ commit, state }, { id, params }) {
    commit('SET_DATA_LOADING', true);
    const { data } = await this.$axios.get(`${state.requestUrl}/${id}`, {
      params,
    });
    normalizePostData([data.data], commit);

    commit('SET_ITEM', data.data);
    commit('SET_DATA_LOADING', false);
  },

  async GET_NEXT_LIST_PAGE({ commit, state }, payload) {
    const params = payload && payload.params;

    const paginationQuery = {
      page: (params && params.page) || state.pagination.current_page + 1,
      'per-page':
        (params && params['per-page'])
        || state.pagination.per_page
        || ITEMS_PER_PAGE,
    };

    const queryParams = {
      ...state.queryParams,
      ...paginationQuery,
      ...params,
    };

    await this.$axios.get(state.requestUrl, { params: queryParams })
      .then((res) => {
        const { data } = res.data;
        const { meta } = res.data;
        const { links } = res.data;

        // normalize post comments data
        normalizePostData(data, commit);
        commit('PUSH_DATA_TO_LIST', data);
        commit('UPDATE_PAGINATION', {
          current_page: meta.current_page,
          per_page: meta.per_page,
          last_page: meta.last_page,
          total: meta.total,
          next_page: links.next,
        });
      })
      .catch(err => err);
  },

  async CREATE_POST({ commit, dispatch, rootState }, action) {
    const draftId = 0 - new Date().getTime();
    commit('ADD_DRAFT_POST_TO_LIST', {
      data: { ...action, id: draftId, community_group_id: action?.groupId },
      rootState,
    });
    try {
      const payload = {
        text: action.text,
        community_group_id: action?.groupId,
        share_with_other_groups: action?.shareWithOtherGroups,
      };
      if (action.gif_url) {
        payload.gif_url = action.gif_url;
      }
      const data = await dispatch('CREATE_DRAFT_POST', {
        postData: payload,
      });
      if (action.images) {
        for (let i = 0; i < action.images.length; i++) {
          await dispatch(
            'posts/ATTACH_IMAGE',
            { postId: data.id, file: action.images[i].file },
            { root: true },
          );
        }
      }
      const postData = await dispatch(
        'posts/POST_CREATED_POST',
        { postId: data.id },
        { root: true },
      );
      commit('UPDATE_POST_DATA', { draftId, data: postData });
    } catch (error) {
      commit('DELETE_POST_FROM_LIST', { postId: draftId, updateCache: false });
    }
  },
  async CREATE_DRAFT_POST(_, { postData }) {
    return await requestWithRetry(axiosConfig =>
      this.$axios
        .$post('/api/posts/', postData, axiosConfig)
        .then(r => r.data),
    );
  },
  async POST_CREATED_POST(_, { postId }) {
    return await requestWithRetry(axiosConfig =>
      this.$axios
        .$put(`/api/posts/publish/${postId}`, {}, axiosConfig)
        .then(r => r.data),
    );
  },
  async ATTACH_IMAGE(_, action) {
    const formData = new FormData();
    formData.append('image', action.file);
    const url = action.commentId
      ? `/api/comments/${action.commentId}/attach-image`
      : `/api/posts/${action.postId}/attach-image`;

    return await requestWithRetry(axiosConfig =>
      this.$axios
        .$post(url, formData, {
          ...axiosConfig,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(r => r.data),
    );
  },
  async DELETE_POST({ commit }, { postId }) {
    await commit('DELETE_POST_FROM_LIST', { postId });
    await requestWithRetry(axiosConfig =>
      this.$axios.$delete(`/api/posts/${postId}`, {}, axiosConfig),
    );
  },

  async GET_POST_DRAFT_DATA({ commit }, action) {
    const data = await requestWithRetry(axiosConfig =>
      this.$axios
        .$put(`/api/posts/${action.postId}`, {}, axiosConfig)
        .then(r => r.data),
    );
    await commit('SET_DRAFT_DATA', data);
  },

  async DELETE_DRAFT_DATA({ commit }, action) {
    try {
      await this.$axios.$delete(`/api/posts/${action.postId}`);
    } finally {
      await commit('REMOVE_DRAFT_DATA');
    }
  },

  async EDIT_POST({ commit, dispatch, state }, action) {
    const postData = {
      text: action.text,
      gif_url: action.gif_url,
      html_formatted_text: action.text,
    };

    const currentPostIndex = findIndex(state.list, {
      id: state.draft.origin_id,
    });
    const currentPost = state.list[currentPostIndex];
    try {
      await commit('UPDATE_POST_DATA', {
        draftId: state.draft.origin_id,
        data: {
          ...currentPost,
          ...state.draft,
          ...action,
          ...postData,
          created_at: null,
        },
        updateCache: false,
      });
      await this.$axios.$put(`/api/posts/${action.draftId}`, postData);

      if (action.deletedImages.length) {
        for (let i = 0; i < action.deletedImages.length; i++) {
          await this.$axios.$delete(
            `/api/posts/${action.draftId}/delete-image/${action.deletedImages[i].id}`,
          );
        }
      }

      if (action.newImages.length) {
        for (let i = 0; i < action.newImages.length; i++) {
          await dispatch(
            'posts/ATTACH_IMAGE',
            { postId: action.draftId, file: action.newImages[i].file },
            { root: true },
          );
        }
      }

      const data = await dispatch(
        'posts/POST_CREATED_POST',
        { postId: action.draftId },
        { root: true },
      );
      await commit('UPDATE_POST_DATA', { draftId: state.draft.id, data });
    } catch {
      commit('UPDATE_POST_DATA', {
        data: currentPost,
        draftId: state.draft.id,
      });
    }
  },

  async GET_POST_DATA({ commit }, action) {
    try {
      const { data } = await this.$axios.$get(`/api/posts/${action.postId}`);
      await commit('UPDATE_POST_DATA', { data });
    } catch {
      await commit('UPDATE_POST_DATA', {
        draftId: state.draft.id,
        data: currentPost,
      });
    }
  },

  // COMMENTS ACTIONS
  async GET_COMMENTS({ commit }, action) {
    try {
      commit('SET_LIST_LOADING', true);
      const { data } = await this.$axios.$get('/api/comments/', {
        params: action.params,
      });

      await commit('PUSH_COMMENTS_TO_LIST', data);
    } catch (e) {
    } finally {
      commit('SET_LIST_LOADING', false);
    }
  },
  async CREATE_SHAREABLE_MOMENT_POST({ commit }, { shareableMomentId }) {
    try {
      commit('SET_LIST_LOADING', true);

      await this.$axios.$put(
        `/api/shareable-moments/${shareableMomentId}/share`,
      );

      commit('SET_LIST_LOADING', false);
    } catch (e) {
      commit('SET_LIST_LOADING', false);
      return Promise.reject(e);
    }
  },
};

export const mutations = {
  ...commonStore.mutations,
  ADD_REACTION(state, { postId, data, isSinglePostPage }) {
    if (isSinglePostPage) {
      this.commit('posts/UPDATE_POST_DATA', {
        data: {
          ...state.data,
          ownReaction: data,
          reactionsCount: data.totalCount,
        },
      });
    } else {
      const post = state.list.find(x => x.id === postId);

      if (post) {
        this.commit('posts/UPDATE_POST_DATA', {
          data: {
            ...post,
            ownReaction: data,
            reactionsCount: data.totalCount,
          },
        });
      }
    }
  },
  REMOVE_REACTION(state, { postId }) {
    if (state.data?.id === postId) {
      this.commit('posts/UPDATE_POST_DATA', {
        data: {
          ...state.data,
          ownReaction: null,
          reactionsCount: state.data.reactionsCount - 1,
        },
      });
    } else {
      const post = state.list.find(x => x.id === postId);

      if (post) {
        this.commit('posts/UPDATE_POST_DATA', {
          data: {
            ...post,
            ownReaction: null,
            reactionsCount: post.reactionsCount - 1,
          },
        });
      }
    }
  },
  DELETE_POST_FROM_LIST(state, { postId, updateCache = true }) {
    const index = state.list.findIndex(item => item.id === postId);
    if (index !== -1) {
      const currentPost = state.list[index];
      Vue.delete(state.list, index);
      if (updateCache && currentPost) {
        this.commit('posts/REMOVE_POST_FROM_EXISTING_GROUPS_CACHE', postId);
      }
    }
  },
  SET_DRAFT_DATA(state, data) {
    state.draft = {
      ...data,
    };
  },
  REMOVE_DRAFT_DATA(state) {
    state.draft = {};
  },
  UPDATE_POST_DATA(state, { data, draftId = null, updateCache = true }) {
    const id = draftId || data.id;
    const postIndex = findIndex(state.list, { id });
    if (state.data) {
      state.data = data;
    }

    if (data?.deletedImages?.length) {
      data.images = data.images.filter(image =>
        data.deletedImages.some(dImage => dImage.id !== image.id),
      );
    }

    if (data?.newImages?.length) {
      data.images.push(...data.newImages);
    }

    Vue.set(state.list, postIndex, data);
    if (updateCache) {
      this.commit('posts/UPDATE_POST_FOR_EXISTING_GROUPS_CACHE', data);
      this.commit('posts/UPDATE_LIST_CACHE', data.community_group_id);
    }
  },
  UPDATE_LIST_CACHE(state, communityGroupId) {
    const cacheKey = `${cacheFirstPagePrefix}${POST_FILTER.GROUP}.${communityGroupId}`;
    if (communityGroupId === state.currentListForCommunityGroupId) {
      setCache(cacheKey, state.list);
    }
  },
  UPDATE_POST_FOR_EXISTING_GROUPS_CACHE(_, postData) {
    const cacheKey = `${cachePrefix}${cacheFirstPagePrefix}${POST_FILTER.GROUP}.`;

    const cacheKeysList = getAllCacheKeys(cacheKey, true);

    cacheKeysList.forEach((key) => {
      const items = getCache(key, true);
      if (Array.isArray(items)) {
        const index = items.findIndex(i => i.id === postData.id);
        if (index !== -1) {
          items[index] = postData;
          setCache(key, items, true);
          // add new record if this post is shared to this group
        } else if (
          postData?.shared_to_ids?.includes(parseInt(key.replace(cacheKey, '')))
        ) {
          setCache(key, [postData, ...items], true);
        }
      }
    });
  },
  REMOVE_POST_FROM_EXISTING_GROUPS_CACHE(_, postId) {
    const cacheKey = `${cacheFirstPagePrefix}${POST_FILTER.GROUP}.`;

    const cacheKeysList = getAllCacheKeys(cacheKey);

    cacheKeysList.forEach((key) => {
      const items = getCache(key, true);
      if (items) {
        setCache(
          key,
          items.filter(i => i.id !== postId),
          true,
        );
      }
    });
  },
  DECREASE_COMMENT_COUNTER(state, { postId }) {
    const postIndex = findIndex(state.list, { id: postId });

    Vue.set(state.list, postIndex, {
      ...state.list[postIndex],
      commentsCount:
        state.list[postIndex].commentsCount > 0
          ? (state.list[postIndex].commentsCount -= 1)
          : 0,
    });
  },
  ADD_DRAFT_POST_TO_LIST(state, { data, rootState }) {
    const newList = state.list;
    newList.unshift(makeDraftPostFromData(rootState, data));
    Vue.set(state, 'list', newList);
  },
  SET_HAS_NEW_DATA_MARK(state, { type, id, value }) {
    const key = type + id;
    Vue.set(state, 'hasNewData', { ...state.hasNewPosts, [key]: value });
  },
  SET_CURRENT_LIST_COMMUNITY_GROUP_ID(state, value) {
    state.currentListForCommunityGroupId = value;
  },
};
