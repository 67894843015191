import { useContext } from '@nuxtjs/composition-api';
import { TrainingPlanAdvertisement } from '~/data/types/advertisement';
import { useTrainingPlanItem } from '~/data/training/use-training-plan-item';

export const useAdvertisements = () => {
  const { $axios } = useContext();
  const { getFunnelId } = useTrainingPlanItem();

  const getAdvertisementForTrainingPlan = async(id: number): Promise<TrainingPlanAdvertisement | null> => {
    try {
      const result = await $axios.get(`/api/advertisements/training-plan/${id}`);
      return result?.data?.data ?? null;
    } catch {
      return null;
    }
  };

  const markAdvertisementAsWatched = (id: number) => {
    try {
      $axios.put(`/api/advertisements/${id}/mark-as-watched`);
    } catch {
      // Do nothing
    }
  };

  const trackViewed = (id: number, trainingPlanId: number) =>
    trackInteraction('view', id, trainingPlanId, null);

  const trackClicked = (id: number, trainingPlanId: number, secondsWatched: number) =>
    trackInteraction('click', id, trainingPlanId, secondsWatched);

  const trackDismissed = (id: number, trainingPlanId: number, secondsWatched: number) =>
    trackInteraction('dismiss', id, trainingPlanId, secondsWatched);

  const trackWatched = (id: number, trainingPlanId: number) =>
    trackInteraction('watch', id, trainingPlanId, null);

  const trackUpgradeClicked = (id: number, trainingPlanId: number) =>
    trackInteraction('upgrade', id, trainingPlanId, null);

  const trackInteraction = (type: string, id: number, trainingPlanId: number, secondsWatched: number | null) => {
    const params: { [key: string]: any } = {
      funnel_id: getFunnelId(trainingPlanId),
      training_plan_id: trainingPlanId,
      type,
    };

    if (secondsWatched !== null) {
      params.seconds_watched = secondsWatched;
    }

    try {
      // @ts-ignore hideToastr doesn't exist on official config
      $axios.post(`/api/advertisements/${id}/track`, params, { hideToastr: true });
    } catch {
      // Do nothing
    }
  };

  return {
    getAdvertisementForTrainingPlan,
    markAdvertisementAsWatched,
    trackClicked,
    trackDismissed,
    trackUpgradeClicked,
    trackViewed,
    trackWatched,
  };
};
