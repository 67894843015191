export const CALENDAR = [
  {
    focus: {
      calendarDays: 69,
      description: 'A half ironman focused training week.',
      endDate: '2022-11-05T14:00:00.000Z',
      events: [
        {
          id: 15393,
          name: '70.3 Worlds',
          event: 'triathlon_half_ironman',
          eventDate: '2022-11-20T00:00:00.000Z',
        },
      ],
      isCurrent: true,
      planType: 3,
      startDate: '2022-08-28T14:00:00.000Z',
      title: '70.3 Worlds',
    },
    weeks: [
      {
        isCurrent: false,
        days: [
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: 4791283,
                title: 'Technique',
                type: 'swim_technique',
                workoutStatus: 'completed',
              },
            ],
            date: '2022-08-28T14:00:00.000Z',
            dateKey: '2022-08-29',
            dateLabel: 'Mon, Aug 29',
            status: 'past',
          },
          {
            activities: [
              {
                details: [
                  '56 min',
                ],
                id: 4791284,
                title: 'Intense #1',
                type: 'bike_intense_1',
                workoutStatus: 'completed',
              },
            ],
            date: '2022-08-29T14:00:00.000Z',
            dateKey: '2022-08-30',
            dateLabel: 'Tue, Aug 30',
            status: 'past',
          },
          {
            activities: [
              {
                details: [
                  '40 - 60 min',
                ],
                id: 4791285,
                title: 'Main',
                type: 'swim_main',
                workoutStatus: 'completed_partially',
              },
              {
                details: [
                  '55 min',
                ],
                id: 4791286,
                title: 'Intense',
                type: 'run_intense',
                workoutStatus: 'completed',
              },
            ],
            date: '2022-08-30T14:00:00.000Z',
            dateKey: '2022-08-31',
            dateLabel: 'Wed, Aug 31',
            status: 'past',
          },
          {
            activities: [
              {
                details: [
                  '55 min',
                ],
                id: 4791287,
                title: 'Steady',
                type: 'bike_steady',
                workoutStatus: 'completed',
              },
              {
                details: [
                  '25 - 35 min',
                ],
                id: 4791288,
                title: 'Strength #1',
                type: 'strength_1',
                workoutStatus: 'completed',
              },
            ],
            date: '2022-08-31T14:00:00.000Z',
            dateKey: '2022-09-01',
            dateLabel: 'Thu, Sep 1',
            status: 'past',
          },
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: 4791289,
                title: 'Steady',
                type: 'swim_steady',
                workoutStatus: 'missed',
              },
            ],
            date: '2022-09-01T14:00:00.000Z',
            dateKey: '2022-09-02',
            dateLabel: 'Fri, Sep 2',
            status: 'past',
          },
          {
            activities: [
              {
                details: [
                  '80 min',
                ],
                id: 4791290,
                title: 'Main',
                type: 'bike_main',
                workoutStatus: 'completed',
              },
              {
                details: [
                  '30 min',
                ],
                id: 4791291,
                title: 'Main',
                type: 'run_brick_main',
                workoutStatus: 'completed',
              },
            ],
            date: '2022-09-02T14:00:00.000Z',
            dateKey: '2022-09-03',
            dateLabel: 'Sat, Sep 3',
            status: 'past',
          },
          {
            activities: [
              {
                details: [
                  '55 min',
                ],
                id: 4791292,
                title: 'Main',
                type: 'run_main',
                workoutStatus: 'completed-partially',
              },
            ],
            date: '2022-09-03T14:00:00.000Z',
            dateKey: '2022-09-04',
            dateLabel: 'Sun, Sep 4',
            status: 'past',
          },
        ],
      },
      {
        isCurrent: true,
        days: [
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: null,
                title: 'Technique',
                type: 'swim_technique',
                workoutStatus: 'today',
              },
            ],
            date: '2022-09-04T14:00:00.000Z',
            dateKey: '2022-09-05',
            dateLabel: 'Mon, Sep 5',
            status: 'today',
          },
          {
            activities: [
              {
                details: [
                  '56 min',
                ],
                id: null,
                title: 'Intense #1',
                type: 'bike_intense_1',
                workoutStatus: 'future',
              },
            ],
            date: '2022-09-05T14:00:00.000Z',
            dateKey: '2022-09-06',
            dateLabel: 'Tue, Sep 6',
            status: 'future',
          },
          {
            activities: [
              {
                details: [
                  '40 - 60 min',
                ],
                id: null,
                title: 'Main',
                type: 'swim_main',
                workoutStatus: 'future',
              },
              {
                details: [
                  '52 min',
                ],
                id: null,
                title: 'Intense',
                type: 'run_intense',
                workoutStatus: 'future',
              },
            ],
            date: '2022-09-06T14:00:00.000Z',
            dateKey: '2022-09-07',
            dateLabel: 'Wed, Sep 7',
            status: 'future',
          },
          {
            activities: [
              {
                details: [
                  '55 min',
                ],
                id: null,
                title: 'Steady',
                type: 'bike_steady',
                workoutStatus: 'future',
              },
              {
                details: [
                  '25 - 35 min',
                ],
                id: null,
                title: 'Strength #1',
                type: 'strength_1',
                workoutStatus: 'future',
              },
            ],
            date: '2022-09-07T14:00:00.000Z',
            dateKey: '2022-09-08',
            dateLabel: 'Thu, Sep 8',
            status: 'future',
          },
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: null,
                title: 'Steady',
                type: 'swim_steady',
                workoutStatus: 'future',
              },
            ],
            date: '2022-09-08T14:00:00.000Z',
            dateKey: '2022-09-09',
            dateLabel: 'Fri, Sep 9',
            status: 'future',
          },
          {
            activities: [
              {
                details: [
                  '85 min',
                ],
                id: null,
                title: 'Main',
                type: 'bike_main',
                workoutStatus: 'future',
              },
              {
                details: [
                  '30 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_brick_main',
                workoutStatus: 'future',
              },
            ],
            date: '2022-09-09T14:00:00.000Z',
            dateKey: '2022-09-10',
            dateLabel: 'Sat, Sep 10',
            status: 'future',
          },
          {
            activities: [
              {
                details: [
                  '60 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_main',
                workoutStatus: 'future',
              },
            ],
            date: '2022-09-10T14:00:00.000Z',
            dateKey: '2022-09-11',
            dateLabel: 'Sun, Sep 11',
            status: 'future',
          },
        ],
      },
      {
        isCurrent: false,
        days: [
          {
            activities: [
              {
                details: [
                  '30 - 45 min',
                ],
                id: null,
                title: 'Technique',
                type: 'swim_technique',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-11T14:00:00.000Z',
            dateKey: '2022-09-12',
            dateLabel: 'Mon, Sep 12',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '56 min',
                ],
                id: null,
                title: 'Intense #1',
                type: 'bike_intense_1',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-12T14:00:00.000Z',
            dateKey: '2022-09-13',
            dateLabel: 'Tue, Sep 13',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '30 - 45 min',
                ],
                id: null,
                title: 'Main',
                type: 'swim_main',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '50 min',
                ],
                id: null,
                title: 'Intense',
                type: 'run_intense',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-13T14:00:00.000Z',
            dateKey: '2022-09-14',
            dateLabel: 'Wed, Sep 14',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '40 min',
                ],
                id: null,
                title: 'Steady',
                type: 'bike_steady',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '25 - 35 min',
                ],
                id: null,
                title: 'Strength #1',
                type: 'strength_1',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-14T14:00:00.000Z',
            dateKey: '2022-09-15',
            dateLabel: 'Thu, Sep 15',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '30 - 45 min',
                ],
                id: null,
                title: 'Steady',
                type: 'swim_steady',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-15T14:00:00.000Z',
            dateKey: '2022-09-16',
            dateLabel: 'Fri, Sep 16',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '50 min',
                ],
                id: null,
                title: 'Main',
                type: 'bike_main',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '15 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_brick_main',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-16T14:00:00.000Z',
            dateKey: '2022-09-17',
            dateLabel: 'Sat, Sep 17',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '45 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_main',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-17T14:00:00.000Z',
            dateKey: '2022-09-18',
            dateLabel: 'Sun, Sep 18',
            status: 'unavailable',
          },
        ],
      },
      {
        isCurrent: false,
        days: [
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: null,
                title: 'Technique',
                type: 'swim_technique',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-18T14:00:00.000Z',
            dateKey: '2022-09-19',
            dateLabel: 'Mon, Sep 19',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '58 min',
                ],
                id: null,
                title: 'Intense #1',
                type: 'bike_intense_1',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-19T14:00:00.000Z',
            dateKey: '2022-09-20',
            dateLabel: 'Tue, Sep 20',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '40 - 60 min',
                ],
                id: null,
                title: 'Main',
                type: 'swim_main',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '60 min',
                ],
                id: null,
                title: 'Intense',
                type: 'run_intense',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-20T14:00:00.000Z',
            dateKey: '2022-09-21',
            dateLabel: 'Wed, Sep 21',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '60 min',
                ],
                id: null,
                title: 'Steady',
                type: 'bike_steady',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '25 - 35 min',
                ],
                id: null,
                title: 'Strength #1',
                type: 'strength_1',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-21T14:00:00.000Z',
            dateKey: '2022-09-22',
            dateLabel: 'Thu, Sep 22',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: null,
                title: 'Steady',
                type: 'swim_steady',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-22T14:00:00.000Z',
            dateKey: '2022-09-23',
            dateLabel: 'Fri, Sep 23',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '90 min',
                ],
                id: null,
                title: 'Main',
                type: 'bike_main',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '30 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_brick_main',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-23T14:00:00.000Z',
            dateKey: '2022-09-24',
            dateLabel: 'Sat, Sep 24',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '65 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_main',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-24T14:00:00.000Z',
            dateKey: '2022-09-25',
            dateLabel: 'Sun, Sep 25',
            status: 'unavailable',
          },
        ],
      },
      {
        isCurrent: false,
        days: [
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: null,
                title: 'Technique',
                type: 'swim_technique',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-25T14:00:00.000Z',
            dateKey: '2022-09-26',
            dateLabel: 'Mon, Sep 26',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '58 min',
                ],
                id: null,
                title: 'Intense #1',
                type: 'bike_intense_1',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-26T14:00:00.000Z',
            dateKey: '2022-09-27',
            dateLabel: 'Tue, Sep 27',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '40 - 60 min',
                ],
                id: null,
                title: 'Main',
                type: 'swim_main',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '60 min',
                ],
                id: null,
                title: 'Intense',
                type: 'run_intense',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-27T14:00:00.000Z',
            dateKey: '2022-09-28',
            dateLabel: 'Wed, Sep 28',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '60 min',
                ],
                id: null,
                title: 'Steady',
                type: 'bike_steady',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '25 - 35 min',
                ],
                id: null,
                title: 'Strength #1',
                type: 'strength_1',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-28T14:00:00.000Z',
            dateKey: '2022-09-29',
            dateLabel: 'Thu, Sep 29',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '40 - 50 min',
                ],
                id: null,
                title: 'Steady',
                type: 'swim_steady',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-29T14:00:00.000Z',
            dateKey: '2022-09-30',
            dateLabel: 'Fri, Sep 30',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '1:35 hrs',
                ],
                id: null,
                title: 'Main',
                type: 'bike_main',
                workoutStatus: 'unavailable',
              },
              {
                details: [
                  '30 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_brick_main',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-09-30T14:00:00.000Z',
            dateKey: '2022-10-01',
            dateLabel: 'Sat, Oct 1',
            status: 'unavailable',
          },
          {
            activities: [
              {
                details: [
                  '70 min',
                ],
                id: null,
                title: 'Main',
                type: 'run_main',
                workoutStatus: 'unavailable',
              },
            ],
            date: '2022-10-01T14:00:00.000Z',
            dateKey: '2022-10-02',
            dateLabel: 'Sun, Oct 2',
            status: 'unavailable',
          },
        ],
      },
    ],
  },
];
