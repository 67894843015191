export enum SubscriptionPlanType {
  Free = 'free',
  FreeTrial = 'free_trial',
  Monthly = 'monthly',
  Yearly = 'yearly',
};

export type SubscriptionPlan = {
  badge_content: string | null,
  billing_amount: number | null,
  billing_interval: string | null,
  description: string,
  id: string,
  is_discountable: boolean,
  is_premium: boolean,
  summary: string,
  title: string,
  type: SubscriptionPlanType | null,
};
