import { OnboardingBumperType } from '~/data/types/onboarding';

const getDefaultState = () => ({
  bumpers: [],
  hasCompletedAllBumpers: false,
  isLoadingBumpers: true,
  shouldShowBumpers: false,
  welcomeMessage: 'Personalize your account to optimize your training and start reaching your goals.',
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_BUMPERS({ commit }) {
    try {
      await commit('SET_IS_LOADING_BUMPERS', true);
      const { data } = await this.$axios.$get('/api/onboarding/bumpers');
      await commit('SET_BUMPERS', data);
      await commit('SET_IS_LOADING_BUMPERS', false);
    } catch (e) {
      await commit('SET_IS_LOADING_BUMPERS', false);
    }
  },
  async COMPLETE_BUMPER({ commit }, id) {
    try {
      await commit('COMPLETE_BUMPER', id);
      await this.$axios.$put(`/api/onboarding/bumpers/${id}`);
    } catch (e) {}
  },
  async DISMISS_BUMPERS({ commit }) {
    try {
      await commit('DISMISS_BUMPERS');
      await this.$axios.$delete('/api/onboarding/bumpers');
    } catch (e) {}
  },
  async HIDE_BUMPERS({ commit }) {
    await commit('HIDE_BUMPERS');
  },
  async HIDE_WELCOME_MESSAGE({ commit }) {
    await commit('HIDE_WELCOME_MESSAGE');
  },
  async SET_REFERRER(_, referrer) {
    try {
      await this.$axios.$post('/api/onboarding/referrer', { referrer });
    } catch (e) {}
  },
  async TOGGLE_BUMPER_VISIBILITY({ commit }) {
    await commit('TOGGLE_BUMPER_VISIBILITY');
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  COMPLETE_BUMPER(state, id) {
    state.bumpers = state.bumpers.map((item) => {
      if (item.id === id) {
        item.is_completed = true;
      }

      return item;
    });
  },
  DISMISS_BUMPERS(state) {
    state.bumpers = [];
  },
  HIDE_BUMPERS(state) {
    state.bumpers = [];
  },
  HIDE_WELCOME_MESSAGE(state) {
    state.welcomeMessage = null;
  },
  SET_BUMPERS(state, data) {
    const supportedBumperKeys = [
      OnboardingBumperType.AddConnection,
      OnboardingBumperType.AddProfileImage,
      OnboardingBumperType.AddRace,
      OnboardingBumperType.AddTrainingZoneInput,
      OnboardingBumperType.SetReferrer,
      OnboardingBumperType.UpdateWorkoutWeeklyPreferences,
    ];
    const supportedBumpers = data.filter(item => supportedBumperKeys.includes(item.key));
    state.hasCompletedAllBumpers = !!state.bumpers.length && !supportedBumpers.length;
    state.bumpers = supportedBumpers;
  },
  SET_IS_LOADING_BUMPERS(state, value) {
    state.isLoadingBumpers = value;
  },
  TOGGLE_BUMPER_VISIBILITY(state) {
    state.shouldShowBumpers = !state.shouldShowBumpers;
  },
};
