import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
// eslint-disable-next-line camelcase
import { confirmed, email, integer, max, max_value, min, min_value, required, size } from 'vee-validate/dist/rules';
import { isValid } from 'date-fns';

extend('date', {
  validate(value) {
    return value ? isValid(value) : false;
  },
  params: ['length'],
  message: fieldName => `Make sure ${fieldName.toLowerCase()} is a valid date`,
});

extend('email', {
  ...email,
  message: fieldName => `Make sure ${fieldName.toLowerCase()} is a valid email address`,
});

extend('required', {
  ...required,
  message: (fieldName) => {
    if (fieldName === '{field}') {
      return 'Add a value';
    }

    const field = fieldName.toLowerCase();
    const firstLetter = field.length ? field[0] : '';
    const firstLetterIsVowel = ['a', 'e', 'i', 'o', 'u'].includes(firstLetter);
    return `Add ${firstLetterIsVowel ? 'an' : 'a'} ${field}`;
  },
});

extend('min_value', {
  // eslint-disable-next-line camelcase
  ...min_value,
  message: (fieldName, params) => {
    return `Make sure ${fieldName.toLowerCase()} is greater than ${params.min}`;
  },
});

extend('max_value', {
  // eslint-disable-next-line camelcase
  ...max_value,
  message: (fieldName, params) => {
    return `Make sure ${fieldName.toLowerCase()} is less than ${params.max}`;
  },
});

extend('integer', {
  ...integer,
  message: (fieldName) => {
    return `Make sure ${fieldName.toLowerCase()} is a whole number`;
  },
});

extend('termsAndConditions', (value) => {
  return value || 'Do you accept the terms and conditions?';
});

extend('confirmed', {
  ...confirmed,
  message: 'Make sure your {_field_} confirmation matches',
});

extend('image_resolution', {
  validate(files, [width, height]) {
    const validateImage = (file, width, height) => {
      const URL = window.URL || window.webkitURL;

      return new Promise((resolve) => {
        const image = new Image();
        image.onerror = () => resolve({ valid: false });
        image.onload = () => resolve({
          valid: image.width >= Number(width) && image.height >= Number(height), // only change from official rule
        });

        image.src = URL.createObjectURL(file);
      });
    };

    const list = [];
    for (let i = 0; i < files.length; i++) {
      // if file is not an image, reject.
      if (!/\.(jpg|svg|jpeg|png|bmp|gif)$/i.test(files[i].name)) {
        return false;
      }
      list.push(files[i]);
    }

    return validateImage(list[0], width, height).then(param => param.valid);
  },
  message: (_, customParams) => {
    return `Make sure your image is greater than ${customParams[0]}x${customParams[1]}px`;
  },
});

extend('size', {
  ...size,
  message: (_, param) => {
    return `Make sure your image is less than ${param.size / 1000}MB`;
  },
});

extend('password', {
  validate(value) {
    // at least one number, one lowercase and one uppercase letter
    // at least eight characters and one special symbol
    const passwordValidation = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
    return passwordValidation.test(value);
  },
  message: 'Create a strong password with 8+ characters, 1 uppercase, 1 lowercase and 1 number',
});

extend('time', {
  validate(value) {
    const parts = value.split(':');

    if (parts.length !== 3) {
      return false;
    }

    const hh = Number(parts[0]);
    const mm = Number(parts[1]);
    const ss = Number(parts[2]);
    return !isNaN(hh) || !isNaN(mm) || !isNaN(ss);
  },
  message: (fieldName) => {
    return `Make sure the ${fieldName.toLowerCase()} is in the format HH:MM:SS`;
  },
});

extend('pace', {
  validate(value) {
    const parts = value.split(':');

    if (parts.length !== 2) {
      return false;
    }

    const mm = Number(parts[0]);
    const ss = Number(parts[1]);
    return !isNaN(mm) || !isNaN(ss);
  },
  message: (fieldName) => {
    return `Make sure the ${fieldName.toLowerCase()} is in the format MM:SS`;
  },
});

extend('min', {
  ...min,
  message: (fieldName, param) => {
    return `Make sure ${fieldName.toLowerCase()} is ${param.length}+ characters`;
  },
});

extend('max', {
  ...max,
  message: (fieldName, param) => {
    return `Make sure ${fieldName.toLowerCase()} is <=${param.length} characters`;
  },
});

extend('card_name', {
  validate(value) {
    const names = value.split(' ');
    return names?.filter(val => val)?.length > 1;
  },
  message: 'Add your first and last name',
});

extend('full_name', {
  validate(value) {
    const names = value.split(' ');
    return names?.filter(val => val)?.length > 1;
  },
  message: 'Add your first and last name',
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
