export enum FeatureFlag {
  DashboardV2 = 'release-dashboard-v2',
  ShareableMoments = 'release-shareable-moments-training-summary',
  ShareableMomentsShareWorkout = 'release-shareable-moments-workout',
  EditableWorkoutStats = 'release-editable-workout-stats',
  StravaGraphics = 'release-strava-graphics',
}

export type FeatureFlagType = {
  slug: string;
  enabled: boolean;
};
