import Vue from 'vue';
import { SUPPORTED_TRAINING_SUMMARIES } from '~/constants';

const getDefaultState = () => ({
  isLoadingSummaries: [],
  summaries: {},
});

export const state = () => getDefaultState();

const makeTrainingSummary = item => ({
  type: item.type,
  statistics: item.stats.map(statistic => ({
    type: statistic.type,
    distance: statistic.distance,
    durationMoving: statistic.duration_moving,
  })),
  completedWorkoutsPercentage: item.completed_workouts_percentage,
  totalActivities: item.total_activities,
});

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_TRAINING_SUMMARIES({ state, commit }, params) {
    try {
      SUPPORTED_TRAINING_SUMMARIES.forEach((type) => {
        if (!state.isLoadingSummaries.includes(type)) {
          commit('SET_LOADING_SUMMARY_START', { key: type });
        }
      });

      const response = await this.$axios.$get('/api/training-summary', {
        params,
        hideToastr: false,
      });

      response.data.forEach((item) => {
        commit('SET_TRAINING_SUMMARY', { key: item.type, data: makeTrainingSummary(item) });
      });

      SUPPORTED_TRAINING_SUMMARIES.forEach(type => commit('SET_LOADING_SUMMARY_END', { key: type }));
    } catch (e) {
      SUPPORTED_TRAINING_SUMMARIES.forEach(type => commit('SET_LOADING_SUMMARY_END', { key: type }));
      return Promise.reject(e);
    }
  },
  async GET_TRAINING_SUMMARY({ state, commit }, { fromDate, toDate, forceUpdate }) {
    const key = `${fromDate}-${toDate}`;
    try {
      if (!forceUpdate && state.isLoadingSummaries.includes(key)) {
        return;
      }

      if (!state.isLoadingSummaries.includes(key)) {
        commit('SET_LOADING_SUMMARY_START', { key });
      }

      const response = await this.$axios.$get('/api/training-summary/date-range', {
        params: {
          from: fromDate,
          to: toDate,
        },
        hideToastr: false,
      });

      commit('SET_TRAINING_SUMMARY', {
        key,
        data: makeTrainingSummary(response.data),
      });
      commit('SET_LOADING_SUMMARY_END', { key });
    } catch (e) {
      commit('SET_LOADING_SUMMARY_END', { key });
      return Promise.reject(e);
    }
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TRAINING_SUMMARY(state, { key, data }) {
    Vue.set(state.summaries, key, data);
  },
  SET_LOADING_SUMMARY_START(state, { key }) {
    state.isLoadingSummaries.push(key);
  },
  SET_LOADING_SUMMARY_END(state, { key }) {
    state.isLoadingSummaries = state.isLoadingSummaries.filter(item => item !== key);
  },
};
