import { useContext, useStore } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';
import { parse } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';

import { State } from '~/data/types/store';
import { MeasurementSystem, User } from '~/data/types';
import { DateFormat } from '~/helpers/date';

export const useUser = () => {
  const { $auth } = useContext();
  const store = useStore<State>();

  const user = computed(() => store.state.auth.user.data);
  const timeZone = computed<string>(() => store.getters['user/userTimeZone']);
  const currentDate = computed<Date>(() => store.getters['user/userCurrentDate']);
  const currentDateUTC = computed(() => {
    const dateString = formatInTimeZone(currentDate.value, timeZone.value, DateFormat.Date);
    return zonedTimeToUtc(dateString, timeZone.value);
  });
  const createdDateUTC = computed(() => {
    const date = parse(store.state.auth.user.data.created_at, DateFormat.ISO, new Date());
    const dateString = formatInTimeZone(date, timeZone.value, DateFormat.Date);
    return zonedTimeToUtc(dateString, timeZone.value);
  });
  const isMetricSystem = computed(() => store.state.auth.user.data.measurement_system === MeasurementSystem.Metric);
  const measurementSystem = computed(() => store.state.auth.user.data.measurement_system || MeasurementSystem.Metric);

  const saveProperty = async(name: string, value: string): Promise<boolean | null> => {
    const key = name.toLowerCase();
    let data: object | null;

    switch (key) {
      case 'name':
        data = getDataForName(value, store.state.auth.user.data);
        break;
      case 'units':
        data = getDataForUnits(value, store.state.auth.user.data);
        break;
      case 'place_id':
        data = { place_id: value };
        break;
      default:
        data = getDataForField(key, value, store.state.auth.user.data);
        break;
    }

    if (!data) {
      return null;
    }

    try {
      const response = await store.dispatch('user/updateUser', data);
      const statusCode = response?.statusCode || response?.data?.statusCode;
      if (statusCode === 200 && response?.data?.id) {
        $auth.setUser(response);
      }
      if (key === 'place_id' || key === 'weight' || key === 'units') {
        await store.dispatch('dashboard/CLEAR_DATA');
        await store.dispatch('importedActivity/CLEAR_DATA');
        await store.dispatch('trainingPlan/CLEAR_DATA');
        await store.dispatch('trainingPlanItem/CLEAR_DATA');
        await store.dispatch('trainingSummary/CLEAR_DATA');
      }
      if (key === 'weight' || key === 'units') {
        await store.dispatch('racingGuidelines/CLEAR_DATA');
        await store.dispatch('zones/CLEAR_DATA');
      }

      switch (statusCode) {
        case 200: return true;
        case 422: return null;
        default: return false;
      }
    } catch {
      return false;
    }
  };

  return {
    createdDateUTC,
    currentDate,
    currentDateUTC,
    isMetricSystem,
    measurementSystem,
    saveProperty,
    timeZone,
    user,
  };
};

const getDataForName = (name: string, user: User): object | null => {
  const names = name.split(' ');
  const firstName = names.shift();
  const lastName = names.join(' ');

  if (user.first_name === firstName && user.last_name === lastName) {
    return null;
  }

  return {
    first_name: firstName,
    last_name: lastName,
  };
};

const getDataForUnits = (value: string, user: User): object | null => {
  if (user.measurement_system.toString() === value) {
    return null;
  }

  return { measurement_system: value };
};

const getDataForField = (key: string, value: string, user: User): object | null => {
  // @ts-ignore `[key]` is not part of User type
  if (`${user[key]}` === value) {
    return null;
  }

  return { [key]: value === 'null' ? null : value };
};
