import { useStore } from '@nuxtjs/composition-api';
import { computed } from '@vue/composition-api';
import { State } from '~/data/types/store';
import { User, UserProfile } from '~/data/types';

export const useProfile = () => {
  const store = useStore<State>();
  const isLoading = computed(() => store.state.userProfile.isLoading);
  const user = computed<User | null>(() => store.state.auth.user?.data ?? null);
  const userId = computed(() => store.state.auth.user?.data.id ?? 0);
  const profile = computed<UserProfile | null>(() => store.state.userProfile.data);

  const clearProfile = async(): Promise<boolean> => {
    return await store.dispatch('userProfile/CLEAR_DATA');
  };

  const getMyProfile = async(): Promise<boolean> => {
    return await getUserProfile(userId.value);
  };

  const getUserProfile = async(id: number): Promise<boolean> => {
    try {
      await store.dispatch('userProfile/GET_USER_PROFILE', id);
      return true;
    } catch {
      return false;
    }
  };

  const saveProperty = async(name: string, value: string): Promise<boolean | null> => await saveProperties({ [name]: value });

  const saveProperties = async(inputData : {[key: string]: string}): Promise<boolean | null> => {
    let data: { [key: string]: any } = {};

    for (const [name, value] of Object.entries(inputData)) {
      const key = name.toLowerCase();
      const processedData = getDataForField(key, value, store.state.userProfile.data);

      data = { ...data, ...processedData };
    }

    if (!data) {
      return null;
    }

    try {
      const response = await store.dispatch('userProfile/UPDATE_USER_PROFILE', { id: userId.value, data });
      const statusCode = response?.statusCode || response?.data?.statusCode;

      switch (statusCode) {
        case 200: return true;
        case 422: return null;
        default: return false;
      }
    } catch {
      return false;
    }
  };

  function formatFollowers(followers: number): string {
    let formatted = '';
    // Display each follower/person following up to 999.
    if (followers < 1000) {
      formatted = followers.toString();
    // From 1K to 10K, display each 100 extra users with a decimal point. Ie. 2.4K followers 9.9K following
    } else if (followers < 10000) {
      formatted = (Math.floor(followers / 100) / 10).toFixed(1) + 'K';
    // Over 10K, remove the decimal point.
    } else if (followers < 1000000) {
      formatted = Math.floor(followers / 1000).toString() + 'K';
    // Over 999K, display 1M, 1.1M etc.
    } else {
      formatted = (Math.floor(followers / 100000) / 10).toFixed(1) + 'M';
    }
    // hide .0
    return formatted.replace('.0', '');
  }

  return {
    getMyProfile,
    getUserProfile,
    isLoading,
    profile,
    saveProperty,
    saveProperties,
    user,
    formatFollowers,
    clearProfile,
  };
};

const getDataForField = (key: string, value: string, profile: UserProfile): object | null => {
  if (key === 'primary_sport_id') {
    if (profile.primary_sport?.id.toString() === value) {
      return null;
    } else {
      return { primary_sport_id: value === 'null' ? null : value };
    }
  }

  // @ts-ignore `[key]` is not part of UserProfile type
  if (`${profile[key]}` === value) {
    return null;
  }

  return { [key]: value === 'null' ? null : value };
};
