import cloneDeep from 'lodash/cloneDeep';

export const state = () => ({
  list: [],
  isLoading: false,
});

// there are a lot of modules inside the platform when user can follows someone
// (sidebars, sliders), when user trigger follow action
// all similar modules should be updated
const relationshipsModules = (rootState) => {
  return {
    leaderboard: rootState.leaderboard.list.length,
    recommended: rootState.recommended.list.length,
    users: rootState.users.list.length,
    notifications: rootState.notifications.list.length || rootState.notifications.dropdownList.length,
    userProfile: rootState.userProfile.data !== null,
  };
};

const followersModules = (rootState) => {
  return {
    userProfile: rootState.userProfile.data !== null,
  };
};

export const actions = {
  async FOLLOW_USER({ commit, rootState }, action) {
    const updateFollowersCount = (increase) => {
      const followersModulesData = followersModules(rootState);
      Object.keys(followersModulesData).forEach((moduleName) => {
        if (followersModulesData[moduleName] > 0) {
          const payload = increase ? {} : { increase: false };
          commit(`${moduleName}/SET_FOLLOWERS_COUNT`, payload, { root: true });
        }
      });
    };

    const updateUserData = (increase) => {
      const newUser = cloneDeep(rootState.auth.user);
      newUser.data.usersFollowedCount = increase ? newUser.data.usersFollowedCount + 1 : newUser.data.usersFollowedCount - 1;
      this.$auth.setUser(newUser);
    };

    try {
      commit('SET_IS_LOADING', true);
      updateFollowersCount(true);
      updateUserData(true);

      const { data } = await this.$axios.$post('/api/follow', { user_id: action.userId }, {
        hideToastr: true,
      });

      const modules = relationshipsModules(rootState);
      Object.keys(modules).forEach((moduleName) => {
        if (modules[moduleName] > 0) {
          commit(`${moduleName}/SET_RELATIONSHIP`, { data }, { root: true });
        }
      });
      return true;
    } catch {
      updateFollowersCount(false);
      updateUserData(false);
      return false;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

  async UNFOLLOW_USER({ commit, rootState }, action) {
    const updateFollowersCount = (increase) => {
      const followersModulesData = followersModules(rootState);
      Object.keys(followersModulesData).forEach((moduleName) => {
        if (followersModulesData[moduleName] > 0) {
          const payload = increase ? {} : { increase: false };
          commit(`${moduleName}/SET_FOLLOWERS_COUNT`, payload, { root: true });
        }
      });
    };

    const updateUserData = (increase) => {
      const newUser = cloneDeep(rootState.auth.user);
      newUser.data.usersFollowedCount = increase ? newUser.data.usersFollowedCount + 1 : newUser.data.usersFollowedCount - 1;
      this.$auth.setUser(newUser);
    };
    try {
      commit('SET_IS_LOADING', true);
      updateFollowersCount(false);
      updateUserData(false);
      await this.$axios.$delete(`/api/follow/${action.ownFollowee.id}`, {
        hideToastr: true,
      });

      const modules = relationshipsModules(rootState);
      Object.keys(modules).forEach((key) => {
        if (modules[key] > 0) {
          commit(`${key}/REMOVE_RELATIONSHIP`, { userId: action.ownFollowee.user_id }, { root: true });
        }
      });
      return true;
    } catch (e) {
      updateFollowersCount(true);
      updateUserData(true);
      return false;
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },

};

export const mutations = {
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
};
