import { computed } from '@vue/composition-api';
import { useContext } from '@nuxtjs/composition-api';

export const useDevice = () => {
  const { $device, store } = useContext();
  const isSmallDevice = computed(() => {
    const result = store.getters['layout/isSmallDevice'];
    return result !== null ? result : $device.isMobile;
  });
  const isMediumDevice = computed(() => {
    const result = store.getters['layout/isMediumDevice'];
    return result !== null ? result : $device.isTablet;
  });
  const isLargeDevice = computed(() => {
    const result = store.getters['layout/isLargeDevice'];
    return result !== null ? result : $device.isDesktop;
  });
  const isMobileOrTabletDevice = computed(() => {
    const result = store.getters['layout/hasTouchPoints'];
    return result !== null ? result : $device.isMobileOrTablet;
  });

  return {
    isSmallDevice,
    isMediumDevice,
    isLargeDevice,
    isMobileOrTabletDevice,
  };
};
