import findIndex from 'lodash/findIndex';
import Vue from 'vue';
import storeInit from '~/helpers/store';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/users/recommended',
});

export const actions = {
  ...commonStore.actions,
};

export const mutations = {
  ...commonStore.mutations,
  SET_RELATIONSHIP(state, { data }) {
    const postIndex = findIndex(state.list, { id: data.user_id });
    Vue.set(state.list, postIndex, {
      ...state.list[postIndex],
      ownFollowee: data,
    });
  },
  REMOVE_RELATIONSHIP(state, action) {
    const obj = state.list.find(x => x?.id === action.userId);
    if (obj) { obj.ownFollowee = null; }
  },
};

export const getters = {
  ...commonStore.getters,
};
