import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { FeatureFlag } from '../types/feature-flags';
import { State } from '~/data/types/store';

export const useFeatureFlag = () => {
  const store = useStore<State>();
  const hasAccessToDashboardV2 = computed(() => true); // TODO: Remove DashboardV2
  const hasAccessToShareableMoments = computed(() =>
    hasAccess(FeatureFlag.ShareableMoments),
  );
  const hasAccessToShareWorkout = computed(() =>
    hasAccess(FeatureFlag.ShareableMomentsShareWorkout),
  );
  const hasAccessToEditableStats = computed(() =>
    hasAccess(FeatureFlag.EditableWorkoutStats),
  );
  const hasAccessToStravaGraphics = computed(
    () =>
      hasAccess(FeatureFlag.StravaGraphics) || hasGlobalAccessToStravaGraphics.value,
  );

  const hasGlobalAccessToStravaGraphics = computed(() =>
    hasGlobalAccess(FeatureFlag.StravaGraphics),
  );

  const hasAccess = (feature: FeatureFlag) =>
    store.state.auth.user?.data?.enabled_features.includes(feature) ?? false;

  const hasGlobalAccess = (feature: FeatureFlag): boolean | null => {
    if (store.state.featureFlags.item?.slug !== feature) {
      return null;
    }

    return store.state.featureFlags.item?.enabled;
  };

  const isLoading = computed(() => store.state.featureFlags.isLoading);

  const getGlobalSetting = async(slug: FeatureFlag) => {
    try {
      await store.dispatch('featureFlags/GET_ITEM', slug);
      return true;
    } catch {
      // Do nothing
    }
  };

  return {
    hasAccessToDashboardV2,
    hasAccessToEditableStats,
    hasAccessToShareableMoments,
    hasAccessToShareWorkout,
    hasAccessToStravaGraphics,
    getGlobalSetting,
    hasGlobalAccessToStravaGraphics,
    isLoading,
  };
}; ;
