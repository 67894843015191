export const state = () => ({
  isLoading: true,
  list: [],
});

export const actions = {
  async GET_SPORTS({ commit }, planType) {
    commit('SET_IS_LOADING', true);
    const { data } = await this.$axios.$get('/api/plan-sports', {
      params: {
        plan_type: planType,
      },
    });

    commit('SET_SPORTS', data);
    commit('SET_IS_LOADING', false);
  },
};

export const mutations = {
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
  SET_SPORTS(state, data) {
    state.list = data;
  },
};
