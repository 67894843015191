const getDefaultState = () => ({
  isLoading: false,
  url: '',
  id: null,
  data: null,
  hasErrors: false,
  type: null,
});

export const state = () => getDefaultState();

const makeTrainingSummaryImage = item => ({
  url: item.url,
  id: item.id,
});

const makeShareableMoment = item => ({
  url: item.image_url,
  id: item.id,
  data: item.formatted_data,
  type: item.type,
});

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_TRAINING_SUMMARY_IMAGE({ state, commit }, { type }) {
    try {
      if (state.isLoading) {
        return;
      }

      commit('SET_LOADING_START');

      const response = await this.$axios.$get('/api/shareable-moments/training-summary/image', {
        params: {
          type,
        },
        hideToastr: true,
      });

      commit('SET_SHAREABLE_MOMENT', makeTrainingSummaryImage(response.data));
      commit('SET_LOADING_END');
    } catch (e) {
      commit('CLEAR_DATA');
      commit('SET_ERROR');
      return Promise.reject(e);
    }
  },
  async GET_WORKOUT_IMAGE({ state, commit }, { id, data }) {
    try {
      if (state.isLoading) {
        return;
      }
      commit('SET_LOADING_START');
      const response = await this.$axios.$get(`/api/shareable-moments/training-plans/${id}/image`, {
        params: {
          ...data,
        },
        hideToastr: true,
      });

      commit('SET_SHAREABLE_MOMENT', makeShareableMoment(response.data));
      commit('SET_LOADING_END');
    } catch (e) {
      commit('CLEAR_DATA');
      commit('SET_ERROR');
      return Promise.reject(e);
    }
  },
  async GET_RACE_COMPLETED_IMAGE({ state, commit }, { id, data }) {
    try {
      if (state.isLoading) {
        return;
      }
      commit('SET_LOADING_START');
      const response = await this.$axios.$get(`/api/shareable-moments/${id}/race-completed-image`, {
        params: {
          ...data,
        },
        hideToastr: true,
      });

      commit('SET_SHAREABLE_MOMENT', makeShareableMoment(response.data));
      commit('SET_LOADING_END');
    } catch (e) {
      commit('CLEAR_DATA');
      commit('SET_ERROR');
      return Promise.reject(e);
    }
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_SHAREABLE_MOMENT(state, data) {
    state.url = data.url;
    state.id = data.id;
    state.data = data?.data ?? null;
    state.type = data?.type;
    state.hasErrors = false;
  },
  SET_ERROR(state) {
    state.hasErrors = true;
  },
  SET_LOADING_START(state) {
    state.isLoading = true;
  },
  SET_LOADING_END(state) {
    state.isLoading = false;
  },
};
