var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"training-plan-item",class:{
    'training-plan-item--collapsable': _vm.mobile,
    'training-plan-item--desktop': !_vm.mobile,
  }},[(_vm.trainingPlanItem)?_c('training-plan-update-form',{attrs:{"item":_vm.trainingPlanItem,"from-ref":_vm.fromRef},on:{"saving":function($event){return _vm.setSavingStatus(true)},"invalid":_vm.updateFormIsInvalid,"done":_vm.itemWasSaved}},[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"d-flex flex-column-reverse flex-xl-row"},[_c('div',{staticClass:"training-plan-item__details col-12",class:{ 'col-xl-8 pr-0 pr-xl-4': !_vm.mobile }},[_c('content-card',{attrs:{"title":"Details","collapsable":_vm.mobile,"collapsed":_vm.trainingPlanItem.isCompleted,"column-layout":!_vm.mobile}},[_c('training-plan-item-keywords',{attrs:{"from-ref":_vm.fromRef}},[_c('training-plan-item-description',{attrs:{"data-id":_vm.trainingPlanItem.id,"description":_vm.trainingPlanItem.description || ''}}),_vm._v(" "),_c('training-plan-item-print'),_vm._v(" "),(
                  _vm.trainingPlanItem.nutrition_details ||
                    _vm.trainingPlanItem.has_suggested_calories
                )?_c('training-plan-item-nutrition',{staticClass:"mt-3 pt-3 border-top border-light",attrs:{"nutrition-details":_vm.trainingPlanItem.nutrition_details || '',"has-suggested-calories":_vm.trainingPlanItem.has_suggested_calories,"from-ref":_vm.fromRef}}):_vm._e()],1)],1),_vm._v(" "),(!_vm.isEvent && !_vm.isEventRecoveryWithNoMovement && _vm.mobile)?_c('content-card',{attrs:{"title":"Stats","collapsable":_vm.mobile,"collapsed":!_vm.hasAccessToEditableStats && !_vm.trainingPlanItem.isCompleted,"column-layout":false}},[(_vm.hasAccessToEditableStats)?_c('workout-stats',{attrs:{"stats":_vm.initialStats}}):_c('imported-activity-stats',{attrs:{"item":_vm.trainingPlanItem.imported_activity || {},"completed":_vm.trainingPlanItem.isCompleted,"horizontal":!_vm.isSmallDevice}})],1):_vm._e(),_vm._v(" "),_c('content-card',{attrs:{"title":"Notes","collapsable":_vm.mobile,"column-layout":!_vm.mobile}},[_c('training-plan-item-notes',{attrs:{"item":_vm.trainingPlanItem,"from-ref":_vm.fromRef},on:{"changed":_vm.completionStatusWasChanged}}),_vm._v(" "),_c('div',{staticClass:"save-btn-container mt-4 pt-4 border-top border-light d-flex justify-content-end gap-2"},[_c('div',{staticClass:"btn btn-link text-danger d-none d-lg-block",on:{"click":_vm.deleteItem}},[_vm._v("\n                Delete Workout\n              ")]),_vm._v(" "),_c('base-button',{attrs:{"variant":"primary","type":"submit","label":"Save Workout","disabled":_vm.isSaving}})],1)],1),_vm._v(" "),_c('div',{staticClass:"d-block d-lg-none text-center mt-2"},[_c('div',{staticClass:"btn btn-link text-danger",on:{"click":_vm.deleteItem}},[_vm._v("\n              Delete Workout\n            ")])])],1),_vm._v(" "),(!_vm.isEvent && !_vm.isEventRecoveryWithNoMovement && !_vm.mobile)?_c('div',{staticClass:"training-plan-item__stats col-xl-4 d-xl-block pl-xl-4 pr-0 border-left border-light"},[(
              _vm.hasAccessToShareWorkout &&
                _vm.trainingPlanItem &&
                _vm.showShareWorkoutCard
            )?_c('div',{staticClass:"share-workout p-4 rounded mb-4"},[_c('div',{staticClass:"share-workout-card-container d-flex flex-column gap-2 m-auto"},[_c('content-card',{staticClass:"w-100",attrs:{"sm":""}},[_c('share-workout-image-card',{attrs:{"status":_vm.status,"discipline":_vm.workoutDiscipline,"from-ref":_vm.fromRef}})],1)],1)]):_vm._e(),_vm._v(" "),_c('content-card',{attrs:{"title":"Stats","column-layout":false}},[(_vm.hasAccessToEditableStats)?_c('workout-stats',{attrs:{"stats":_vm.initialStats}}):_c('imported-activity-stats',{attrs:{"item":_vm.trainingPlanItem.imported_activity || {},"completed":_vm.trainingPlanItem.isCompleted}})],1)],1):_vm._e()])])]):_vm._e(),_vm._v(" "),(_vm.trainingPlanItem)?_c('modal-confirmation',{attrs:{"id":"modal-delete-training-plan-item","title":"Are you sure you want to delete this workout?","submit-label":"Delete Workout","submit-action":_vm.deleteItemWasConfirmed}},[_c('p',{staticClass:"mb-0"},[_vm._v("\n      Just letting you know, this action cannot be undone.\n    ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }