const REDIRECT_MAP = {
  '/my-account/change-password': '/account/change-password',
  '/my-account/membership': '/account/billing',
  '/my-account/profile': '/account/profile',
  '/my-training/connections': '/account/connections',
  '/my-training/races': '/training/races',
  '/my-training/zones': '/training/zones',
  '/athlete': '/profile?ref=athlete',
  '/feed': '/groups',
};

export default async function({ env, redirect, route, store, $auth, params }) {
  const isUserLoggedIn = store.state.auth.loggedIn;
  const isUnsubscribed = store.getters['user/isUnsubscribed'];

  if (
    !route.path.includes('impersonate')
    && env.BASE_BETA_URL
    && !env.IS_BETA
    && isUserLoggedIn
    && store.state.auth.user?.data?.has_beta_access
  ) {
    const token = $auth.$storage.getCookie('_token.local');
    await $auth.setUserToken(token);
    redirect(302, env.BASE_BETA_URL + '/impersonate/?token=' + token);
    return;
  }

  // if the athlete id is the current user redirect to my profile
  if (route.path.startsWith('/athlete/')) {
    const currentUserId = store.state.auth.user?.data?.id;
    if (params.id && currentUserId && params.id === currentUserId.toString()) {
      return redirect('/profile?ref=athlete');
    }
  }

  if (route.path.startsWith('/feed/')) {
    if (route.path !== '/feed/') {
      return redirect(route.path.replace('feed', 'post'));
    } else {
      return redirect('/groups');
    }
  }

  // Legacy route support for iOS
  if (REDIRECT_MAP[route.path]) {
    redirect(REDIRECT_MAP[route.path]);
    return;
  }

  // Legacy route support for iOS
  if (route.path === '/terms-and-conditions') {
    redirect('https://mymottiv.com/terms-and-conditions');
    return;
  }

  // Legacy route support for iOS
  if (route.path.startsWith('/my-training/workout-settings')) {
    redirect('/training/settings');
    return;
  }

  if (isUserLoggedIn && isUnsubscribed) {
    if (!route.path.includes('logout')) {
      await $auth.logout();
      window.location.href = '/login'; // Hard reset of the app to reset the store
    }
    return;
  }

  if (
    (route.path === '/'
      || route.path === '/login/'
      || route.path === '/login')
    && isUserLoggedIn
  ) {
    redirect('/dashboard');
  }
}
