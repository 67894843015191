const getDefaultState = () => ({
  isLoading: false,
  isSavingId: null,
  data: null,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async DELETE_TRAINING_PLAN_ITEM({ commit }, { id, funnelId, ref }) {
    commit('SET_IS_LOADING', true);
    try {
      await this.$axios.$delete(`/api/training-plans/${id}`, {
        params: {
          ref,
          funnel_id: funnelId,
        },
      });
      commit('SET_DATA', null);
      commit('SET_IS_LOADING', false);
    } catch (e) {
      commit('SET_IS_LOADING', false);
      return Promise.reject(e);
    }
  },
  async GET_TRAINING_PLAN_ITEM({ commit }, { id, ref, funnelId }) {
    commit('SET_IS_LOADING', true);
    try {
      const response = await this.$axios.$get(`/api/training-plans/${id}`, {
        params: {
          ref,
          funnel_id: funnelId,
        },
      });
      commit('SET_DATA', response.data);
      commit('SET_IS_LOADING', false);
    } catch (e) {
      commit('SET_IS_LOADING', false);
      return Promise.reject(e);
    }
  },
  async UPDATE_TRAINING_PLAN_ITEM({ commit }, { id, data }) {
    commit('SET_IS_LOADING', true);
    try {
      const response = await this.$axios.put(`/api/training-plans/${id}`, data);
      commit('SET_DATA', response.data.data);
      commit('SET_IS_LOADING', false);
      return response.data.data;
    } catch (e) {
      commit('SET_IS_LOADING', false);
      return Promise.reject(e);
    }
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
  SET_IS_LOADING_IN_BACKGROUND(state, value) {
    state.isLoadingInBackground = value;
  },
  SET_IS_SAVING_ID(state, value) {
    state.isSavingId = value;
  },
  SET_DATA(state, data) {
    state.data = data;
  },
};
