import { useContext, useRoute, useStore } from '@nuxtjs/composition-api';
import { v4 as uuidv4 } from 'uuid';
import { State } from '~/data/types/store';

const mixpanel = require('mixpanel-browser');

export enum AnalyticsEvent {
  AddRaceClicked = 'add_race_click',
  BannerClicked = 'banner_click',
  CommunityGroupClicked = 'community_group_click',
  CustomizeZonesClicked = 'customize_your_zones',
  EmptyFriendsFindClicked = 'find_mottivators_click',
  FilterLeaderboard = 'filter_leaderboard',
  FilterRecommended = 'filter_recommended',
  FilterUsers = 'filter_mottivator',
  FiveAthlete = 'five_athlete',
  FollowAthlete = 'follow_athlete',
  KeywordClicked = 'keyword_click',
  MembershipDowngradeClicked = 'free_downgrade_click',
  MembershipPlanClicked = 'plan_select',
  NotificationIconClicked = 'notification_click',
  NotificationImageShared = 'notification_post_share',
  OnboardingBumpersDismissClicked = 'no_personlized_training_click',
  RaceClicked = 'race_click',
  RacingGuidelinesTypeClicked = 'racing_guidelines_select',
  RegisterBikeSelected = 'bike_distance_select',
  RegisterDobSelected = 'dob_select',
  RegisterGenderSelected = 'gender_select',
  RegisterGenerateCompleted = 'join_the_mottiv_movement',
  RegisterGoalSelected = 'goal_select',
  RegisterHeartRateSelected = 'heart_rate_data_select',
  RegisterLandingNextClick = 'lets_do_it_register_page_click',
  RegisterRunSelected = 'run_distance_select',
  RegisterSignUp = 'sign_up',
  RegisterSportsSelected = 'train_races_select',
  RegisterStartDateSelected = 'start_date_select',
  RegisterSwimSelected = 'swim_distance_select',
  RegisterWeightSelected = 'current_weight_select',
  ScheduleChangeHoursClicked = 'change_hours_click',
  ScheduleOffseasonChanged = 'offseason_select',
  ScheduleUpdated = 'updates_training_plan',
  ScheduleWorkoutAdded = 'add_workout_schedule',
  ScheduleWorkoutDeleted = 'delete_workout_schedule',
  ScheduleWorkoutMoved = 'move_workout_schedule',
  TheFeedClicked = 'thefeed_visit',
  TodaysMediaPlayed = 'todays_media_play',
  TodaysTaskMarkAsCompleteClicked = 'mark_as_complete_click',
  TrainingSummaryShared = 'share_training_summary',
  TrainingZonesTypeClicked = 'training_zone_select',
  ViewAllLeaderboardClicked = 'view_all_leaderboard',
  ViewAllMottivatorsClicked = 'view_all_mottivators',
  WorkoutCompletionStatusSelected = 'prescribed_workout_select',
  WorkoutPrintClicked = 'print_workout',
  WorkoutShareClicked = 'share_workout_click',
  WorkoutShared = 'share_workout',
}

export enum AnalyticsFunnel {
  AddRace = 'addRaceFunnelId',
  Billing = 'billingFunnelId',
  Login = 'loginFunnelId',
  Register = 'registerFunnelId',
  Schedule = 'scheduleFunnelId',
};

export enum AnalyticsPageView {
  AthleteProfile = 'athlete_page_visit',
  Dashboard = 'dashboard_page_visit',
  Feed = 'mottivator_feed_visit',
  Login = 'log_in_page_visit',
  MembershipOptions = 'membership_options_page_visit',
  MyAccount = 'my_account_page_visit',
  MyAccountTab = 'my_account_tab_visit',
  MyProfile = 'profile_page_visit',
  Races = 'races_page_visit',
  Register = 'register_page_visit',
  Schedule = 'schedule_page_visit',
  TrainingPlan = 'training_plan_visit',
  Zones = 'zones_page_visit',
}

const FROM_WHERE_KEY = 'From Where';
const FROM_SOURCE_KEY = 'From Source';

type TrackOptions = {
  useCache?: boolean;
  customUrl?: string | null;
};

export const useAnalytics = () => {
  const { $config } = useContext();
  const store = useStore<State>();
  const route = useRoute();

  const handleMixpanelIsReady = async() => {
    const id = mixpanel.get_distinct_id();

    if (id) {
      store.commit('analytics/SET_MIXPANEL_ID', id);
    }

    if (store.state.auth.user?.data?.analytics_id && id && !route.value.fullPath.includes('impersonate')) {
      const result = await mixpanel.alias(id, store.state.auth.user.data.analytics_id);

      if (result?.properties) {
        const superData: { [key: string]: any } = {};

        Object.keys(result.properties).forEach((key) => {
          if (key.startsWith('$browser') || key.startsWith('$current') || key.startsWith('$initial') || key.startsWith('$os') || key.startsWith('$referring') || key.startsWith('$screen')) {
            superData[key] = result.properties[key];
          }
        });

        store.commit('analytics/SET_DEFAULT_SUPER_EVENT_DATA', superData);
        store.commit('analytics/SET_HAS_CREATED_ALIAS', true);
      }
    }
  };

  const configure = () => {
    /**
     * Attempt to get the current Mixpanel Distinct ID. If that fails,
     * then we know that Mixpanel hasn't been initialized yet.
     */
    try {
      mixpanel.get_distinct_id();
      handleMixpanelIsReady();
    } catch {
      mixpanel.init($config.MIXPANEL_TOKEN, {
        cross_subdomain_cookie: true,
        batch_requests: false,
        loaded: () => {
          handleMixpanelIsReady();
        },
      });
    }
  };

  const track = (event: string, data: { [key: string]: any }, options: TrackOptions = {}) => {
    if (!data[FROM_WHERE_KEY] && !data.ref && route.value.query?.ref) {
      data[FROM_WHERE_KEY] = route.value.query.ref;
    }

    if (!data[FROM_SOURCE_KEY] && route.value.query?.src) {
      data[FROM_SOURCE_KEY] = route.value.query.src;
    }

    if (store.state.analytics.hasCreatedAlias && store.state.auth.user?.data) {
      setTimeout(() => {
        store.dispatch('analytics/TRACK_EVENT', {
          url: options.customUrl,
          data: {
            event,
            data: {
              ...store.state.analytics.defaultSuperEventData,
              ...data,
            },
            use_cache: !!options.useCache,
          },
        });
      }, 1000); // add delay to ensure request doesn't block the browser "request queue"
    } else {
      try {
        mixpanel.track(event, data);
      } catch {}
    }
  };

  const getTrackEventDataForSearch = (moduleName: 'users' | 'recommended' | 'leaderboard', params: { [key: string]: any }): { [key: string]: any } => {
    const data: { [key: string]: any } = {
      Name: null,
      Race: null,
      Location: null,
      'Total Search Results': store.state[moduleName].pagination.total ?? 0,
    };

    if (params.location?.length) {
      data.Location = params.location;
    }

    if (params['filter[userNames]']?.length) {
      data.Name = params['filter[userNames]'];
    }

    if (store.state.raceEvents.list.length && params['filter[workoutEventIds]']?.length) {
      data.Race = store.state.raceEvents.list.filter(item => item.id === Number(Array(params['filter[workoutEventIds]'])[0]))[0].title ?? null;
    }

    return data;
  };

  const trackEvent = (event: AnalyticsEvent, data: { [key: string]: any } = {}, url: string | null = null) =>
    track(event, data, { customUrl: url });

  const trackPageView = (event: AnalyticsPageView, data: { [key: string]: any } = {}, useCache: boolean = true) =>
    track(event, data, { useCache });

  const setProfileData = (data: { [key: string]: any }) => mixpanel.people.set(data);

  const setSuperEventData = (data: { [key: string]: any }) => mixpanel.register(data, {
    /**
     * Persistence is set to `false` to ensure super event data clears when the user logs out.
     * We don't want to call `reset` when the user is unauthenticated as that will reset the
     * Mixpanel Distinct ID and we'll have to keep creating aliases.
     */
    persistent: false,
  });

  const startFunnel = (funnel: AnalyticsFunnel) => {
    store.commit('analytics/SET_FUNNEL', { key: funnel, value: uuidv4() });
  };

  const getFunnelId = (funnel: AnalyticsFunnel): string | null => store.state.analytics[funnel] ?? null;

  return {
    configure,
    getFunnelId,
    getTrackEventDataForSearch,
    setProfileData,
    setSuperEventData,
    startFunnel,
    trackEvent,
    trackPageView,
  };
};
