import { computed, ref } from '@vue/composition-api';
import { useContext, useStore } from '@nuxtjs/composition-api';
import { differenceInCalendarDays, isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import { SubscriptionPlanType, UserSubscription, UserSubscriptionStatus, UserSubscriptionType } from '~/data/types';
import { State } from '~/data/types/store';
import { convertDashesToSlashes, DateFormat } from '~/helpers/date';
import { getDeviceToken } from '~/data/auth/device-token';
import { useOnSuccess } from '~/data/auth/use-on-success';
import { useRaces } from '~/data/training/use-races';
import { EventBus, Key } from '~/helpers/eventBus';
import { AnalyticsFunnel, useAnalytics } from '~/composables/use-analytics';

type Card = {
  type: string,
  number: string,
};

export const useUserSubscription = () => {
  const { $auth } = useContext();
  const store = useStore<State>();
  const { onSuccess } = useOnSuccess();
  const { getFunnelId } = useAnalytics();
  const { upcoming: upcomingRaces, isToday: isRaceToday } = useRaces();
  const isLoading = ref(false);
  const hasSubscription = computed<boolean>(() => !!store.state.auth.user.data.subscription);
  const subscription = computed<UserSubscription | null>(() => store.state.auth.user.data.subscription || null);
  const currentPaymentAmount = computed<string | null>(() => {
    if (!store.state.auth.user.data.subscription) {
      return null;
    }

    if (store.state.auth.user.data.subscription.payment_amount && store.state.auth.user.data.subscription.payment_interval) {
      return `$${store.state.auth.user.data.subscription.payment_amount}/${store.state.auth.user.data.subscription.payment_interval}`;
    }

    return store.state.auth.user.data.subscription?.stripe_plan_title || null;
  });
  const currentBillingAmount = computed<number | null>(() => store.state.auth.user.data.subscription?.payment_amount || null);
  const hasDowngradeSchedule = computed<boolean>(() =>
    store.state.auth.user.data.subscription?.stripe_scheduled_plan_type === SubscriptionPlanType.Free);
  const scheduledIsPremium = computed<boolean | null>(() => {
    const isPremium = store.state.auth.user.data.subscription?.stripe_scheduled_plan_is_premium;
    return (isPremium === null || isPremium === undefined)
      ? null
      : isPremium;
  });
  const scheduledTitle = computed<string | null>(() => store.state.auth.user.data.subscription?.stripe_scheduled_plan_title || null);
  const nextPaymentDate = computed<string | null>(() => {
    if (store.state.auth.user.data.subscription?.next_payment_date) {
      const nextPaymentDate = new Date(convertDashesToSlashes(store.state.auth.user.data.subscription.next_payment_date));
      return isValid(nextPaymentDate) ? formatInTimeZone(nextPaymentDate, 'UTC', DateFormat.Human) : null;
    }
    return null;
  });
  const cardDetails = computed<Card | null>(() => {
    if (!!store.state.auth.user.data.card_brand && !!store.state.auth.user.data.card_last_four) {
      return {
        type: store.state.auth.user.data.card_brand,
        number: store.state.auth.user.data.card_last_four,
      };
    }
    return null;
  });
  const hasDiscount = computed(() => {
    if (!subscription.value || !currentPaymentAmount.value) {
      return null;
    }

    return subscription.value.stripe_plan_title !== currentPaymentAmount.value;
  });

  const hasTrialingStatus = computed(() => store.state.auth.user?.data?.subscription?.status === UserSubscriptionStatus.Trialing);
  const hasCardDetails = computed(() => !!store.state.auth.user?.data?.card_last_four && !!store.state.auth.user?.data?.card_brand);
  const hasStripeSubscription = computed(() => store.state.auth.user?.data?.subscription?.type === UserSubscriptionType.Stripe);
  const hasAppleIAPSubscription = computed(() => store.state.auth.user?.data?.subscription?.type === UserSubscriptionType.AppleIAP);
  const isOnFreeTrial = computed(() => isTrialing.value && !hasCardDetails.value);
  const isTrialing = computed(() => hasTrialingStatus.value && !hasCardDetails.value && hasStripeSubscription.value);
  const isActive = computed(() => store.state.auth.user?.data?.subscription?.status === UserSubscriptionStatus.Active
    || store.state.auth.user?.data?.subscription?.status === UserSubscriptionStatus.PastDue);
  const isUnsubscribed = computed<boolean>(() => store.getters['user/isUnsubscribed']);
  const isPremium = computed(() => !!store.state.auth.user?.data?.subscription?.is_premium);
  const isOnFreePlan = computed(() => !isPremium.value && !isTrialing.value);
  const canAddFitnessOnlyConnection = computed(() => !isOnFreePlan.value);
  const canAddRace = computed(() => {
    if (isPremium.value || isTrialing.value || upcomingRaces.value.length === 0) {
      return true;
    }

    const racesAfterToday = upcomingRaces.value.filter(race => !isRaceToday(race));
    return racesAfterToday.length === 0;
  });
  const canViewStats = computed(() => !isOnFreePlan.value);
  const trialExpiryDays = computed(() => {
    if (isTrialing.value && !!store.state.auth.user.data.trial_ends_at) {
      const trialEndsAt = new Date(convertDashesToSlashes(store.state.auth.user.data.trial_ends_at));
      const date = new Date(formatInTimeZone(new Date(), 'UTC', DateFormat.DateTime));
      return differenceInCalendarDays(trialEndsAt, date);
    }

    return null;
  });
  const trialExpiresToday = computed(() => trialExpiryDays.value != null && trialExpiryDays.value <= 0);
  const trialExpiryDaysHuman = computed(() => {
    if (trialExpiryDays.value == null) {
      return null;
    }

    return trialExpiresToday.value
      ? 'today'
      : `${trialExpiryDays.value} day${trialExpiryDays.value === 1 ? '' : 's'}`;
  });
  const getAppleSubscriptionInterval = computed(() => {
    const stripePlanId = store.state.auth.user?.data?.subscription?.stripe_plan_id || '';

    if (stripePlanId.includes('monthly')) {
      return SubscriptionPlanType.Monthly;
    }
    if (stripePlanId.includes('yearly')) {
      return SubscriptionPlanType.Yearly;
    }

    return null;
  });

  const changePaymentMethod = async(token: string): Promise<boolean> => {
    isLoading.value = true;
    try {
      const response = await store.dispatch({
        type: 'user/changeSettings',
        endpoint: 'user-subscription/change-payment-method',
        data: {
          card_token: token,
        },
      });
      if (response?.statusCode === 200 && response?.data?.id) {
        $auth.setUser(response);
        return true;
      }
    } catch {
      // Do nothing
    }

    isLoading.value = false;
    return false;
  };

  const changePlan = async(planId: string, discountCode: string | null): Promise<boolean> => {
    isLoading.value = true;

    try {
      const response = await store.dispatch({
        type: 'user/changeSettings',
        endpoint: 'user-subscription/change-plan',
        data: {
          plan_id: planId,
          discount_code: discountCode,
          funnel_id: getFunnelId(AnalyticsFunnel.Billing),
        },
      });
      if (response?.statusCode === 200 && response?.data?.id) {
        $auth.setUser(response);
        return true;
      }
    } catch {
      // Do nothing
    }

    isLoading.value = false;
    return false;
  };

  const deleteAccount = async(): Promise<boolean> => {
    isLoading.value = true;

    try {
      await store.dispatch('user/deleteUser');
      await $auth.logout();
    } catch {
      // Do nothing
    }

    isLoading.value = false;
    return false;
  };

  const upgrade = async(token: string, planId: string, discountCode: string | null): Promise<boolean> => {
    isLoading.value = true;

    try {
      const response = await store.dispatch('user/upgrade', {
        card_token: token,
        discount_code: discountCode,
        plan_id: planId,
        platform: 'web',
        device_token: getDeviceToken(),
        funnel_id: getFunnelId(AnalyticsFunnel.Billing),
      });
      if (response?.data?.statusCode === 200 && response?.data?.data?.id) {
        const shouldRememberMe = !!$auth.$storage.getCookie('_token_remember.local');
        await onSuccess(response, shouldRememberMe);
        return true;
      }
    } catch {
      // Do nothing as error will be displayed from store
    }

    isLoading.value = false;
    return false;
  };

  const cancelDowngrade = async(): Promise<boolean> => {
    isLoading.value = true;

    try {
      const response = await store.dispatch('user/cancelDowngrade');
      if (response?.data?.statusCode === 200) {
        $auth.setUser(response.data);
        return true;
      }
    } catch {
      // Do nothing
    }

    isLoading.value = false;
    return false;
  };

  const submitDowngradeSurvey = async(reason: string) => {
    try {
      // Dispatch an action to the store
      await store.dispatch('subscription/DOWNGRADE_SURVEY', { reason });
      EventBus.$emit(Key.ShowSuccessToast, 'Thanks for your feedback.');
    } catch {
      // Do nothing
    }
  };

  return {
    canAddFitnessOnlyConnection,
    canAddRace,
    cancelDowngrade,
    canViewStats,
    cardDetails,
    changePaymentMethod,
    changePlan,
    currentBillingAmount,
    currentPaymentAmount,
    deleteAccount,
    getAppleSubscriptionInterval,
    hasAppleIAPSubscription,
    hasCardDetails,
    hasDiscount,
    hasDowngradeSchedule,
    hasStripeSubscription,
    hasSubscription,
    isActive,
    isLoading,
    isOnFreePlan,
    isOnFreeTrial,
    isPremium,
    isTrialing,
    isUnsubscribed,
    nextPaymentDate,
    scheduledIsPremium,
    scheduledTitle,
    submitDowngradeSurvey,
    subscription,
    trialExpiresToday,
    trialExpiryDays,
    trialExpiryDaysHuman,
    upgrade,
  };
};
