export default function({ redirect, route, store }) {
  const isUserLoggedIn = store.state.auth.loggedIn;
  const hasCompletedSteps = !!store.state.registration.startDate;
  const hasCompletedAccount = !!store.state.registration.signupMethod;
  const hasStartedRegistration = !!store.state.registration.startedAt;

  if (isUserLoggedIn) {
    redirect('/dashboard');
  }

  if (route.params.step && !hasStartedRegistration) {
    redirect('/register');
  }

  if (route.path.startsWith('/register/generate') && !hasCompletedSteps) {
    redirect('/register');
  }

  if (route.path.startsWith('/register/account') && !hasCompletedSteps) {
    redirect('/register');
  }

  if (route.path.startsWith('/register/billing') && !route.query.code && !hasCompletedAccount) {
    redirect('/register');
  }
};
