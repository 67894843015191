import { format, isAfter, isBefore, isSameDay, parse } from 'date-fns';
import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz';
import isString from 'lodash/isString';

export enum DateFormat {
  Date = 'yyyy-MM-dd',
  DateTime = 'yyyy-MM-dd HH:mm:ss',
  DateTimeLocal = "yyyy-MM-dd'T'HH:mm:ss",
  Human = 'MMM d, yyyy',
  ISO = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'",
  Number = 'yyyyMMdd',
}

export const formatDate = (
  dateString: string,
  fromFormat: DateFormat = DateFormat.Date,
  toFormat: DateFormat = DateFormat.Human,
) => {
  const date = parse(dateString, fromFormat, new Date());
  return format(date, toFormat);
};

export const isDateBetweenDates = (
  date: Date,
  firstDate: Date,
  secondDate: Date,
): boolean => {
  return (
    isSameDay(date, firstDate)
    || isSameDay(date, secondDate)
    || (isAfter(date, firstDate) && isBefore(date, secondDate))
  );
};

export const getUTCDate = (dateString: string, timeZone: string) => {
  const dateStringInTimeZone = formatInTimeZone(
    new Date(dateString),
    'UTC',
    DateFormat.Date,
  );
  return zonedTimeToUtc(dateStringInTimeZone, timeZone);
};

/**
 * Unfortunately the iOS webview returns "Invalid Date" when parsing dates in YYYY-MM-DD format.
 * To be able to parse the dates without error, we need to replace "-" with "/".
 * Refer https://stackoverflow.com/questions/4310953/invalid-date-in-safari
 */
export const convertDashesToSlashes = (date: string | Date): string | Date =>
  isString(date) ? date.replace(/-/g, '/') : date;
