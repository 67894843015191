import { getCookie } from '~/utils/cookieStorage';
import { uniqID } from '~/utils/hash';

export const getDeviceToken = () => {
  let deviceToken = getCookie('device_token');

  if (!deviceToken) {
    deviceToken = uniqID(navigator.userAgent);
    document.cookie = `device_token=${deviceToken}`;
  }

  return deviceToken;
};
