import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { SubscriptionPlan } from '~/data/types/subscription';
import { State } from '~/data/types/store';

export const useSubscriptionPlans = () => {
  const store = useStore<State>();

  const isLoading = computed(() => store.state.subscription.isLoading);

  const plans = computed(() => store.state.subscription.plans
    .filter(item => !isNaN(parseInt(`${item.billing_amount}`)) && item.billing_interval !== null)
    .map(item => ({
      badge_content: item.badge_content,
      billing_amount: item.billing_amount,
      billing_interval: item.billing_interval,
      description: item.description || 'Plan',
      // @ts-ignore `plan_id` comes from API
      id: item.plan_id,
      is_discountable: item.is_discountable,
      is_premium: item.is_premium,
      summary: item.summary || 'MOTTIV Plan',
      title: item.title,
      type: item.type,
    })));

  const plansSortedByPrice = computed(() => structuredClone(plans.value)
    .sort((left, right) => {
      const leftBillingAmount = left.billing_amount || 0;
      const rightBillingAmount = right.billing_amount || 0;
      const leftSortAmount = left.is_premium ? leftBillingAmount + 100 : leftBillingAmount;
      const rightSortAmount = right.is_premium ? rightBillingAmount + 100 : rightBillingAmount;
      return leftSortAmount - rightSortAmount;
    }));

  const plansSortedByPremiumThenPrice = computed(() => structuredClone(plans.value)
    .sort((left, right) => {
      const leftBillingAmount = left.billing_amount || 0;
      const rightBillingAmount = right.billing_amount || 0;
      const leftSortAmount = left.is_premium ? leftBillingAmount - 1000 : leftBillingAmount;
      const rightSortAmount = right.is_premium ? rightBillingAmount - 1000 : rightBillingAmount;
      return leftSortAmount - rightSortAmount;
    }));

  const getPlans = async() => {
    if (!plans.value?.length) {
      await store.dispatch('subscription/getPlans');
    }
  };

  const getPlan = (id: string): SubscriptionPlan | null => {
    const validPlans = plans.value.filter(plan => id === plan.id);
    return validPlans ? validPlans[0] : null;
  };

  return {
    getPlan,
    getPlans,
    isLoading,
    plans,
    plansSortedByPremiumThenPrice,
    plansSortedByPrice,
  };
};
