import { removeAllCachedData } from '../utils/cacheUtils';

export default function({ $auth, store }) {
  const isCompletedOnboarding = store.state.auth.user?.data?.is_completed_onboarding;
  const isUnsubscribed = store.getters['user/isUnsubscribed'];

  // Watch state changes
  $auth.$storage.watchState('loggedIn', (newValue) => {
    if (!newValue) {
      store.dispatch('connections/CLEAR_DATA');
      store.dispatch('importedActivity/CLEAR_DATA');
      store.dispatch('dashboard/CLEAR_DATA');
      store.dispatch('trainingPlan/CLEAR_DATA');
      store.dispatch('trainingPlanItem/CLEAR_DATA');
      store.dispatch('trainingSummary/CLEAR_DATA');
      store.dispatch('trainingRecommendations/CLEAR_DATA');
      store.dispatch('workoutSettings/CLEAR_DATA');
      store.dispatch('shareableMoment/CLEAR_DATA');
      removeAllCachedData();
    }

    if (newValue && isCompletedOnboarding && !isUnsubscribed) {
      store.dispatch('connections/GET_CONNECTION');
    }
  });
}
