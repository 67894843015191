import { TrainingPlanItem } from './training';
import { UserFollowee } from './users';

export enum NotificationType {
  Comment = 'new_comment_on_post',
  Fiver = 'new_fiver',
  Follower = 'new_follower',
  IncompleteWorkout = 'uncompleted_workout',
  Reply = 'new_reply_to_comment',
  Upgrade = 'upgrade_to_premium',
  ShareableMomentPost = 'shareable_moment_training_summary',
  ShareableMomentImage = 'shareable_moment_image',
  NewCommunityGroup = 'new_community_group',
  DeprecatedCommunityGroup = 'deprecated_community_group',
};

export enum ReactionType {
  Comment = 3,
  Leaderboard = 4,
  Post = 2,
};

type NotificationPostParams = {
  post_id: number,
};

type NotificationCommentParams = NotificationPostParams & {
  comment_id: number,
};

type NotificationFiverParams = {
  post_id: number | undefined,
  user_reaction_id: number,
  user_reaction_type: ReactionType,
};

type NotificationFollowerParams = {
  follower_id: number,
  user_follow_id: number,
};

type NotificationIncompleteWorkoutParams = {
  training_plan_id: number,
};

export type NotificationShareableMomentParams = {
  'shareable_moment_id': number,
  'shareable_moment_type': string,
  'shareable_moment_url': string,
};

export type NotificationCommunityGroupParams = {
  community_group_id: number;
  community_group_type: string;
  community_group_slug: string;
};

export type NotificationItem = {
  created_at: string;
  id: string;
  is_read: boolean;
  message: string;
  params:
    | NotificationCommentParams
    | NotificationFiverParams
    | NotificationFollowerParams
    | NotificationIncompleteWorkoutParams
    | NotificationPostParams
    | NotificationShareableMomentParams
    | NotificationCommunityGroupParams;
  training_plan: TrainingPlanItem | null;
  type: NotificationType;
  user: {
    first_name: string;
    id: number;
    last_name: string;
    own_followee: UserFollowee;
  };
};
