import findIndex from 'lodash/findIndex';
import Vue from 'vue';
import storeInit from '~/helpers/store';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/users/leaderboard',
});

const splitDataForList = (commit, data) => {
  if (data.data.users) {
    commit('SET_LIST', data.data.users);
  }

  if (data.data.current_user) {
    commit('SET_ITEM', data.data.current_user);
  }
};

export const actions = {
  ...commonStore.actions,
  GET_LEADERSHIP_LIST: commonStore.actions.GET_LIST,
  async GET_LIST({ dispatch }, action) {
    await dispatch('GET_LEADERSHIP_LIST', {
      ...action,
      dataFormatterFunction: splitDataForList,
    });
  },
};

export const mutations = {
  ...commonStore.mutations,
  SET_RELATIONSHIP(state, { data }) {
    const itemIndex = findIndex(state.list, { id: data.user_id });
    Vue.set(state.list, itemIndex, {
      ...state.list[itemIndex],
      ownFollowee: data,
    });
  },
  REMOVE_RELATIONSHIP(state, action) {
    const obj = state.list.find(x => x?.id === action.userId);
    if (obj) { obj.ownFollowee = null; }
  },
  ADD_REACTION(state, data) {
    const listItem = state.list.find(x => x.id === data.modelId);

    if (listItem) {
      listItem.ownReaction = data.data;
    }
  },
  REMOVE_REACTION(state, { modelId }) {
    state.list.find(x => x.id === modelId).ownReaction = null;
  },
};

export const getters = {
  ...commonStore.getters,
};
