const loadedScripts = {};

function hashCode(s) {
  return s.split('').reduce(function(a, b) {
    a = ((a << 5) - a) + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export default {
  load(url, scriptParams = {}) {
    const id = hashCode(url);

    if (url in loadedScripts) {
      return loadedScripts[url];
    }

    const loadedScript = document.getElementById(id);
    if (loadedScript && !(url in loadedScripts)) {
      loadedScripts[url] = new Promise((resolve) => {
        loadedScript.onload = () => {
          resolve();
        };
      });
    } else {
      loadedScripts[url] = new Promise((resolve) => {
        const script = document.createElement('script');
        script.id = id;
        script.type = 'text/javascript';
        script.src = url;

        if (scriptParams.data) {
          for (const prop in scriptParams.data) {
            script.dataset[prop] = scriptParams.data[prop];
          }

          delete scriptParams.data;
        }

        for (const prop in scriptParams) {
          script[prop] = scriptParams[prop];
        }

        script.onload = () => {
          resolve();
        };

        document.head.append(script);
      });
    }

    return loadedScripts[url];
  },
  get(url) {
    return loadedScripts[url];
  },
};
