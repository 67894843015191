import { useRoute } from '@nuxtjs/composition-api';

export const useNavigation = () => {
  const route = useRoute();

  const isName = (name: string) => route.value.name === name;

  const startsWith = (name: string) => (route.value.name ?? '').startsWith(name);

  return {
    isName,
    startsWith,
  };
};
