import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { isBefore, isSameDay } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { TrainingSummary } from 'mottiv-web-components';

import { State } from '~/data/types/store';
import { CalendarActivity, TrainingPlanItem } from '~/data/types';
import { getWorkoutDetails, getWorkoutStatus } from '~/data/training/get-training-plan-details';
import { useUser } from '~/data/account/use-user';
import { DateFormat, getUTCDate, isDateBetweenDates } from '~/helpers/date';
import { SUPPORTED_TRAINING_SUMMARIES } from '~/constants';
import { useTrainingSummary } from '~/data/stats/use-training-summary';

export const useDashboard = () => {
  const store = useStore<State>();
  const { currentDateUTC, isMetricSystem, timeZone } = useUser();
  const { isLoadingSummaryForKey } = useTrainingSummary();

  const coachesMessage = computed(() => store.state.dashboard.coachesMessage || null);
  const hasRegenerationError = computed(() => store.state.dashboard.hasRegenerationError || false);
  const isLoading = computed(() => isLoadingMedia.value || isLoadingTrainingPlan.value || isLoadingCoachesMessage.value);
  const isLoadingCoachesMessage = computed(() => store.state.dashboard.isLoadingCoachesMessage);
  const isLoadingMedia = computed(() => store.state.dashboard.isLoadingMedia);
  const isLoadingTrainingPlan = computed(() => store.state.dashboard.isLoadingTrainingPlan);
  const media = computed(() => store.state.dashboard.media);
  const mediaNotYetWatched = computed(() => store.state.dashboard.media.filter(item => !item.watched_at));
  const hasWatchedAllMedia = computed(() => media.value.length && !mediaNotYetWatched.value.length);
  const trainingPlan = computed(() => store.state.dashboard.trainingPlan);
  const calendarActivities = computed<CalendarActivity[]>(() => {
    return store.state.dashboard.trainingPlan.map((item: TrainingPlanItem) => {
      const details = getWorkoutDetails(item, isMetricSystem.value);
      return {
        details: details ? [details] : null,
        id: item.id,
        workoutStatus: getWorkoutStatus(item, timeZone.value),
        title: item.workout.categoryTitleShort || item.workout.categoryTitle,
        type: item.workout.categoryKey,
      };
    });
  });
  const trainingSummaries = computed<TrainingSummary[]>(() => {
    const items: TrainingSummary[] = [];

    SUPPORTED_TRAINING_SUMMARIES.forEach((type) => {
      if (store.state.trainingSummary.summaries[type]) {
        items.push(store.state.trainingSummary.summaries[type]);
      }
    });

    return items;
  });
  const isLoadingTrainingSummaries = computed(() => SUPPORTED_TRAINING_SUMMARIES.filter(type => isLoadingSummaryForKey(type)).length);

  const deleteWorkout = async(id: number) => {
    await store.commit('dashboard/DELETE_TRAINING_PLAN_ITEM', id);
  };

  const setCoachesMessageAsLoading = (status: boolean = true) => {
    store.commit('dashboard/SET_IS_LOADING_COACHES_MESSAGE', status);
  };

  const getCoachesMessage = async(forceUpdate: boolean = false) => {
    if (!forceUpdate && store.state.dashboard.coachesMessage) {
      return;
    }
    await store.dispatch('dashboard/GET_COACHES_MESSAGE');
  };

  const getTrainingPlan = async() => {
    if (store.state.dashboard.trainingPlan.length) {
      return;
    }
    await store.dispatch('dashboard/GET_TRAINING_PLAN');
  };

  const getMedia = async() => {
    if (store.state.dashboard.media.length) {
      return;
    }
    await store.dispatch('dashboard/GET_MEDIA');
  };

  const markMediaAsWatched = async(id: number) => {
    await store.dispatch('dashboard/MARK_MEDIA_AS_WATCHED', id);
  };

  const isRaceCompleted = (date: Date): boolean => {
    const dateUTC = getUTCDate(formatInTimeZone(date, 'UTC', DateFormat.Date), timeZone.value);
    return isSameDay(dateUTC, currentDateUTC.value) || isBefore(dateUTC, currentDateUTC.value);
  };

  const isRaceToday = (date: Date): boolean => {
    const dateUTC = getUTCDate(formatInTimeZone(date, 'UTC', DateFormat.Date), timeZone.value);
    return isSameDay(dateUTC, currentDateUTC.value);
  };

  const isBetweenDates = (startDate: Date, endDate: Date) => {
    const startDateUTC = getUTCDate(formatInTimeZone(startDate, 'UTC', DateFormat.Date), timeZone.value);
    const endDateUTC = getUTCDate(formatInTimeZone(endDate, 'UTC', DateFormat.Date), timeZone.value);

    return isDateBetweenDates(currentDateUTC.value, startDateUTC, endDateUTC);
  };

  return {
    calendarActivities,
    coachesMessage,
    deleteWorkout,
    getCoachesMessage,
    getMedia,
    getTrainingPlan,
    hasRegenerationError,
    hasWatchedAllMedia,
    isBetweenDates,
    isLoading,
    isLoadingCoachesMessage,
    isLoadingMedia,
    isLoadingTrainingPlan,
    isLoadingTrainingSummaries,
    isRaceCompleted,
    isRaceToday,
    markMediaAsWatched,
    media,
    setCoachesMessageAsLoading,
    trainingPlan,
    trainingSummaries,
  };
};
