import storeInit from '~/helpers/store';

const commonStore = storeInit();

export const state = () => ({
  ...commonStore.state,
  requestUrl: '/api/users/my-uploads',
});

export const actions = {
  ...commonStore.actions,
  async GET_USER_GALLERY({ state, commit }, id, payload) {
    const params = payload && payload.params;
    const paginationQuery = {
      page: (params && params.page) || state.pagination.current_page,
      'per-page': (params && params['per-page']) || state.pagination.per_page,
    };

    const queryParams = {
      ...paginationQuery,
      ...params,
    };
    commit('SET_LIST_LOADING', true);

    await this.$axios.get(`/api/users/${id}/photos`, { params: queryParams })
      .then((res) => {
        const { data } = res.data;
        const { meta } = res.data;

        commit('SET_LIST', data);
        commit('UPDATE_PARAMS', queryParams);
        commit('UPDATE_PAGINATION', {
          current_page: meta.current_page,
          per_page: meta.per_page,
          last_page: meta.last_page,
          total: meta.total,
        });
      })
      .catch(err => err)
      .finally(() => {
        commit('SET_LIST_LOADING', false);
      });
  },
  async GET_NEXT_USER_GALLERY_PAGE({ state, commit }, id, payload) {
    const params = payload && payload.params;

    const paginationQuery = {
      page: (params && params.page) || (state.pagination.current_page + 1),
      'per-page': (params && params['per-page']) || state.pagination.per_page,
    };

    const queryParams = {
      ...state.queryParams,
      ...paginationQuery,
      ...params,
    };
    commit('SET_LIST_LOADING', true);
    await this.$axios.get(`/api/users/${id}/photos`, { params: queryParams })
      .then((res) => {
        const { data } = res.data;
        const { meta } = res.data;

        commit('PUSH_DATA_TO_LIST', data);
        commit('UPDATE_PARAMS', queryParams);
        commit('UPDATE_PAGINATION', {
          current_page: meta.current_page,
          per_page: meta.per_page,
          last_page: meta.last_page,
          total: meta.total,
        });
      })
      .catch(err => err)
      .finally(() => {
        commit('SET_LIST_LOADING', false);
      });
  },
};

export const mutations = {
  ...commonStore.mutations,
};
