import isArray from 'lodash/isArray';
import { addMinutes, parse } from 'date-fns';
import { RegistrationMethod } from '~/data/types/registration';
import { DateFormat } from '~/helpers/date';

const DEFAULT_DATA = {
  availableSports: [],
  capabilities: {
    swim: undefined,
    bike: undefined,
    run: undefined,
  },
  email: undefined,
  emailConfirmation: undefined,
  externalAccountId: undefined,
  externalSignedRequest: undefined,
  firstName: undefined,
  gender: undefined,
  heartRate: {
    max: undefined,
    resting: undefined,
  },
  isLoadingAvailableSports: false,
  lastName: undefined,
  measurementSystem: undefined,
  month_of_birth: undefined,
  signupMethod: undefined,
  signupOauthCode: undefined,
  sports: undefined,
  startDate: undefined,
  startedAt: undefined,

  timeZone: {
    offset: undefined,
    string: undefined,
  },
  trainingGoal: undefined,
  weight: undefined,
  year_of_birth: undefined,
};

export const state = () => {
  return DEFAULT_DATA;
};

export const actions = {
  async getAvailableSports({ commit }) {
    try {
      commit('SET_IS_LOADING_AVAILABLE_SPORTS', true);
      const response = await this.$axios.get('/api/sports');
      commit('SET_AVAILABLE_SPORTS', response?.data?.data || []);
      commit('SET_IS_LOADING_AVAILABLE_SPORTS', false);
    } catch {}
  },
  async signUp(_, data) {
    return await this.$axios.post('/api/users/signup', data);
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    const availableSports = state.availableSports;
    state = {
      ...DEFAULT_DATA,
      availableSports,
    };
  },
  SET_DATA(state, payload) {
    state.availableSports = payload.availableSports ?? DEFAULT_DATA.availableSports;
    state.capabilities = {
      swim: payload.capabilities.swim ?? DEFAULT_DATA.capabilities.swim,
      bike: payload.capabilities.bike ?? DEFAULT_DATA.capabilities.bike,
      run: payload.capabilities.run ?? DEFAULT_DATA.capabilities.run,
    };
    state.email = payload.email ?? DEFAULT_DATA.email;
    state.emailConfirmation = payload.emailConfirmation ?? DEFAULT_DATA.emailConfirmation;
    state.gender = payload.gender ?? DEFAULT_DATA.gender;
    state.heartRate = {
      max: payload.heartRate.max ?? DEFAULT_DATA.heartRate.max,
      resting: payload.heartRate.resting ?? DEFAULT_DATA.heartRate.resting,
    };
    state.isLoadingAvailableSports = false;
    state.measurementSystem = payload.measurementSystem ?? DEFAULT_DATA.measurementSystem;
    state.month_of_birth = payload.month_of_birth ?? DEFAULT_DATA.month_of_birth;
    state.referrer = payload.referrer ?? DEFAULT_DATA.referrer;
    state.signupMethod = payload.signupMethod ?? DEFAULT_DATA.signupMethod;
    state.signupOauthCode = payload.signupOauthCode ?? DEFAULT_DATA.signupOauthCode;
    state.sports = payload.sports ?? DEFAULT_DATA.sports;
    state.startDate = payload.startDate ?? DEFAULT_DATA.startDate;

    if (payload.startedAt && payload.timeZone.offset) {
      const startedAtUTC = parse(payload.startedAt, DateFormat.ISO, new Date());
      state.startedAt = addMinutes(startedAtUTC, payload.timeZone.offset * -1);
      state.timeZone = payload.timeZone;
    } else {
      state.startedAt = DEFAULT_DATA.startedAt;
      state.timeZone = {
        offset: new Date().getTimezoneOffset(),
        string: Intl.DateTimeFormat().resolvedOptions().timeZone || undefined,
      };
    }

    state.trainingGoal = payload.trainingGoal ?? DEFAULT_DATA.trainingGoal;
    state.weight = payload.weight ?? DEFAULT_DATA.weight;
    state.year_of_birth = payload.year_of_birth ?? DEFAULT_DATA.year_of_birth;
  },
  SET_BIRTH_DATE(state, data) {
    state.month_of_birth = data.month;
    state.year_of_birth = data.year;
  },
  SET_AVAILABLE_SPORTS(state, payload) {
    if (isArray(payload) && payload.length) {
      state.availableSports = payload;
    } else {
      state.availableSports = [];
    }
  },
  SET_CAPABILITY(state, { key, value }) {
    state.capabilities[key] = value;
  },
  SET_DETAILS(state, { email, emailConfirmation }) {
    state.email = email;
    state.emailConfirmation = emailConfirmation;
  },
  SET_GENDER(state, value) {
    state.gender = value;
  },
  SET_HEART_RATE(state, { key, value }) {
    state.heartRate[key] = value;
  },
  SET_IS_LOADING_AVAILABLE_SPORTS(state, value) {
    state.isLoadingAvailableSports = value;
  },
  SET_MEASUREMENT_SYSTEM(state, value) {
    state.measurementSystem = value;
  },
  SET_REFERRER(state, value) {
    state.referrer = value;
  },
  SET_SIGNUP_WITH_EMAIL(state) {
    state.signupMethod = RegistrationMethod.Email;
  },
  SET_SIGNUP_WITH_FACEBOOK(state, { accountId, accessToken, signedRequest, firstName, lastName, email }) {
    state.signupMethod = RegistrationMethod.Facebook;
    state.signupOauthCode = accessToken;
    state.email = email || undefined;
    state.emailConfirmation = email || undefined;
    state.externalAccountId = accountId;
    state.externalSignedRequest = signedRequest;
    state.firstName = firstName;
    state.lastName = lastName;
  },
  SET_SIGNUP_WITH_STRAVA(state, code) {
    state.signupMethod = RegistrationMethod.Strava;
    state.signupOauthCode = code;
  },
  SET_SPORTS(state, value) {
    state.sports = value;
  },
  SET_START_DATE(state, value) {
    state.startDate = value;
  },
  SET_STARTED_AT(state, value) {
    state.startedAt = value;
  },
  SET_TIMEZONE(state, value) {
    state.timeZone = value;
  },
  SET_TRAINING_GOAL(state, value) {
    state.trainingGoal = value;
  },
  SET_WEIGHT(state, value) {
    state.weight = value;
  },
};

export const getters = {};
