import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuidv4 } from 'uuid';

import { useDashboard } from './use-dashboard';
import { State } from '~/data/types/store';
import { getDiscipline, getHoursMinutesSecondsFromMinutes, getWorkoutDetails, getWorkoutStatus, getWorkoutType } from '~/data/training/get-training-plan-details';
import { useUser } from '~/data/account/use-user';
import { useTrainingPlan } from '~/data/training/use-training-plan';
import { useTrainingSummary } from '~/data/stats/use-training-summary';
import { useNotifications } from '~/data/notifications/use-notifications';
import { TrainingPlanItem, WorkoutStats } from '~/data/types';
import { COMPLETION_STATUS_MANUAL } from '~/constants';
import { AnalyticsEvent, useAnalytics } from '~/composables/use-analytics';

type UpdateData = {
  notes: string | null,
  completionStatus: number | null,
  stats: WorkoutStats | null
};

export const useTrainingPlanItem = () => {
  const store = useStore<State>();
  const { trackEvent: trackAnalyticsEvent } = useAnalytics();
  const { isMetricSystem, timeZone } = useUser();
  const {
    deleteWorkout: deleteWorkoutFromDashboard,
    getCoachesMessage,
    setCoachesMessageAsLoading,
  } = useDashboard();
  const {
    deleteWorkout: deleteWorkoutFromTrainingPlan,
    updateCalendar,
    updatePrevCalendar,
  } = useTrainingPlan();
  const { deleteWorkout: deleteWorkoutFromNotifications } = useNotifications();
  const { getSummaries, setSummariesAsLoading, setWeekSummaryAsLoading, updateWeekSummary } = useTrainingSummary();
  const isLoading = computed(() => store.state.trainingPlanItem.isLoading);
  const isSavingId = computed(() => store.state.trainingPlanItem.isSavingId);
  const trainingPlanItem = computed(() => store.state.trainingPlanItem.data);
  const isEvent = computed(() => trainingPlanItem.value ? getIsEvent(trainingPlanItem.value) : false);
  const isEventRecoveryWithNoMovement = computed(() => trainingPlanItem.value ? getIsEventRecoveryWithNoMovement(trainingPlanItem.value) : false);

  const getInitialStats = (
    trainingPlanItem: TrainingPlanItem | null,
    completionStatus: number | null = null,
  ): WorkoutStats | null => {
    const stats = trainingPlanItem?.imported_activity?.stats ?? trainingPlanItem?.stats ?? null;

    if (!stats || trainingPlanItem?.isCompleted) {
      return stats;
    }

    const localStats = cloneDeep(stats);
    let estimatedDuration = '00:00:00';

    switch (completionStatus) {
      case COMPLETION_STATUS_MANUAL.AVERAGE:
        estimatedDuration = getHoursMinutesSecondsFromMinutes(trainingPlanItem?.workout.duration_min ?? 0);
        break;
      case COMPLETION_STATUS_MANUAL.TARGET:
        estimatedDuration = getHoursMinutesSecondsFromMinutes(trainingPlanItem?.workout.duration_max ?? 0);
        break;
      default:
        break;
    }

    if (localStats.duration_moving) {
      localStats.duration_moving.value = estimatedDuration;
    } else if (localStats.duration_total) {
      localStats.duration_total.value = estimatedDuration;
    }

    return localStats;
  };

  const status = computed(() => trainingPlanItem.value
    ? getWorkoutStatus(trainingPlanItem.value, timeZone.value)
    : null,
  );

  const workoutType = computed(() => trainingPlanItem.value
    ? getWorkoutType(trainingPlanItem.value)
    : '',
  );

  const workoutDetails = computed(() => {
    if (!trainingPlanItem.value) {
      return null;
    }

    return getWorkoutDetails(trainingPlanItem.value, isMetricSystem.value);
  });

  const workoutDiscipline = computed(() => trainingPlanItem.value
    ? getDiscipline(trainingPlanItem.value)
    : '',
  );

  const clearData = () => {
    store.dispatch('trainingPlanItem/CLEAR_DATA');
  };

  const getIsEvent = (item: TrainingPlanItem): boolean => item.workout.categoryKey === 'event';

  const getIsEventRecoveryWithNoMovement = (item: TrainingPlanItem): boolean => item.workout.categoryKey === 'event_recovery'
    && (item.workout.distance_min || 0) === 0
    && (item.workout.duration_min || 0) === 0;

  const getTrainingPlanItem = async(id: number, ref: string | null) => {
    await store.dispatch('trainingPlanItem/GET_TRAINING_PLAN_ITEM', {
      id,
      ref,
      funnelId: getFunnelId(id),
    });
  };

  const deleteTrainingPlanItem = async(id: number, date: string, ref: string | null): Promise<boolean> => {
    deleteWorkoutFromTrainingPlan(id, date);
    deleteWorkoutFromDashboard(id);
    deleteWorkoutFromNotifications(id);
    updateCalendar();
    updatePrevCalendar();
    setCoachesMessageAsLoading();
    setSummariesAsLoading();
    setWeekSummaryAsLoading(date);

    try {
      await store.dispatch('trainingPlanItem/DELETE_TRAINING_PLAN_ITEM', {
        id,
        funnelId: getFunnelId(id),
        ref,
      });
      await Promise.all([
        getCoachesMessage(true),
        updateWeekSummary(date),
        getSummaries(),
      ]);
    } catch {
      setCoachesMessageAsLoading(false);
      setSummariesAsLoading(false);
      setWeekSummaryAsLoading(date, false);
    }

    return true;
  };

  const updateTrainingPlanItem = async(item: TrainingPlanItem, data: UpdateData, ref: string | null): Promise<boolean> => {
    store.commit('trainingPlanItem/SET_IS_LOADING', true);
    store.commit('trainingPlanItem/SET_IS_SAVING_ID', item.id);

    try {
      const oldStatus = trainingPlanItem.value
        ? `${trainingPlanItem.value.completion_status_manual}-${trainingPlanItem.value.completion_status_automatic}`
        : null;
      const oldDistance = trainingPlanItem.value
        ? trainingPlanItem.value.stats?.distance?.value || trainingPlanItem.value.imported_activity?.stats?.distance?.value
        : null;
      const oldDurationMoving = trainingPlanItem.value
        ? trainingPlanItem.value.stats?.duration_moving?.value || trainingPlanItem.value.imported_activity?.stats?.duration_moving?.value
        : null;

      const payload: { [key: string]: any } = {
        id: item.id,
        data: {
          completed_note: data.notes,
        },
      };

      if (data.completionStatus) {
        payload.data.completion_status_manual = data.completionStatus;
      }

      if (data.stats) {
        payload.data.stats = {};

        Object.keys(data.stats).forEach((key) => { // @ts-ignore key type
          payload.data.stats[key] = data.stats[key] && data.stats[key].length ? data.stats[key] : null;
        });
      }

      payload.data.funnel_id = getFunnelId(item.id);
      payload.data.ref = ref;

      const newTrainingPlan = await store.dispatch('trainingPlanItem/UPDATE_TRAINING_PLAN_ITEM', payload);

      store.commit('trainingPlan/SET_TRAINING_PLAN_ITEM', newTrainingPlan);
      store.commit('dashboard/SET_TRAINING_PLAN_ITEM', newTrainingPlan);

      const newStatus = newTrainingPlan
        ? `${newTrainingPlan.completion_status_manual}-${newTrainingPlan.completion_status_automatic}`
        : null;

      let shouldRefreshSummaryData = oldStatus !== newStatus;

      if (!shouldRefreshSummaryData) {
        if (data.stats?.distance !== undefined) {
          const newDistance = newTrainingPlan
            ? newTrainingPlan.stats?.distance?.value || newTrainingPlan.imported_activity?.stats?.distance?.value
            : null;

          shouldRefreshSummaryData = oldDistance !== newDistance;
        }
      }

      if (!shouldRefreshSummaryData) {
        if (data.stats?.duration_moving !== undefined) {
          const newDurationMoving = newTrainingPlan
            ? newTrainingPlan.stats?.duration_moving?.value || newTrainingPlan.imported_activity?.stats?.duration_moving?.value
            : null;

          shouldRefreshSummaryData = oldDurationMoving !== newDurationMoving;
        }
      }

      if (shouldRefreshSummaryData) {
        store.commit('trainingPlan/CLEAR_CALENDAR');
        updateWeekSummary(item.planned_date);
        getSummaries();
      }

      store.commit('trainingPlanItem/SET_IS_SAVING_ID', null);
      return true;
    } catch {
      store.commit('trainingPlanItem/SET_IS_SAVING_ID', null);
      return false;
    }
  };

  const startFunnel = (id: number) => {
    store.commit('analytics/SET_TRAINING_PLAN_ITEM_FUNNEL', { id, value: uuidv4() });
  };

  const getFunnelId = (id: number): string | null => store.state.analytics.trainingPlanItemFunnelIds[id] ?? null;

  const trackEvent = (event: AnalyticsEvent, ref: string | null, data: { [key: string]: any } = {}) => {
    if (!trainingPlanItem.value) {
      return;
    }

    data.funnel_id = getFunnelId(trainingPlanItem.value.id);

    if (ref?.length) {
      data.ref = ref;
    }

    trackAnalyticsEvent(event, data, `/api/training-plans/${trainingPlanItem.value.id}/track`);
  };

  return {
    clearData,
    deleteTrainingPlanItem,
    getFunnelId,
    getInitialStats,
    getIsEvent,
    getIsEventRecoveryWithNoMovement,
    getTrainingPlanItem,
    isEvent,
    isEventRecoveryWithNoMovement,
    isLoading,
    isSavingId,
    startFunnel,
    status,
    trackEvent,
    trainingPlanItem,
    updateTrainingPlanItem,
    workoutDetails,
    workoutDiscipline,
    workoutType,
  };
};
