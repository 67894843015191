import Vue from 'vue';
import isArray from 'lodash/isArray';
import cloneDeep from 'lodash/cloneDeep';
import { differenceInCalendarDays, isAfter, isBefore, isSameDay } from 'date-fns';

import { TrainingPlanType } from '~/data/types';

const getDefaultState = () => ({
  calendar: [],
  focus: [],
  hasLoadedEverythingInTheFuture: false,
  hasLoadedEverythingInThePast: false,
  importedActivities: {},
  isLoadingFocus: true,
  isLoadingPreview: true,
  isLoadingTrainingPlan: true,
  lastFocusIndex: -1,
  prevCalendar: [],
  preview: {},
  trainingPlan: {},
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_IMPORTED_ACTIVITIES({ commit }, { fromDate, toDate }) {
    try {
      const response = await this.$axios.$get('/api/imported-activities', {
        params: {
          from_date: fromDate,
          to_date: toDate,
        },
      });
      commit('SET_IMPORTED_ACTIVITIES', response.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async GET_FOCUS({ commit }, { fromDate, toDate }) {
    commit('SET_IS_LOADING_FOCUS', true);

    try {
      const response = await this.$axios.$get('/api/training-plans/focus', {
        params: {
          from_date: fromDate,
          to_date: toDate,
        },
      });
      commit('SET_FOCUS', { data: response.data });
    } catch (e) {
      commit('SET_IS_LOADING_FOCUS', false);
      return Promise.reject(e);
    }
    commit('SET_IS_LOADING_FOCUS', false);
  },
  async GET_PREVIEW({ commit }, { fromDate, toDate }) {
    commit('SET_IS_LOADING_PREVIEW', true);

    try {
      const response = await this.$axios.$get('/api/training-plans/preview', {
        params: {
          from_date: fromDate,
          to_date: toDate,
        },
      });
      commit('SET_PREVIEW', response.data);
    } catch (e) {
      commit('SET_IS_LOADING_PREVIEW', false);
      return Promise.reject(e);
    }
    commit('SET_IS_LOADING_PREVIEW', false);
  },
  async GET_TRAINING_PLAN({ commit }, { fromDate, toDate }) {
    commit('SET_IS_LOADING_TRAINING_PLAN', true);

    try {
      const response = await this.$axios.$get('/api/training-plans', {
        params: {
          type: 'date',
          from_date: fromDate,
          to_date: toDate,
        },
      });
      commit('SET_TRAINING_PLAN', response.data);
    } catch (e) {
      commit('SET_IS_LOADING_TRAINING_PLAN', false);
      return Promise.reject(e);
    }
    commit('SET_IS_LOADING_TRAINING_PLAN', false);
  },
  UPDATE_CALENDAR({ commit }, value) {
    commit('SET_CALENDAR', value);
  },
  async UPDATE_WORKOUT_DATE({ commit }, { id, fromDate, toDate }) {
    try {
      const response = await this.$axios.$put(`/api/training-plans/${id}`, {
        planned_date: toDate,
      });
      commit('SET_WORKOUT_DATE', { trainingPlan: response.data, fromDate, toDate });
      return true;
    } catch {
      return false;
    }
  },
  UPDATE_LAST_FOCUS_INDEX({ commit }, value) {
    commit('SET_LAST_FOCUS_INDEX', value);
  },
  UPDATE_HAS_LOADED_EVERYTHING_IN_THE_FUTURE({ commit }, value) {
    commit('SET_HAS_LOADED_EVERYTHING_IN_THE_FUTURE', value);
  },
  UPDATE_HAS_LOADED_EVERYTHING_IN_THE_PAST({ commit }, value) {
    commit('SET_HAS_LOADED_EVERYTHING_IN_THE_PAST', value);
  },
  UPDATE_PREV_CALENDAR({ commit }, value) {
    commit('SET_PREV_CALENDAR', value);
  },
};

export const mutations = {
  CLEAR_CALENDAR(state) {
    state.calendar = getDefaultState().calendar;
  },
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  DELETE_IMPORTED_ACTIVITY(state, { date, id }) {
    const items = cloneDeep(state.importedActivities);

    if (items[date]) {
      // Remove the workout
      items[date] = items[date].filter(item => item.id !== id);

      // If the date is now empty, consider it a rest day
      if (!items[date].length) {
        delete items[date];
      }
    }

    state.importedActivities = items;
  },
  DELETE_TRAINING_PLAN_ITEM(state, { date, id }) {
    const items = cloneDeep(state.trainingPlan);

    if (items[date]) {
      // Remove the workout
      items[date] = items[date].filter(item => item.id !== id);

      // If the date is now empty, consider it a rest day
      if (!items[date].length) {
        delete items[date];
      }
    }

    state.trainingPlan = items;
  },
  SET_CALENDAR(state, data) {
    state.calendar = data;
  },
  SET_IS_LOADING_FOCUS(state, data) {
    state.isLoadingFocus = data;
  },
  SET_IS_LOADING_PREVIEW(state, data) {
    state.isLoadingPreview = data;
  },
  SET_IS_LOADING_TRAINING_PLAN(state, data) {
    state.isLoadingTrainingPlan = data;
  },
  SET_FOCUS(state, { data }) {
    if (isArray(data) && data.length) {
      state.focus = data.map((item) => {
        const today = new Date();
        const startDate = new Date(item.start_date);
        const endDate = new Date(item.end_date);
        const isCurrent = isSameDay(today, startDate) || (isAfter(today, startDate) && isBefore(today, endDate));
        const calendarDays = differenceInCalendarDays(endDate, startDate);

        let title = 'Baseline';
        switch (item.plan_type) {
          case TrainingPlanType.Offseason:
            title = 'Offseason';
            break;
          case TrainingPlanType.Event:
            title = item.events.map(event => event.name || event.title).join(' + ');
            break;
          default:
            break;
        }

        return {
          calendarDays,
          description: item.description,
          endDate,
          events: item.events.map(event => ({
            id: event.id,
            name: event.name,
            event: event.event,
            eventTitle: event.event_title,
            eventDate: new Date(event.event_date),
            eventTaperStartDate: new Date(event.event_taper_start_date),
            eventTaperEndDate: new Date(event.event_taper_end_date),
            eventRecoveryStartDate: new Date(event.event_recovery_start_date),
            eventRecoveryEndDate: new Date(event.event_recovery_end_date),
          })),
          isCurrent,
          planType: item.plan_type,
          startDate,
          title,
        };
      });
    } else {
      state.focus = [];
    }
  },
  SET_HAS_LOADED_EVERYTHING_IN_THE_FUTURE(state, data) {
    state.hasLoadedEverythingInTheFuture = data;
  },
  SET_HAS_LOADED_EVERYTHING_IN_THE_PAST(state, data) {
    state.hasLoadedEverythingInThePast = data;
  },
  SET_IMPORTED_ACTIVITIES(state, data) {
    const result = state.importedActivities;

    Object.keys(data).forEach((key) => {
      result[key] = data[key];
    }); // This is faster than using the spread operator

    state.importedActivities = result;
  },
  SET_LAST_FOCUS_INDEX(state, data) {
    state.lastFocusIndex = data;
  },
  SET_PREV_CALENDAR(state, data) {
    state.prevCalendar = data;
  },
  SET_PREVIEW(state, data) {
    const result = state.preview;

    Object.keys(data).forEach((key) => {
      result[key] = data[key];
    }); // This is faster than using the spread operator

    state.preview = result;
  },
  SET_TRAINING_PLAN(state, data) {
    const result = state.trainingPlan;

    Object.keys(data).forEach((key) => {
      result[key] = data[key];
    }); // This is faster than using the spread operator

    state.trainingPlan = result;
  },
  SET_WORKOUT_DATE(state, { trainingPlan, fromDate, toDate }) {
    const items = cloneDeep(state.trainingPlan);

    // If the new date was a rest date, it won't exist in the store
    if (!items[toDate]) {
      items[toDate] = [trainingPlan];
    } else {
      items[toDate].push(trainingPlan);
    }

    // Remove the workout from the old date
    items[fromDate] = items[fromDate].filter(item => item.id !== trainingPlan.id);

    // If the from date is now empty, consider it a rest day
    if (!items[fromDate].length) {
      delete items[fromDate];
    }

    state.trainingPlan = items;
  },
  SET_TRAINING_PLAN_ITEM(state, trainingPlan) {
    if (state.trainingPlan[trainingPlan.planned_date]) {
      Vue.set(
        state.trainingPlan,
        trainingPlan.planned_date,
        state.trainingPlan[trainingPlan.planned_date].map(item => item.id === trainingPlan.id ? trainingPlan : item),
      );
    }
  },
};
