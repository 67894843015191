import Vue from 'vue';
export const EventBus = new Vue();

export enum Key {
  ChangeFeedTab = 'changeFeedTab',
  DeleteRace = 'deleteRace',
  HideOverlay = 'hideOverlay',
  ShowChangeMembership = 'showChangeMembership',
  ShowDowngradePlan = 'showDowngradePlan',
  ShowDowngradeReason = 'showDowngradeReason',
  ShowErrorToast = 'showErrorToast',
  ShowErrorToastOffline = 'showErrorToastOffline',
  ShowImportedActivity = 'showImportedActivity',
  ShowOverlay = 'showOverlay',
  ShowShareImage = 'showShareImage',
  ShowShareRaceCompletedImage = 'showShareRaceCompletedImage',
  ShowShareTrainingSummary = 'showShareTrainingSummary',
  ShowShareWorkoutImage = 'showShareWorkoutImage',
  ShowSuccessToast = 'showSuccessToast',
  ShowTrainingPlanItem = 'showTrainingPlanItem',
  ShowTrainingSummaryAutoSharing = 'showTrainingSummaryAutoSharing',
};
