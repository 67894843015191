import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { State } from '~/data/types/store';
import { KeywordItem } from '~/data/types';

export const useKeywords = () => {
  const store = useStore<State>();
  const keywords = computed(() => store.state.keywords.data);

  const getKeyword = async(keyword: string): Promise<KeywordItem | null> => {
    if (keywords.value[keyword]) {
      return keywords.value[keyword];
    }
    await store.dispatch('keywords/GET_KEYWORD', keyword);
    return keywords.value[keyword] || null;
  };

  return {
    getKeyword,
  };
};
