export const state = () => ({
  isSmallDevice: null,
  isMediumDevice: null,
  isLargeDevice: null,
  hasTouchPoints: null,
});

export const actions = { };

export const mutations = {
  SET_IS_SMALL(state) {
    state.isSmallDevice = true;
    state.isMediumDevice = false;
    state.isLargeDevice = false;
  },
  SET_IS_MEDIUM(state) {
    state.isSmallDevice = false;
    state.isMediumDevice = true;
    state.isLargeDevice = false;
  },
  SET_IS_LARGE(state) {
    state.isSmallDevice = false;
    state.isMediumDevice = false;
    state.isLargeDevice = true;
  },
  SET_HAS_TOUCH_POINTS(state, value) {
    state.hasTouchPoints = value;
  },
};

export const getters = {
  isLargeDevice(state) {
    return state.isLargeDevice;
  },
  isSmallDevice(state) {
    return state.isSmallDevice;
  },
  isMediumDevice(state) {
    return state.isMediumDevice;
  },
  hasTouchPoints(state) {
    return !!state.hasTouchPoints;
  },
};
