var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-card bg-white rounded position-relative",class:{
    'content-card--xs': _vm.xs,
    'content-card--sm': _vm.sm,
    'content-card--has-title': _vm.title.length,
    'content-card--mobile': _vm.mobile,
    'content-card--collapsable': _vm.collapsable,
    'content-card--collapsed': _vm.collapsable && _vm.isCollapsed,
    'content-card--open': _vm.collapsable && !_vm.isCollapsed,
    'content-card--column': _vm.columnLayout,
  }},[(_vm.title.length)?_c('h3',{class:( _obj = { 'placeholder-container': _vm.loading }, _obj[_vm.classTitle] = _vm.classTitle, _obj ),on:{"click":_vm.toggle}},[_c('span',{class:{ 'bg-light text-light': _vm.loading }},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])]):_vm._e(),_vm._v(" "),(!_vm.collapsable || (_vm.collapsable && !_vm.isCollapsed))?_c('div',{staticClass:"content-card__content"},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }