<template>
  <nuxt />
</template>

<script>
export default {
  name: 'Default',
  middleware({ store, redirect, route }) {
    if (!store.state.auth.loggedIn && !route.path.startsWith('/install')) {
      redirect('https://mymottiv.com');
    }
  },
};
</script>
