// Currently using common store, so need to use keys from api

export enum PostReactionType {
  Social = 2,
  Comment = 3,
  User = 4,
};

export type PostAuthor = {
  id: number,
  first_name: string,
  last_name: string,
  avatar: string | null,
  city: string,
  country_code: string,
  next_event_name: string | null,
};

export type PostImage = {
  id: number,
  url: string,
  urls: { [key: string]: string },
};

export type PostOwnReaction = {
  id: number,
  type: PostReactionType,
  model_id: number,
  author: {
    id: number,
    first_name: string,
    last_name: string,
    avatar: string | null,
  },
  totalCount: number,
};

export type PostComment = {
  id: number,
  post_id: number,
  parent_id: number,
  text: string,
  origin_text: string,
  html_formatted_text: string,
  gif_url: string | null,
  published_at: string,
  origin_id: number | null,
  author: PostAuthor,
  image: PostImage | null,
  ownReaction: PostOwnReaction | null,
  reactionsCount: number,
  latestReplies: PostComment[],
  hasMoreReplies: boolean,
};

export type PostWorkout = {
  id: number,
  categoryKey: string,
  categoryTitle: string,
  duration_min: number | null,
  duration_max: number | null,
  distance_min: number | null,
  distance_max: number | null,
};

export type Post = {
  id: number,
  text: string | null,
  origin_text: string | null,
  html_formatted_text: string,
  gif_url: string | null,
  published_at: string,
  created_at: string | null,
  origin_id: number | null,
  author: PostAuthor,
  images: PostImage[],
  ownReaction: PostOwnReaction | null,
  reactionsCount: number,
  commentsCount: number,
  latestComments: Comment[],
  hasMoreComments: boolean,
  trainingPlan: PostWorkout | null,
  community_group_id: number,
  shared_to_ids: undefined | number[],
};
