export const state = () => ({
  list: [],
});

export const actions = {
  async GET_PROMPTS({ commit }) {
    try {
      const { data } = await this.$axios.$get('/api/review-prompts?platform=web', { hideToastr: true });
      await commit('SET_PROMPTS', data);
    } catch (e) {}
  },

  async DISMISS_PROMPT({ commit }, id) {
    try {
      const { data } = await this.$axios.$delete(`/api/review-prompts/${id}`, { hideToastr: true });
      await commit('SET_PROMPTS', data);
    } catch (e) {}
  },

  async MARK_AS_REVIEWED({ commit }, id) {
    try {
      const { data } = await this.$axios.$get(`/api/review-prompts/${id}/reviewed`, { hideToastr: true });
      await commit('SET_PROMPTS', data);
    } catch (e) {}
  },
};

export const mutations = {
  SET_PROMPTS(state, data) {
    state.list = data;
  },
};
