class Logger {
  constructor() {
    this.logger = console;
  }

  log(...args) {
    this.logger.log(...args);
  }

  warn(...args) {
    this.logger.warn(...args);
  }

  info(...args) {
    this.logger.info(...args);
  }

  error(module, ...args) {
    this.logger.error(`Error in: ${module} \n`, ...args);
  }
}

export const createLogger = () => new Logger();
