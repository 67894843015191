import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { setCache, getCache } from '../../utils/cacheUtils';
import { Connection, ConnectionType } from '~/data/types';
import { State } from '~/data/types/store';
import { useUserSubscription } from '~/data/subscription';
import { CONNECTION_SCOPE_SETTING } from '~/constants';
import { EventBus, Key } from '~/helpers/eventBus';
import { useFeatureFlag } from '~/data/account/use-feature-flag';

export const useConnections = () => {
  const { canAddFitnessOnlyConnection } = useUserSubscription();
  const { hasAccessToStravaGraphics }
    = useFeatureFlag();

  const isLoading = computed(() => store.state.connections.isLoading);
  const store = useStore<State>();
  const connections = computed<Connection[]>(() => store.state.connections?.connections || []);

  const hasConnectionWithStats = computed(() => {
    const isConnectedToGarmin = getConnection(ConnectionType.Garmin);
    const isConnectedToStrava = getConnection(ConnectionType.Strava);
    const isConnectedToWahoo = getConnection(ConnectionType.Wahoo);
    return isConnectedToGarmin || isConnectedToStrava || isConnectedToWahoo;
  });

  const canAddConnection = (type: ConnectionType) => {
    if (canAddFitnessOnlyConnection.value) {
      return true;
    }

    const fitnessOnlyConnections = [ConnectionType.TrainingPeaks, ConnectionType.Garmin, ConnectionType.Wahoo];
    return !fitnessOnlyConnections.includes(type);
  };

  const setConnectionIntent = async(type: ConnectionType, id?: number) => {
    localStorage.setItem('connectionType', type.toString());
    if (id) {
      localStorage.setItem('connectionId', id.toString());
    } else {
      localStorage?.removeItem('connectionId');
    }

    if (type === ConnectionType.Garmin) {
      const tokens = await store.dispatch('connections/GET_GARMIN_TOKENS');
      if (tokens.oauth_token) {
        const redirectUrl = `${window?.location?.origin + window?.location?.pathname}`;
        localStorage.setItem('garmin_tokens', JSON.stringify(tokens));
        window.location.href = `https://connect.garmin.com/oauthConfirm?oauth_token=${tokens.oauth_token}&oauth_callback=${redirectUrl}`;
      }
    }
  };

  const getConnection = (type: ConnectionType): Connection | null => {
    const items = connections.value.filter(connection => connection.type === type);
    return items.length ? items[0] : null;
  };

  const getGarminTokens = (query: { [key: string]: any }) => {
    const oauthToken = query.oauth_token;
    const oauthVerifier = query.oauth_verifier;
    if (oauthToken && oauthVerifier) {
      const garminRequestTokens = JSON.parse(localStorage?.getItem('garmin_tokens') || '') || '';
      localStorage?.removeItem('garmin_tokens');
      return {
        request_token: oauthToken,
        request_token_secret: garminRequestTokens.oauth_token_secret,
        verifier: oauthVerifier,
      };
    }
  };

  const connect = async(type: ConnectionType, query: { [key: string]: any }, redirectUrl: string): Promise<boolean> => {
    const storedType = Number(localStorage?.getItem('connectionType') || null);
    const storedId = Number(localStorage?.getItem('connectionId') || null);
    if (!storedType || storedType !== type) {
      return false;
    }

    const isGarmin = type === ConnectionType.Garmin;
    const isStrava = type === ConnectionType.Strava;
    const code = query.code;
    const garminTokens = isGarmin ? getGarminTokens(query) : null;
    if (!code && !garminTokens) {
      return false;
    }

    const params = {
      platform_type: type,
      redirect_uri: redirectUrl,
      authorization_code: code,
    };
    if (isGarmin) {
      Object.assign(params, garminTokens);
      delete params.authorization_code;
    }

    localStorage?.removeItem('connectionType');
    localStorage?.removeItem('connectionId');

    if (isStrava) {
      const scope = typeof query.scope === 'string' ? query.scope : '';
      if (!stravaHasRequiredScopes(scope)) {
        EventBus.$emit(
          Key.ShowErrorToast,
          "Please authorize all 'view' permissions to register with Strava",
        );
        return false;
      }
      Object.assign(params, { scope });
    }

    try {
      if (storedId) {
        await store.dispatch('connections/UPDATE_CONNECTION', { id: storedId, params });
      } else {
        await store.dispatch('connections/SUBMIT_CONNECTION', params);
      }
      return true;
    } catch {
      // Do nothing
    }

    return false;
  };

  const connectWithApple = async(code: string, redirectUri: string) => {
    const params = {
      platform: 'web',
      platform_type: ConnectionType.Apple,
      redirect_uri: redirectUri,
      authorization_code: code,
    };

    try {
      await store.dispatch('connections/SUBMIT_CONNECTION', params);
      return true;
    } catch {
      // Do nothing
    }
  };

  const connectWithFacebook = async(accountId: number, accessToken: string, signedRequest: string) => {
    const params = {
      platform: 'web',
      platform_type: ConnectionType.Facebook,
      access_token: accessToken,
      account_id: accountId,
      signed_request: signedRequest,
    };

    try {
      await store.dispatch('connections/SUBMIT_CONNECTION', params);
      return true;
    } catch {
      // Do nothing
    }
  };

  const disconnect = async(type: ConnectionType): Promise<boolean> => {
    const id = connections.value.find(connection => connection.type === type)?.id;
    if (!id) {
      return true;
    }

    try {
      await store.dispatch('connections/DELETE_CONNECTION', id);
    } catch {
      // Do nothing
    }

    return true;
  };

  const getRedirectUrl = (type: ConnectionType, redirectUrl: string): string | null => {
    switch (type) {
      case ConnectionType.Strava:
        return `https://www.strava.com/oauth/authorize?client_id=${process.env.STRAVA_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}&approval_prompt=force&scope=${getScopesString(type, !hasAccessToStravaGraphics.value)}`;
      case ConnectionType.TrainingPeaks:
        return `${process.env.TRAINING_PEAKS_AUTHORIZATION_URL}/OAuth/Authorize?response_type=code&client_id=${process.env.TRAINING_PEAKS_CLIENT_ID}&redirect_uri=${redirectUrl}&scope=${getScopesString(type)}`;
      case ConnectionType.Wahoo:
        return `https://api.wahooligan.com/oauth/authorize?client_id=${process.env.WAHOO_CLIENT_ID}&response_type=code&redirect_uri=${redirectUrl}&scope=${getScopesString(type)}`;
      default:
        return null;
    }
  };

  const getScopesString = (type: ConnectionType, requiredOnly: Boolean = false): string => {
    // @ts-ignore
    const scopeSetting = CONNECTION_SCOPE_SETTING[type];

    if (scopeSetting) {
      const scopes = requiredOnly
        ? scopeSetting.required
        : [...scopeSetting.required, ...scopeSetting.additional];

      return encodeURI(scopes.join(scopeSetting.separator));
    }
    return '';
  };

  const stravaHasRequiredScopes = (queryScopesString: string): Boolean => {
    const scopeSetting = CONNECTION_SCOPE_SETTING[ConnectionType.Strava];
    const queryScopes = queryScopesString.split(scopeSetting.separator);
    return scopeSetting.required.every(scope => queryScopes.includes(scope));
  };

  const getConnections = async() => {
    if (!connections.value.length) {
      await store.dispatch('connections/GET_CONNECTION');
    }
  };

  const hideStravaReAuthBanner = () => {
    setCache('hideStravaReAuthBanner', 'hidden');
  };

  const stravaReAuthBannerHidden = computed(() => {
    return !!getCache('hideStravaReAuthBanner');
  });

  return {
    canAddConnection,
    canAddFitnessOnlyConnection,
    connect,
    connections,
    connectWithApple,
    connectWithFacebook,
    disconnect,
    getConnection,
    getConnections,
    getRedirectUrl,
    hasConnectionWithStats,
    isLoading,
    setConnectionIntent,
    stravaHasRequiredScopes,
    hideStravaReAuthBanner,
    stravaReAuthBannerHidden,
  };
};
