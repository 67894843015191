import loadScript from '~/helpers/loadScript';

export const installPixel = (pixelId) => {
  // <!-- Meta Pixel Code -->
  // eslint-disable-next-line
  !function(f,b,e,v,n,t,s) // eslint-disable-next-line
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod? // eslint-disable-next-line
  n.callMethod.apply(n,arguments):n.queue.push(arguments)}; // eslint-disable-next-line
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; // eslint-disable-next-line
  n.queue=[];t=b.createElement(e);t.async=!0; // eslint-disable-next-line
  t.src=v;s=b.getElementsByTagName(e)[0]; // eslint-disable-next-line
  s.parentNode.insertBefore(t,s)}(window, document,'script', // eslint-disable-next-line
  'https://connect.facebook.net/en_US/fbevents.js'); // eslint-disable-next-line
  fbq('init', pixelId);
  // <!-- End Meta Pixel Code -->
};

export const installSdk = (appId) => {
  window.fbAsyncInit = function() {
    FB.init({
      appId,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v15.0',
    });
  };
  loadScript.load('https://connect.facebook.net/en_US/sdk.js', {
    async: true,
    defer: true,
    crossorigin: 'anonymous',
  });
};
