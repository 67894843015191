import Vue from 'vue';

export const state = () => ({
  data: {},
});

export const actions = {
  async GET_KEYWORD({ commit }, action) {
    try {
      const { data } = await this.$axios.$get(`/api/keywords/${action}`);
      await commit('PUSH_KEYWORD', data);
    } catch (e) {}
  },
};

export const mutations = {
  PUSH_KEYWORD(state, data) {
    Vue.set(state.data, data.key, data);
  },
};
