import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { endOfISOWeek, format, isSameDay, parse, startOfISOWeek } from 'date-fns';
import { TrainingSummary } from 'mottiv-web-components';

import { useUser } from '~/data/account/use-user';
import { DateFormat } from '~/helpers/date';
import { State } from '~/data/types/store';
import { CalendarDay } from '~/data/types';
import { SUPPORTED_TRAINING_SUMMARIES } from '~/constants';

export const useTrainingSummary = () => {
  const store = useStore<State>();
  const { currentDate } = useUser();
  const isLoadingSummaries = computed(() => store.state.trainingSummary.isLoadingSummaries.length);

  const isLoadingSummary = (fromDate: string, toDate: string): boolean => {
    const key = `${fromDate}-${toDate}`;
    return isLoadingSummaryForKey(key);
  };

  const isLoadingSummaryForCurrentWeek = (): boolean => {
    const fromDate = format(startOfISOWeek(currentDate.value), DateFormat.Date);
    const toDate = format(currentDate.value, DateFormat.Date);
    const key = `${fromDate}-${toDate}`;
    return isLoadingSummaryForKey(key);
  };

  const isLoadingSummaryForKey = (key: string): boolean => {
    return store.state.trainingSummary.isLoadingSummaries.includes(key);
  };

  const getSummaryByKey = (key: string): null | object => {
    return store.state.trainingSummary.summaries[key] ?? null;
  };

  const getLastWeekSummaryForUser = async(userId: number | null): Promise<boolean> => {
    const params = userId ? { 'filter[userId]': userId } : {};
    try {
      store.dispatch('trainingSummary/CLEAR_DATA');
      await store.dispatch('trainingSummary/GET_TRAINING_SUMMARIES', params);
      return true;
    } catch {
      return false;
    }
  };

  const getSummaries = async(): Promise<boolean> => {
    try {
      await store.dispatch('trainingSummary/GET_TRAINING_SUMMARIES');
      return true;
    } catch {
      return false;
    }
  };

  const getSummary = async(fromDate: string, toDate: string, forceUpdate: boolean = false): Promise<TrainingSummary | null> => {
    const key = `${fromDate}-${toDate}`;

    if (!forceUpdate && isLoadingSummaryForKey(key)) {
      return null;
    }

    if (!forceUpdate && store.state.trainingSummary.summaries[key]) {
      return store.state.trainingSummary.summaries[key];
    }

    try {
      await store.dispatch('trainingSummary/GET_TRAINING_SUMMARY', {
        fromDate,
        toDate,
        forceUpdate,
      });
      return store.state.trainingSummary.summaries[key] ?? null;
    } catch {
      return null;
    }
  };

  const getSummaryForCurrentWeek = async(): Promise<TrainingSummary | null> => {
    const fromDate = format(startOfISOWeek(currentDate.value), DateFormat.Date);
    const toDate = format(currentDate.value, DateFormat.Date);
    const key = `${fromDate}-${toDate}`;

    if (isLoadingSummaryForKey(key)) {
      return null;
    }

    if (store.state.trainingSummary.summaries[key]) {
      return store.state.trainingSummary.summaries[key];
    }

    try {
      await store.dispatch('trainingSummary/GET_TRAINING_SUMMARY', {
        fromDate,
        toDate,
      });
      return store.state.trainingSummary.summaries[key] ?? null;
    } catch {
      return null;
    }
  };

  const getSummaryActivityTypesFromCalendar = (days: CalendarDay[]): string[] => {
    let hasSwim = false;
    let hasBike = false;
    let hasRun = false;
    let hasOther = false;

    days.forEach((day) => {
      day.activities.forEach((activity) => {
        const activityType = (activity.type ?? '').toLowerCase();
        if (activityType.includes('swim')) {
          hasSwim = true;
        } else if (activityType.includes('bike')) {
          hasBike = true;
        } else if (activityType.includes('run')) {
          hasRun = true;
        } else {
          hasOther = true;
        }
      });
    });

    const activities: string[] = [];

    if (hasSwim) {
      activities.push('swim');
    }
    if (hasBike) {
      activities.push('bike');
    }
    if (hasRun) {
      activities.push('run');
    }
    if (hasOther) {
      activities.push('other');
    }

    return activities;
  };

  const setSummariesAsLoading = (status: boolean = true) => {
    const storeFn = status ? 'SET_LOADING_SUMMARY_START' : 'SET_LOADING_SUMMARY_END';
    SUPPORTED_TRAINING_SUMMARIES.forEach(type => store.commit(`trainingSummary/${storeFn}`, { key: type }));
  };

  const setWeekSummaryAsLoading = (date: string, status: boolean = true) => {
    const { start, end } = getDatesOfWeekSummary(date);
    const storeFn = status ? 'SET_LOADING_SUMMARY_START' : 'SET_LOADING_SUMMARY_END';
    const key = `${start}-${end}`;
    store.commit(`trainingSummary/${storeFn}`, { key });
  };

  const updateWeekSummary = (date: string) => {
    const { start, end } = getDatesOfWeekSummary(date);
    return getSummary(start, end, true);
  };

  const getDatesOfWeekSummary = (date: string): { start: string, end: string } => {
    const startOfWeek = startOfISOWeek(parse(date, DateFormat.Date, currentDate.value));
    let endOfWeek: Date;

    // If this is the current week, then make the end of week today
    if (isSameDay(startOfISOWeek(currentDate.value), startOfWeek)) {
      endOfWeek = currentDate.value;
    } else {
      endOfWeek = endOfISOWeek(startOfWeek);
    }

    return {
      start: format(startOfWeek, DateFormat.Date),
      end: format(endOfWeek, DateFormat.Date),
    };
  };

  return {
    getSummaries,
    getSummary,
    getSummaryActivityTypesFromCalendar,
    getSummaryForCurrentWeek,
    isLoadingSummaries,
    isLoadingSummary,
    isLoadingSummaryForCurrentWeek,
    isLoadingSummaryForKey,
    setSummariesAsLoading,
    setWeekSummaryAsLoading,
    updateWeekSummary,
    getSummaryByKey,
    getLastWeekSummaryForUser,
  };
};
