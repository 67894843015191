export enum OnboardingBumperType {
  AddConnection = 'add_connection',
  AddProfileImage = 'add_profile_image',
  AddRace = 'add_race',
  AddTrainingZoneInput = 'add_training_zone_input',
  SetReferrer = 'set_referrer',
  UpdateWorkoutWeeklyPreferences = 'update_workout_weekly_preferences',
};

export type OnboardingBumper = {
  id: number,
  key: string,
  title: string,
  is_completed: boolean,
};
