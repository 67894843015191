/* global window */
import { addHours } from 'date-fns';

const cachePrefix = 'cache.';

const setCache = (key, data, ignorePrefix = false) => {
  if (!window || !window.localStorage || !key || !data) {
    return false;
  }
  window.localStorage.setItem(
    `${ignorePrefix ? '' : cachePrefix}${key}`,
    JSON.stringify({
      data,
      createdAt: new Date().toISOString(),
    }),
  );
};

const getCache = (key, ignorePrefix = false) => {
  if (!window || !window.localStorage || !key) {
    return false;
  }
  const data = JSON.parse(
    window.localStorage.getItem(`${ignorePrefix ? '' : cachePrefix}${key}`)
      || '{}',
  );
  if (!data) {
    return false;
  }

  if (!Object.keys(data).length) {
    return false;
  }

  const expiryDate = addHours(new Date(data.createdAt), 6);
  const expiryTime = expiryDate.getTime();
  const currentTime = new Date().getTime();
  if (currentTime > expiryTime) {
    removeCache(key);
    return false;
  }

  return data.data;
};

const removeCache = (key) => {
  if (!window || !window.localStorage || !key) {
    return false;
  }
  window.localStorage.removeItem(
    key.startsWith(cachePrefix) ? key : cachePrefix + key,
  );
  return true;
};

const removeAllCachedData = () => {
  if (!window || !window.localStorage) {
    return false;
  }
  Object.keys(window.localStorage).forEach((key) => {
    if (key.startsWith(cachePrefix)) {
      localStorage.removeItem(key);
    }
  });
};

const getAllCacheKeys = (key = '', ignorePrefix = false) => {
  if (!window || !window.localStorage) {
    return [];
  }
  return Object.keys(window.localStorage).filter((k) => {
    return k.startsWith(`${ignorePrefix ? '' : cachePrefix}${key}`);
  });
};

export {
  setCache,
  getCache,
  removeCache,
  removeAllCachedData,
  getAllCacheKeys,
  cachePrefix,
};
