import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { differenceInMonths, differenceInWeeks, differenceInDays, format, parse, startOfDay } from 'date-fns';

import { State } from '~/data/types/store';
import { Race } from '~/data/types/races';
import { useUser } from '~/data/account/use-user';
import { DateFormat, getUTCDate, isDateBetweenDates } from '~/helpers/date';
import { AnalyticsEvent, AnalyticsFunnel, useAnalytics } from '~/composables/use-analytics';

export const useRaces = () => {
  const store = useStore<State>();
  const { trackEvent, getFunnelId } = useAnalytics();
  const { currentDate, currentDateUTC, timeZone } = useUser();
  const isLoading = computed(() => store.state.races.isListLoading);
  const upcoming = computed(() => store.state.races.upcomingRacesList || []);
  const completed = computed(() => store.state.races.completedRacesList || []);
  const nextRace = computed(() => upcoming.value.length ? upcoming.value[0] : null);
  const failedLoad = computed(() => store.state.races.upcomingRacesList === null && store.state.races.completedRacesList === null && !isLoading.value);

  const addRace = async(name: string, date: Date, eventId: number, placeId: string): Promise<boolean> => {
    try {
      const result = await store.dispatch('races/CREATE_NEW_RACE', {
        name,
        event_date: format(date, DateFormat.Date),
        workout_event_id: eventId,
        place_id: placeId,
        funnel_id: getFunnelId(AnalyticsFunnel.AddRace),
      });
      await getUpcoming();
      await store.dispatch('trainingPlan/CLEAR_DATA');
      await store.dispatch('trainingSummary/CLEAR_DATA');
      await store.dispatch('dashboard/CLEAR_DATA');
      await store.dispatch('workoutSettings/CLEAR_DATA');
      store.dispatch('onboarding/GET_BUMPERS');

      return result && result.statusCode === 201 && !!result.data;
    } catch {
      return false;
    }
  };

  const deleteRace = async(id: number): Promise<boolean> => {
    try {
      await store.dispatch('races/DELETE_RACE', { id });
      await store.dispatch('trainingPlan/CLEAR_DATA');
      await store.dispatch('trainingSummary/CLEAR_DATA');
      await store.dispatch('dashboard/CLEAR_DATA');
      await store.dispatch('workoutSettings/CLEAR_DATA');
      await Promise.all([
        getUpcoming(),
        getCompleted(),
      ]);
      return true;
    } catch {
      return false;
    }
  };

  const updateRace = async(id: number, name: string, date: Date, eventId: number, placeId: string): Promise<boolean> => {
    try {
      const result = await store.dispatch('races/UPDATE_RACE', {
        id,
        data: {
          name,
          event_date: format(date, DateFormat.Date),
          workout_event_id: eventId,
          place_id: placeId,
        },
      });
      await getUpcoming();
      await store.dispatch('trainingPlan/CLEAR_DATA');
      await store.dispatch('trainingSummary/CLEAR_DATA');
      await store.dispatch('dashboard/CLEAR_DATA');
      await store.dispatch('workoutSettings/CLEAR_DATA');
      return result && result.statusCode === 200 && !!result.data;
    } catch {
      return false;
    }
  };

  const getRace = (id: number): Race|null => {
    trackEvent(AnalyticsEvent.RaceClicked);
    if (!store.state.races.upcomingRacesList && !store.state.races.completedRacesList) {
      return null;
    }
    const event = store.getters['races/getRaceById'](id);
    return event || null;
  };

  const getUpcoming = async(userId?: number | null, perPage?: number) => {
    const params = userId || perPage ? { 'filter[userId]': userId, 'per-page': perPage } : {};
    await store.dispatch('races/GET_UPCOMING_RACES_LIST', { params });
    return true;
  };

  const getCompleted = async(userId?: number | null, perPage?: number) => {
    const params = userId || perPage ? { 'filter[userId]': userId, 'per-page': perPage } : {};
    await store.dispatch('races/GET_COMPLETED_RACES_LIST', { params });
    return true;
  };

  const isDateDisabled = (date: Date) => date < startOfDay(currentDate.value);

  const getCountdownMetrics = (dateString: string): { months: number, weeks: number, days: number } => {
    const eventDate = getUTCDate(dateString, timeZone.value);
    const months = differenceInMonths(eventDate, currentDateUTC.value);
    const weeks = differenceInWeeks(eventDate, currentDateUTC.value);
    const days = differenceInDays(eventDate, currentDateUTC.value);

    return {
      months: isNaN(months) ? 0 : months,
      weeks: isNaN(weeks) ? 0 : weeks,
      days: isNaN(days) ? 0 : days,
    };
  };

  const getCountdown = (race: Race): string|null => {
    if (race.is_today) {
      return 'Race Day';
    }

    if (race.countdown) {
      const value = race.countdown.map(item => `${item.value} ${item.unit}`).join(' ');
      return `${value} to go`;
    }

    return null;
  };

  const isActive = (race: Race): boolean => {
    const startDate = parse(race.plan_start_date, DateFormat.ISO, currentDateUTC.value);
    const endDate = parse(race.plan_end_date, DateFormat.ISO, currentDateUTC.value);

    return isDateBetweenDates(currentDateUTC.value, startDate, endDate);
  };

  const isInTaper = (race: Race): boolean => {
    const startDate = parse(race.plan_taper_start_date, DateFormat.ISO, currentDateUTC.value);
    const endDate = parse(race.plan_taper_end_date, DateFormat.ISO, currentDateUTC.value);

    return isDateBetweenDates(currentDateUTC.value, startDate, endDate);
  };

  const isToday = (race: Race) => {
    const eventDate = getUTCDate(race.event_date, timeZone.value);
    return differenceInDays(eventDate, currentDateUTC.value) === 0;
  };

  const isInRecovery = (race: Race): boolean => {
    const startDate = parse(race.plan_recovery_start_date, DateFormat.ISO, currentDateUTC.value);
    const endDate = parse(race.plan_recovery_end_date, DateFormat.ISO, currentDateUTC.value);

    return isDateBetweenDates(currentDateUTC.value, startDate, endDate);
  };

  const isPast = (race: Race) => {
    const eventDate = getUTCDate(race.event_date, timeZone.value);
    return differenceInDays(eventDate, currentDateUTC.value) < 0;
  };

  const isLastPage = computed(() => store.state.races.isLastUpcomingRacesListPage && store.state.races.isLastCompletedRacesPage);

  return {
    addRace,
    completed,
    deleteRace,
    getCompleted,
    getCountdown,
    getCountdownMetrics,
    getRace,
    getUpcoming,
    isActive,
    isDateDisabled,
    isInRecovery,
    isInTaper,
    isLoading,
    isPast,
    isToday,
    nextRace,
    upcoming,
    updateRace,
    isLastPage,
    failedLoad,
  };
};
