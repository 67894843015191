import Vue from 'vue';
import findIndex from 'lodash/findIndex';

import { getCache, setCache } from '../utils/cacheUtils';

const getDefaultState = () => ({
  isLoading: false,
  list: [],
});

const cacheListKey = 'communityGroups.list';

export const state = () => getDefaultState();

export const actions = {
  async GET_LIST({ commit }) {
    const cachedData = getCache(cacheListKey);
    if (cachedData) {
      commit('SET_DATA', cachedData);
      commit('RESET_UNREAD_COUNT_FOR_ALL_GROUPS');
    } else {
      commit('CLEAR_DATA');
      commit('SET_IS_LOADING', true);
    }
    const { data } = await this.$axios.$get('/api/community-groups');

    setCache(cacheListKey, data);
    commit('SET_DATA', data);
    commit('SET_IS_LOADING', false);
  },
  async SET_UNREAD_COUNT_FOR_GROUP({ commit }, { groupId, value }) {
    await commit('SET_UNREAD_COUNT_FOR_GROUP', { groupId, value });
  },
};

export const mutations = {
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
  SET_DATA(state, data) {
    state.list = data;
  },
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  RESET_UNREAD_COUNT_FOR_ALL_GROUPS(state) {
    state.list.forEach((group, index) => {
      Vue.set(state.list, index, {
        ...group,
        unread_count: 0,
      });
    });
  },
  SET_UNREAD_COUNT_FOR_GROUP(state, { groupId, value }) {
    const index = findIndex(state.list, { id: groupId });
    if (index !== -1) {
      Vue.set(state.list, index, {
        ...state.list[index],
        unread_count: value,
      });
    }
  },
};
