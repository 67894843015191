import cloneDeep from 'lodash/cloneDeep';
import { TrainingSummaryType } from 'mottiv-web-components';
import { SocialType } from '~/data/social';

import { WorkoutStatus, ConnectionType, Gender, MeasurementSystem } from '~/data/types';
import { RegistrationStep } from '~/data/types/registration';

export const HEADER_BACKGROUND_TYPES = {
  DEFAULT: 'default',
  BLUE: 'blue',
  GRADIENT: 'gradient',
  GREEN: 'green',
  ORANGE: 'orange',
  GRAY: 'gray',
  PINK: 'pink',
};

export const WORKOUT_STATUS_HEADER_BACKGROUND_TYPES_MAP = {
  [WorkoutStatus.Completed]: HEADER_BACKGROUND_TYPES.GREEN,
  [WorkoutStatus.CompletedMissed]: HEADER_BACKGROUND_TYPES.GRAY,
  [WorkoutStatus.CompletedPartially]: HEADER_BACKGROUND_TYPES.ORANGE,
  [WorkoutStatus.Future]: HEADER_BACKGROUND_TYPES.DEFAULT,
  [WorkoutStatus.Imported]: HEADER_BACKGROUND_TYPES.DEFAULT,
  [WorkoutStatus.Missed]: HEADER_BACKGROUND_TYPES.GRAY,
  [WorkoutStatus.Today]: HEADER_BACKGROUND_TYPES.BLUE,
  [WorkoutStatus.Unavailable]: HEADER_BACKGROUND_TYPES.GRAY,
};

export const ICONS = {
  bike: 'bike',
  comment: 'comment',
  event_recovery: 'event-recovery',
  event: 'flag',
  media: 'media',
  mobility: 'mobility',
  rest: 'rest',
  run_brick: 'run-brick',
  run: 'run',
  strength: 'strength',
  swim: 'swim',
  swimrun: 'swim-run',
  training_plan: 'training-plan',
  shareable_moment_image: 'shareable-moment-image',
};

export const ITEMS_PER_PAGE = 12;

export const MUTATIONS = {
  GET_LIST: 'GET_LIST',
  CLEAR_LIST: 'CLEAR_LIST',
  SET_LOADING: 'SET_LOADING',
  UPDATE_PAGINATION: 'UPDATE_PAGINATION',
};

export const POST_FILTER = {
  ALL_POSTS: 'authorOnly', // show all posts except workouts on the main tab
  FOLLOWED_ONLY: 'followedOnly',
  MY_ONLY: 'myOnly',
  USER: 'userId',
  GROUP: 'groupId',
};

export const FEED_SLIDER_TYPE = {
  RECOMMENDED: 'recommended',
  WORKOUTS: 'workouts',
};

export const FILTER_TYPE = {
  LEADERBOARD: 'leaderboard',
  USERS: 'users',
};

export const USERS_LIST_TYPE = {
  RECOMMENDED: 'recommended',
  USERS: 'users',
};

export const DAY_STATUS = {
  PAST: 'past',
  TODAY: 'today',
  FUTURE: 'future',
};

export const WEEK_STATUS = {
  PREVIOUS_WEEKS: 'previous',
  CURRENT_WEEK: 'current',
  NEXT_WEEK: 'next',
  AFTER_NEXT_WEEKS: 'after_next',
};

export const CONNECTION_TYPES = {
  [ConnectionType.TrainingPeaks]: 'TrainingPeaks',
  [ConnectionType.Strava]: 'Strava',
  [ConnectionType.Garmin]: 'Garmin Connect',
  [ConnectionType.Wahoo]: 'Wahoo',
  [ConnectionType.Facebook]: 'Facebook',
  [ConnectionType.Apple]: 'Apple',
};

export const CONNECTION_SCOPE_SETTING = {
  [ConnectionType.Strava]: {
    required: ['read', 'activity:read_all', 'profile:read_all'],
    additional: ['activity:write'],
    separator: ',',
  },
  [ConnectionType.TrainingPeaks]: {
    required: [
      'events:write',
      'workouts',
      'workouts:details',
      'athlete:profile',
    ],
    additional: [],
    separator: ' ',
  },
  [ConnectionType.Wahoo]: {
    required: [
      'workouts_read',
      'user_read',
      'offline_data',
      'power_zones_read',
    ],
    additional: [],
    separator: ' ',
  },
};

export const POST_IMAGE_SIZES = {
  large: '2048x2048',
  small: '600x600',
};

export const COMPLETION_STATUS_MANUAL = {
  POOR: 1,
  AVERAGE: 2,
  TARGET: 3,
};

export const COMPLETION_STATUS_AUTO = {
  TOO_LOW: 1,
  LOW: 2,
  TARGET: 3,
  HIGH: 4,
  TOO_HIGH: 5,
};

export const ZONE_TITLES = ['Easy Recovery', 'Endurance', 'Tempo', 'V02 Max', 'Fast'];

const ZONE_TYPE = [
  { title: 'Effort', subtitle: 'RPE', fieldName: 'rpe' },
  { title: 'Heart Rate', subtitle: 'bpm', fieldName: 'heartRate' },
  { title: 'Bike Power', subtitle: 'watts', fieldName: 'power' },
];

export const ZONE_TYPE_IMPERIAL = [
  ...ZONE_TYPE,
  { title: 'Run Pace', subtitle: 'min/mi', fieldName: 'pace' },
];

export const ZONE_TYPE_METRIC = [
  ...ZONE_TYPE,
  { title: 'Run Pace', subtitle: 'min/km', fieldName: 'pace' },
];

export const ZONE_EMPTY_STATE = {
  rpe: '',
  heartRate: 'Use Effort (RPE), Power or Pace',
  power: 'No FTP provided. Use Effort (RPE) or HR',
  pace: 'No pacing information provided. Use Effort (RPE) or HR',
};

export const WORKOUT_STATS_LABELS = {
  distance: 'Total Distance',
  duration_moving: 'Moving Time',
  duration_total: 'Total Time',
  pace_avg: 'Avg Pace',
  power_avg: 'Avg Power',
  power_max: 'Max Power',
  power_normalized: 'Normalized',
  speed_avg: 'Avg Speed',
  speed_max: 'Max Speed',
  hr_avg: 'Avg HR',
  hr_max: 'Max HR',
  time_in_hr_zones: 'HR',
  time_in_pace_zones: 'Pace',
  time_in_power_zones: 'Power',
};

export const COOKIE_EXPIRY_DAYS = 7;

export const COUNTDOWN_MIN_MONTHS = 3;
export const COUNTDOWN_MIN_WEEKS = 2;

export const TRAINING_TABS = [
  { label: 'Plan', url: '/training/plan' },
  { label: 'Races', url: '/training/races' },
  { label: 'Zones', url: '/training/zones' },
  { label: 'Schedule', url: '/training/settings' },
];

export const REGISTRATION_STEP_ORDER = [
  RegistrationStep.TrainingSports,
  RegistrationStep.TrainingGoal,
  RegistrationStep.CapabilitySwim,
  RegistrationStep.CapabilityBike,
  RegistrationStep.CapabilityRun,
  RegistrationStep.AthleteHeartRate,
  RegistrationStep.AthleteGender,
  RegistrationStep.AthleteAge,
  RegistrationStep.AthleteWeight,
  RegistrationStep.TrainingStartDate,
  RegistrationStep.Generate,
  RegistrationStep.AccountSignupMethod,
  RegistrationStep.AccountBilling,
];

export const API_VERSION_KEY = '_version';

const MEMBERSHIP_GENERAL_FEATURES = [
  {
    included: true,
    text: 'Connect fitness apps and devices',
    icons: true,
  },
  {
    included: true,
    text: 'Push workouts to fitness apps and devices',
    icons: false,
  },
  {
    included: true,
    text: 'Automatically sync workout stats from fitness devices',
    icons: false,
  },
  {
    included: true,
    text: 'Access ongoing performance stats',
    icons: false,
  },
  {
    included: true,
    text: 'Get personalized pace, power and heart rate targets in workouts',
    icons: false,
  },
  {
    included: true,
    text: 'Ad-free workouts',
    icons: false,
  },
];

export const MEMBERSHIP_FREE_FEATURES = [
  {
    included: true,
    text: 'Train for one race at a time',
    icons: false,
  },
  ...cloneDeep(MEMBERSHIP_GENERAL_FEATURES).map((item) => {
    item.included = false;
    return item;
  }),
];

export const MEMBERSHIP_PREMIUM_FEATURES = [
  {
    included: true,
    text: 'Train for unlimited races',
    icons: false,
  },
  ...MEMBERSHIP_GENERAL_FEATURES,
];

export const SUPPORTED_TRAINING_SUMMARIES = [
  TrainingSummaryType.Week,
  TrainingSummaryType.Month,
  TrainingSummaryType.AllTime,
];

export const DASHBOARD_TRAINING_SUMMARY_SEGMENTED_CONTROL_ITEMS = ['7 DAYS', '28 DAYS', 'ALL TIME'];

export const EMPTY_TRAINING_SUMMARY = {
  type: TrainingSummaryType.Week,
  statistics: [],
  completedWorkoutsPercentage: 0,
  totalActivities: 0,
};

export const WORKOUT_DISCIPLINE = {
  BIKE: 'bike',
  SWIM_RUN: 'swimrun',
  SWIM: 'swim',
  RUN_BRICK: 'run_brick',
  RUN: 'run',
  STRENGTH: 'strength',
  MOBILITY: 'mobility',
};

export const SOCIAL_SHARING_TYPES = [SocialType.Twitter, SocialType.Facebook, SocialType.Instagram];

export const SOCIAL_NAME_MAP = {
  [SocialType.Twitter]: 'X (Twitter)',
  [SocialType.Facebook]: 'Facebook',
  [SocialType.Instagram]: 'Instagram',
};

export const UNREAD_COUNT_TYPE = {
  NOTIFICATION: 'notification',
  POST: 'post',
};

export const GENDER_MAP = {
  [Gender.Female]: 'Female',
  [Gender.Male]: 'Male',
};

export const MEASUREMENT_SYSTEM_MAP = {
  [MeasurementSystem.Metric]: 'Metric',
  [MeasurementSystem.Imperial]: 'Imperial',
};
