import { useUserSubscription } from '../data/subscription';

export const useHelpCenter = () => {
  const { isPremium, isTrialing } = useUserSubscription();

  const getUrl = (headless: boolean = false) => {
    const params: String[] = [];

    if (headless) {
      params.push('ref=app');
    }

    if (isPremium.value && !isTrialing.value) {
      params.push('p=1');
    }

    const paramsString = params.length ? '?' + params.join('&') : '';

    return `https://www.mymottiv.com/help${paramsString}`;
  };

  return {
    getUrl,
  };
};
