export enum ConnectionType {
  TrainingPeaks = 1,
  Strava = 2,
  Garmin = 3,
  Wahoo = 4,
  Facebook = 5,
  Apple = 6,
};

export type Connection = {
  id: number,
  type: ConnectionType,
  is_workout_export_enabled: boolean,
};
