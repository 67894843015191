import Vue from 'vue';
import isArray from 'lodash/isArray';
import findIndex from 'lodash/findIndex';

const getDefaultState = () => ({
  connections: [],
  isLoading: true,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async SUBMIT_CONNECTION({ commit }, payload) {
    try {
      const connection = await this.$axios.$post('/api/connected-accounts', payload);
      commit('SET_CONNECTION', connection.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async UPDATE_CONNECTION({ commit }, { id, params: payload }) {
    try {
      const connection = await this.$axios.$put(`/api/connected-accounts/${id}`, payload);
      commit('SET_CONNECTION', connection.data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async GET_CONNECTION({ commit }) {
    try {
      commit('SET_IS_LOADING', true);
      const { data } = await this.$axios.$get('/api/connected-accounts');
      commit('SET_CONNECTIONS', data);
    } finally {
      commit('SET_IS_LOADING', false);
    }
  },
  async GET_GARMIN_TOKENS() {
    try {
      const response = await this.$axios.$get('/api/connected-accounts/garmin-request-tokens');
      return response?.data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async DELETE_CONNECTION({ commit }, id) {
    try {
      await this.$axios.$delete(`/api/connected-accounts/${id}`);
      commit('DELETE_ITEM_CONNECTION', id);
    } catch (e) {
      return Promise.reject(e);
    }
  },
};

export const mutations = {
  SET_IS_LOADING(state, data) {
    state.isLoading = data;
  },
  SET_CONNECTIONS(state, data) {
    if (isArray(data) && data.length) {
      state.connections = data;
    } else {
      state.connections = [];
    }
  },
  SET_CONNECTION(state, data) {
    const index = findIndex(state.connections, { id: data.id });
    if (index !== -1) {
      Vue.set(state.connections, index, data);
    } else {
      state.connections.push(data);
    }
  },
  DELETE_ITEM_CONNECTION(state, id) {
    const listItemIndex = state.connections.findIndex(item => item.id === id);
    state.connections.splice(listItemIndex, 1);
  },
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
};

export const getters = {};
