import { Sport } from './sport';
import { Gender, MeasurementSystem } from './user';

export enum RegistrationMethod {
  Email = 'email',
  Facebook = 'facebook',
  Strava = 'strava',
};

export enum LoginMethod {
  Apple = 'apple',
  Email = 'email',
  Facebook = 'facebook',
  Strava = 'strava',
};

export enum RegistrationStep {
  AccountBilling = 'account-billing',
  AccountSignupMethod = 'account-signup-method',
  AthleteAge = 'athlete-age',
  AthleteGender = 'athlete-gender',
  AthleteHeartRate = 'athlete-heart-rate',
  AthleteWeight = 'athlete-weight',
  CapabilityBike = 'capability-bike',
  CapabilityRun = 'capability-run',
  CapabilitySwim = 'capability-swim',
  Generate = 'generate',
  TrainingGoal = 'training-goal',
  TrainingSports = 'training-sports',
  TrainingStartDate = 'training-start-date',
};

export type TimeTrial = {
  distanceKey: string,
  time: string,
  units: MeasurementSystem,
};

export type Capability = {
  isBeginner: boolean,
  capability: string | null,
};

export type CapabilityWithTimeTrial = Capability & {
  timeTrial: TimeTrial | null,
};

export type CapabilityWithFTP = Capability & {
  ftp: number | null,
};

export enum TrainingGoal {
  Advanced = 'advanced',
  Beginner = 'beginner',
  Intermediate = 'intermediate',
}

export enum RegistrationStartDate {
  Today = 'today',
  Tomorrow = 'tomorrow',
  Monday = 'monday',
};

export type Registration = {
  analytics: { [key: string]: any },
  availableSports: Sport[],
  capabilities: {
    swim: CapabilityWithTimeTrial | null | undefined,
    bike: CapabilityWithFTP | null | undefined,
    run: CapabilityWithTimeTrial | null | undefined,
  },
  deviceId: string | undefined,
  email: string | undefined,
  emailConfirmation: string | undefined,
  externalAccountId: string | undefined,
  externalSignedRequest: string | undefined,
  firstName: string | undefined,
  funnelId: string | undefined,
  gender: Gender | null | undefined,
  heartRate: {
    max: number | null | undefined,
    resting: number | null | undefined,
  },
  isLoadingAvailableSports: boolean,
  lastName: string | undefined,
  measurementSystem: MeasurementSystem | undefined,
  month_of_birth: number | undefined,
  referrer: string | undefined,
  signupMethod: string | undefined,
  signupOauthCode: string | undefined,
  sports: Sport[] | undefined,
  startDate: RegistrationStartDate | undefined,
  startedAt: Date | undefined,
  timeZone: {
    offset: number | undefined,
    string: string | undefined,
  },
  trainingGoal: TrainingGoal | undefined,
  weight: number | undefined,
  year_of_birth: number | undefined,
};
