const metersToMiles = (meters) => {
  return (Number(meters) / 1000) * 0.6214;
};

const metersToYards = (meters) => {
  return Math.round(Number(meters) * 1.0936);
};

export const getDistanceString = (meters, shouldUseMetricSystem, shouldUseSmallUnits) => {
  if (typeof meters !== 'number') {
    return '';
  }

  let measurement = null;
  let userDistance = null;

  if (shouldUseSmallUnits) {
    measurement = shouldUseMetricSystem ? 'm' : 'yd';
    userDistance = shouldUseMetricSystem ? meters : metersToYards(meters);
  } else {
    measurement = shouldUseMetricSystem ? 'km' : 'mi';
    userDistance = shouldUseMetricSystem ? (meters / 1000) : metersToMiles(meters);
  }

  if (!measurement || !userDistance) {
    return '';
  }

  return `${userDistance.toFixed(2).replace(/\.00$/, '')}${measurement}`;
};

export const getDurationString = (seconds, withSeconds) => {
  if (typeof seconds !== 'number') {
    return '';
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor(seconds % 3600 / 60);
  const restSeconds = Math.floor(seconds % 3600 % 60);
  const hDisplay = hours > 0 ? `${hours}h` : '';
  const mDisplay = minutes > 0 ? `${minutes}m` : '';
  const sDisplay = restSeconds > 0 ? `${restSeconds}s` : '';
  let result = `${hDisplay} ${mDisplay}`;
  if (withSeconds && !hDisplay && !mDisplay) {
    result += ` ${sDisplay}`;
  }

  return result.trim();
};

export const shouldUseSmallUnits = (categoryKey) => {
  return typeof categoryKey === 'string' ? categoryKey.startsWith('swim') : false;
};
