export const state = () => ({
  isEnabled: false,
});

export const mutations = {
  ENABLE(state) {
    if (!state.isEnabled) {
      state.isEnabled = true;
    }
  },
  DISABLE(state) {
    if (state.isEnabled) {
      state.isEnabled = false;
    }
  },
};
