import { computed } from '@vue/composition-api';
import { useStore } from '@nuxtjs/composition-api';
import { State } from '~/data/types/store';
import { ImportedActivity, WorkoutStats } from '~/data/types';
import { useTrainingPlan } from '~/data/training/use-training-plan';
import { useTrainingSummary } from '~/data/stats/use-training-summary';
import { DateFormat, formatDate } from '~/helpers/date';

export const useImportedActivity = () => {
  const store = useStore<State>();
  const {
    deleteImportedActivity: deleteImportedActivityFromTrainingPlan,
    getInitialCalendar,
    updateCalendar,
    updatePrevCalendar,
  } = useTrainingPlan();
  const { getSummaries, updateWeekSummary, setSummariesAsLoading, setWeekSummaryAsLoading } = useTrainingSummary();
  const isLoading = computed(() => store.state.importedActivity.isLoading);
  const importedActivity = computed(() => store.state.importedActivity.data);

  const clearData = () => {
    store.dispatch('trainingPlanItem/CLEAR_DATA');
  };

  const deleteImportedActivity = async(id: number, isoDate: string): Promise<boolean> => {
    const date = formatDate(isoDate, DateFormat.ISO, DateFormat.Date);

    deleteImportedActivityFromTrainingPlan(id, date);
    updateCalendar();
    updatePrevCalendar();
    setSummariesAsLoading();
    setWeekSummaryAsLoading(date);

    try {
      await store.dispatch('importedActivity/DELETE_IMPORTED_ACTIVITY', id);
      await Promise.all([
        updateWeekSummary(date),
        getSummaries(),
      ]);
    } catch {
      setSummariesAsLoading(false);
      setWeekSummaryAsLoading(date, false);
    }

    return true;
  };

  const getImportedActivity = async(id: number) => {
    await store.dispatch('importedActivity/GET_IMPORTED_ACTIVITY', id);
  };

  const updateImportedActivity = async(item: ImportedActivity, stats: WorkoutStats): Promise<boolean> => {
    try {
      const oldDistance = item.stats?.distance?.value;
      const oldDurationMoving = item.stats?.duration_moving?.value;

      const payload: { [key: string]: any } = {
        id: item.id,
        data: {
          stats: {},
        },
      };

      Object.keys(stats).forEach((key) => { // @ts-ignore key type
        payload.data.stats[key] = stats[key] && stats[key].length ? stats[key] : null;
      });

      const newImportedActivity = await store.dispatch('importedActivity/UPDATE_IMPORTED_ACTIVITY', payload);

      let shouldRefreshSummaryData = false;

      if (!shouldRefreshSummaryData) {
        if (stats?.distance !== undefined) {
          const newDistance = newImportedActivity ? newImportedActivity.stats?.distance?.value : null;
          shouldRefreshSummaryData = oldDistance !== newDistance;
        }
      }

      if (!shouldRefreshSummaryData) {
        if (stats?.duration_moving !== undefined) {
          const newDurationMoving = newImportedActivity ? newImportedActivity.stats?.duration_moving?.value : null;
          shouldRefreshSummaryData = oldDurationMoving !== newDurationMoving;
        }
      }

      if (shouldRefreshSummaryData) {
        store.commit('trainingPlan/CLEAR_CALENDAR');
        getInitialCalendar();
        updateWeekSummary(formatDate(item.started_at, DateFormat.ISO, DateFormat.Date));
        getSummaries();
      }

      return true;
    } catch {
      return false;
    }
  };

  return {
    clearData,
    deleteImportedActivity,
    getImportedActivity,
    importedActivity,
    isLoading,
    updateImportedActivity,
  };
};
