import { differenceInDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { COMPLETION_STATUS_AUTO, COMPLETION_STATUS_MANUAL, WORKOUT_DISCIPLINE } from '~/constants';

import { TrainingPlanItem, WorkoutStatus } from '~/data/types';
import { DateFormat } from '~/helpers/date';
import { getImportedActivityDetails } from '~/data/training/get-imported-activity-details';

const getUnitMeters = (minValue: number | null, maxValue: number) => {
  if (minValue === maxValue || !minValue) {
    return `${maxValue} m`;
  } else {
    return `${minValue}-${maxValue} m`;
  }
};

const getRoundedValue = (value: number) => {
  const roundToTenth = value.toFixed(1);
  const arr = roundToTenth.toString().split('.').map(Number);
  if (value.toString().indexOf('.')) {
    if (arr[1] === 0) {
      return value.toFixed();
    }
    return roundToTenth;
  }
  return value;
};

const conversionMetersToKilometers = (value: number) => getRoundedValue(value / 1000);

const getUnitKilometers = (minValue: number | null, maxValue: number) => {
  if (minValue === maxValue || !minValue) {
    return `${conversionMetersToKilometers(maxValue)} km`;
  } else {
    return `${conversionMetersToKilometers(minValue)}-${conversionMetersToKilometers(maxValue)} km`;
  }
};

const conversionMetersToMiles = (value: number) => getRoundedValue((value / 1000) * 0.6214);

const getUnitMiles = (minValue: number | null, maxValue: number) => {
  if (minValue === maxValue || !minValue) {
    return `${conversionMetersToMiles(maxValue)} ml`;
  } else {
    return `${conversionMetersToMiles(minValue)}-${conversionMetersToMiles(maxValue)} ml`;
  }
};

const conversionMetersToYards = (value: number) => getRoundedValue(Math.round(value * 1.09361 / 25) * 25);

const getUnitYards = (minValue: number | null, maxValue: number) => {
  if (minValue === maxValue || !minValue) {
    return `${conversionMetersToYards(maxValue)} yds`;
  } else {
    return `${conversionMetersToYards(minValue)}-${conversionMetersToYards(maxValue)} yds`;
  }
};

const getTimeTitle = (currentValue: number) => {
  const quotient = Math.floor(currentValue / 60);
  const remainder = currentValue % 60;

  if (currentValue > 90) {
    return `${quotient}${remainder !== 0 ? `:${remainder}` : ''}`;
  } else {
    return `${currentValue}`;
  }
};

const displayTimeText = (value: number) => {
  return value > 90 ? ' hrs' : ' min';
};

const getBothDurations = (minValue: number, maxValue: number) => {
  if (minValue <= 90 && maxValue > 90) {
    // If durationMin is less than 90 then it will be displayed in minutes,
    // the general format will be like this => f.e. 40 min - 1.20 hrs;
    return `${minValue} min` + ' - ' + getTimeTitle(maxValue) + displayTimeText(maxValue);
  } else {
    return getTimeTitle(minValue) + ' - ' + getTimeTitle(maxValue) + displayTimeText(maxValue);
  }
};

export const getWorkoutType = (trainingPlanItem: TrainingPlanItem): string => {
  let type = trainingPlanItem.workout.categoryTitle || trainingPlanItem.workout.categoryTitleShort;

  if (trainingPlanItem && trainingPlanItem.workout.categoryKey === 'event') {
    const eventTitle = trainingPlanItem.event_title || trainingPlanItem.workout.plan_event_title;

    if (eventTitle) {
      type = eventTitle;
    }
  }

  return type;
};

export const getWorkoutDetails = (item: TrainingPlanItem, useMetricSystem: boolean): string | null => {
  if (item.imported_activity) {
    const details = getImportedActivityDetails(item.imported_activity, useMetricSystem);

    if (details.length) {
      return details[0];
    }
  }

  return getPlannedWorkoutDetails(
    item.workout.duration_min,
    item.workout.duration_max,
    item.workout.distance_min,
    item.workout.distance_max,
    item.workout.categoryKey,
    useMetricSystem,
  );
};

export const getPlannedWorkoutDetails = (
  durationMin: number | null,
  durationMax: number | null,
  distanceMin: number | null,
  distanceMax: number | null,
  workoutCategoryKey: string,
  useMetricSystem: boolean,
): string | null => {
  const isWorkoutSwim = workoutCategoryKey.includes('swim');

  const getDurationTitle = () => {
    const isDurationTotalRecovery = durationMin === 0 && durationMax === 0;
    const onlyMaxDurationExists = durationMin === 0 && durationMax;
    const isEqualDuration = durationMin && (durationMin === durationMax);
    const bothDurationsExists = durationMin && durationMax;

    if (isEqualDuration && durationMax) {
      // 60min ( duration_min = 60, duration_max = 60)
      return getTimeTitle(durationMax) + displayTimeText(durationMax);
    } else if (bothDurationsExists && durationMin && durationMax) {
      return getBothDurations(durationMin, durationMax);
    } else if (isDurationTotalRecovery) {
      // Total recovery ( duration_min = 0, duration_max = 0)
      return 'Total recovery';
    } else if (onlyMaxDurationExists && durationMax) {
      // < 60min ( duration_min = 0, duration_max = 60)
      return '< ' + getTimeTitle(durationMax) + displayTimeText(durationMax);
    }
    return null;
  };

  const getDistanceTitle = () => {
    if (!distanceMax) {
      return '';
    } else if (useMetricSystem && !isWorkoutSwim) {
      return getUnitKilometers(distanceMin, distanceMax);
    } else if (!useMetricSystem && !isWorkoutSwim) {
      return getUnitMiles(distanceMin, distanceMax);
    } else if (useMetricSystem && isWorkoutSwim) {
      return getUnitMeters(distanceMin, distanceMax);
    } else if (!useMetricSystem && isWorkoutSwim) {
      return getUnitYards(distanceMin, distanceMax);
    }
    return null;
  };

  if (durationMin || durationMax) {
    return getDurationTitle();
  } else if (distanceMin || distanceMax) {
    return getDistanceTitle();
  }
  return null;
};

/**
 * Compare a parsable workout date string to the current date in a user's timezone
 *
 * @param parentDate string
 * @param userTimeZone string
 * @return WorkoutStatus
 */
export const getWorkoutStatusForDate = (parentDate: string, userTimeZone: string): WorkoutStatus => {
  const date = parentDate ? new Date(parentDate) : new Date();
  const timezone = userTimeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const currentDate = formatInTimeZone(new Date(), timezone, DateFormat.Date);
  const diff = differenceInDays(date, new Date(currentDate));

  if (diff < 0) { return WorkoutStatus.Missed; }
  if (diff > 0) { return WorkoutStatus.Future; }

  return WorkoutStatus.Today;
};

export const getWorkoutStatus = (training: TrainingPlanItem, userTimeZone: string): WorkoutStatus => {
  if (training.completion_status_manual) {
    if (training.completion_status_manual === COMPLETION_STATUS_MANUAL.POOR) {
      return WorkoutStatus.CompletedMissed;
    }
    return training.completion_status_manual === COMPLETION_STATUS_MANUAL.TARGET
      ? WorkoutStatus.Completed
      : WorkoutStatus.CompletedPartially;
  } else if (training.completion_status_automatic) {
    return training.completion_status_automatic === COMPLETION_STATUS_AUTO.TARGET
      ? WorkoutStatus.Completed
      : WorkoutStatus.CompletedPartially;
  }
  return getWorkoutStatusForDate(training.planned_date, userTimeZone);
};

export const getDiscipline = (training: TrainingPlanItem): string|null => {
  const categoryKey = training.workout.categoryKey;
  const arr = Object.values(WORKOUT_DISCIPLINE);
  for (let i = 0; i < arr.length; i++) {
    if (categoryKey.includes(arr[i])) {
      return arr[i];
    }
  };
  return null;
};

export const getHoursMinutesSecondsFromMinutes = (minutesNumber: number): string => {
  const roundedMinutes = Math.round(minutesNumber);
  const minutes = roundedMinutes % 60;
  const hours = (roundedMinutes - minutes) / 60;
  const hoursAndMinutes = (hours < 10 ? '0' : '') + hours.toString() + ':' + (minutes < 10 ? '0' : '') + minutes.toString();

  return `${hoursAndMinutes}:00`;
};
