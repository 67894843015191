import cloneDeep from 'lodash/cloneDeep';

const getDefaultState = () => ({
  isLoading: true,
  data: null,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_USER_PROFILE({ commit, rootState }, id) {
    commit('SET_IS_LOADING', true);
    const { data } = await this.$axios.$get(`/api/users/${id}/profile`);
    commit('SET_PROFILE', data);
    if (id === rootState.auth.user.data.id) {
      commit('UPDATE_AUTH_DATA', rootState.auth.user);
    }
    commit('SET_IS_LOADING', false);
  },
  async UPDATE_USER_PROFILE({ commit }, { id, data: payload }) {
    const response = await this.$axios.$put(`/api/users/${id}/profile`, payload);
    commit('SET_PROFILE', response.data);
    return response;
  },
};

export const mutations = {
  SET_RELATIONSHIP(state, { data }) {
    state.data.own_followee = data;
  },
  SET_FOLLOWERS_COUNT(state, { increase = true }) {
    state.data.followers = increase ? state.data.followers + 1 : state.data.followers - 1;
    state.data.is_following = increase;
  },
  REMOVE_RELATIONSHIP(state) {
    state.data.own_followee = null;
  },
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_IS_LOADING(state, value) {
    state.isLoading = value;
  },
  SET_PROFILE(state, data) {
    state.data = { ...data, is_following: !!data.own_followee };
  },
  UPDATE_AUTH_DATA(state, authUser) {
    const newUserData = cloneDeep(authUser);
    newUserData.data.usersFollowedCount = state.data.followed;
    newUserData.data.followersCount = state.data.followers;
    this.$auth.setUser(newUserData);
  },
};
