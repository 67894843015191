import { installPixel, installSdk } from '~/helpers/facebook';

export default function() {
  if (process.env.FACEBOOK_PIXEL_ID) {
    installPixel(process.env.FACEBOOK_PIXEL_ID);
  }

  if (process.env.FACEBOOK_LOGIN_APP_ID) {
    installSdk(process.env.FACEBOOK_LOGIN_APP_ID);
  }
};
