import { ImportedActivity } from '~/data/types/training';
import { getDistanceString, getDurationString } from '~/helpers/importedActivity';

export const getImportedActivityDetails = (item: ImportedActivity, useMetricSystem: boolean): string[] => {
  const details: string[] = [];
  const useSmallUnits = item.type ? item.type.toLowerCase().includes('swim') : false;

  if (item.duration) {
    details.push(getDurationString(item.duration, true));
  }

  if (item.distance) {
    details.push(getDistanceString(item.distance, useMetricSystem, useSmallUnits));
  }

  return details;
};
