export const state = () => ({
  addRaceFunnelId: null,
  billingFunnelId: null,
  defaultSuperEventData: {},
  hasCreatedAlias: false,
  loginFunnelId: null,
  mixpanelId: null,
  registerFunnelId: null,
  scheduleFunnelId: null,
  trainingPlanItemFunnelIds: {},
});

export const actions = {
  async TRACK_EVENT(_, { url, data }) {
    try {
      await this.$axios.$post(url ?? '/api/analytics/track', data, { hideToastr: true });
    } catch {}
  },
};

export const mutations = {
  SET_DEFAULT_SUPER_EVENT_DATA(state, data) {
    state.defaultSuperEventData = data;
  },
  SET_FUNNEL(state, { key, value }) {
    state[key] = value;
  },
  SET_HAS_CREATED_ALIAS(state, value) {
    state.hasCreatedAlias = value;
  },
  SET_MIXPANEL_ID(state, id) {
    state.mixpanelId = id;
  },
  SET_TRAINING_PLAN_ITEM_FUNNEL(state, { id, value }) {
    const ids = state.trainingPlanItemFunnelIds;
    ids[id] = value;

    state.trainingPlanItemFunnelIds = ids;
  },
};
