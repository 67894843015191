const getDefaultState = () => ({
  estimatedResults: [],
  isLoadingEstimatedResults: true,
  isLoadingWeeklyPreferences: true,
  primarySportId: null,
  recommendedWeeklyPreferences: null,
  selectedEstimatedHoursPerWeek: null,
  weeklyPreferences: null,
  weeklyPreferencesError: null,
});

export const state = () => getDefaultState();

export const actions = {
  CLEAR_DATA({ commit }) {
    commit('CLEAR_DATA');
  },
  async GET_ESTIMATED_RESULTS({ commit }, { planType, workoutEventId, sportId }) {
    commit('SET_IS_LOADING_ESTIMATED_RESULTS', true);

    try {
      const response = await this.$axios.$get('/api/workout-estimated-results', {
        params: {
          'filter[plan_type]': planType,
          'filter[sport_id]': sportId,
          'filter[workout_event_id]': workoutEventId, // required for event plan types
        },
      });
      commit('SET_ESTIMATED_RESULTS', response.data);
    } catch {
      // Do nothing
    }

    commit('SET_IS_LOADING_ESTIMATED_RESULTS', false);
  },
  async GET_WEEKLY_PREFERENCES({ commit }, { planType, workoutEventId, workoutHoursPerWeek, sportId }) {
    commit('SET_IS_LOADING_WEEKLY_PREFERENCES', true);

    try {
      const response = await this.$axios.$get('/api/default-schedule', {
        params: {
          plan_type: planType,
          sport_id: sportId, // required for non event plan types
          workout_event_id: workoutEventId, // required for event plan types
          workout_hours_per_week: workoutHoursPerWeek,
        },
      });
      commit('SET_RECOMMENDED_WEEKLY_PREFERENCES', response.data);
      commit('SET_WEEKLY_PREFERENCES', response.data);
    } catch {
      // Do nothing
    }

    commit('SET_IS_LOADING_WEEKLY_PREFERENCES', false);
  },
};

export const mutations = {
  CLEAR_DATA(state) {
    Object.assign(state, getDefaultState());
  },
  SET_ESTIMATED_RESULTS(state, data) {
    state.estimatedResults = data;
  },
  SET_IS_LOADING_ESTIMATED_RESULTS(state, value) {
    state.isLoadingEstimatedResults = value;
  },
  SET_IS_LOADING_WEEKLY_PREFERENCES(state, value) {
    state.isLoadingWeeklyPreferences = value;
  },
  SET_PRIMARY_SPORT(state, value) {
    state.primarySport = value;
  },
  SET_RECOMMENDED_WEEKLY_PREFERENCES(state, value) {
    state.recommendedWeeklyPreferences = value;
  },
  SET_SELECTED_ESTIMATED_HOURS_PER_WEEK(state, value) {
    state.selectedEstimatedHoursPerWeek = value;
  },
  SET_WEEKLY_PREFERENCES(state, data) {
    state.weeklyPreferences = data;
  },
  SET_WEEKLY_PREFERENCES_ERROR(state, error) {
    state.weeklyPreferencesError = error;
  },
  SET_PRIMARY_SPORT_ID(state, value) {
    state.primarySportId = value;
  },
};
